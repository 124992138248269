import {
  TextfieldMD,
  TextfieldSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { FC, useEffect, useState } from "react";
import "./styles.scss";
import releaseImg from '../../../../../assets/img/release-img.png';
import { CloseModalButton } from "../../logo-header/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { fetchTrackDetails, setTrackID, setTrackSlugName } from "../../../../../redux/reducer/uploadSlice";
import { setProjectname } from "../../../../../redux/reducer/projectnameSlice";
import { createTrackProject } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";

interface viewType {
  navigateTo:(path:string)=>()=>void
} 

const view :FC<viewType> =({ navigateTo }) => {

    const [primaryArtist, setPrimaryArtist] = useState<any>([]);
    const [featuredArtist, setFeaturedArtist] = useState<any>([]);
    const [hiddenArtist, setHiddenArtist] = useState<any>([]);
    const[nonCredits,setNonCredits]=useState<any>([])
  const navigate=useNavigate()
  const ErrorImg =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxqCQMRexiN9Iu6yVJQt3py5bPb13DRIqCXA&s";
  const { trackDetails,trackId,reReleaseTrackDetails,invitedUserRereleaseTrackProject,invitedUserTrackProject} = useSelector((state: RootState) => state.upload);
  const path = window.location.pathname;


    useEffect(() => {
      if(path.includes("track-project-overview")){
        if (Array.isArray(invitedUserTrackProject?.data)) {
          // Filter and sort Primary Artists
          const filteredPrimaryArtists = invitedUserTrackProject.data
          .slice(1)
            .filter((data: any) => data.credit_to === "Primary Artist")
            .sort((a: any, b: any) => {
              // Prioritize based on isyellow_tick
              if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
              if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
      
              // If both have the same isyellow_tick, sort by updated_at
              return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
            });
          setPrimaryArtist(filteredPrimaryArtists);
      
          // Filter and sort Featured Artists
          const filteredFeaturedArtist = invitedUserTrackProject.data
          .slice(1)
            .filter((data: any) => data.credit_to === "Featured Artist")
            .sort((a: any, b: any) => {
              // Prioritize based on isyellow_tick
              if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
              if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
      
              // If both have the same isyellow_tick, sort by updated_at
              return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
            });
          setFeaturedArtist(filteredFeaturedArtist);
      
          // Filter and sort Hidden Artists
          const filteredHiddenArtist = invitedUserTrackProject.data
          .slice(1)
            .filter((data: any) => data.credit_to === "Hidden")
            .sort((a: any, b: any) => {
              // Prioritize based on isyellow_tick
              if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
              if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
      
              // If both have the same isyellow_tick, sort by updated_at
              return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
            });
          setHiddenArtist(filteredHiddenArtist);
      
          // Filter and sort Non-Credit Artists
          const filteredNonCreditArtist = invitedUserTrackProject.data
            .slice(1)
            .filter((data: any) => data.credit_to == null && data.role.toLowerCase() != "curator")
            .sort((a: any, b: any) => {
              // Prioritize based on isyellow_tick
              if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
              if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
      
              // If both have the same isyellow_tick, sort by updated_at
              return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
            });
          setNonCredits(filteredNonCreditArtist);
        }
      }
      else if(path.includes("track-project-rerelease")){
      if (Array.isArray(invitedUserRereleaseTrackProject?.data)) {
        // Filter and sort Primary Artists
        const filteredPrimaryArtists = invitedUserRereleaseTrackProject.data
        .slice(1)
          .filter((data: any) => data.credit_to === "Primary Artist")
          .sort((a: any, b: any) => {
            // Prioritize based on isyellow_tick
            if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
            if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
    
            // If both have the same isyellow_tick, sort by updated_at
            return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
          });
        setPrimaryArtist(filteredPrimaryArtists);
    
        // Filter and sort Featured Artists
        const filteredFeaturedArtist = invitedUserRereleaseTrackProject.data
        .slice(1)
          .filter((data: any) => data.credit_to === "Featured Artist")
          .sort((a: any, b: any) => {
            // Prioritize based on isyellow_tick
            if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
            if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
    
            // If both have the same isyellow_tick, sort by updated_at
            return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
          });
        setFeaturedArtist(filteredFeaturedArtist);
    
        // Filter and sort Hidden Artists
        const filteredHiddenArtist = invitedUserRereleaseTrackProject.data
        .slice(1)
          .filter((data: any) => data.credit_to === "Hidden")
          .sort((a: any, b: any) => {
            // Prioritize based on isyellow_tick
            if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
            if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
    
            // If both have the same isyellow_tick, sort by updated_at
            return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
          });
        setHiddenArtist(filteredHiddenArtist);
    
        // Filter and sort Non-Credit Artists
        const filteredNonCreditArtist = invitedUserRereleaseTrackProject.data
          .slice(1)
          .filter((data: any) => data.credit_to == null && data.role.toLowerCase() != "curator")
          .sort((a: any, b: any) => {
            // Prioritize based on isyellow_tick
            if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; // a comes first
            if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; // b comes first
    
            // If both have the same isyellow_tick, sort by updated_at
            return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
          });
        setNonCredits(filteredNonCreditArtist);
      }}
    }, [invitedUserTrackProject,invitedUserRereleaseTrackProject]);

const dispatch=useDispatch()
  const click = async (
    name: string,
    value: string | null | boolean
  ) => {
    
    if (value) {
      const response = await createTrackProject({ [name]: value, id: trackId });
      if (response.success) {
        dispatch(setTrackID(response.data._id));
        dispatch(fetchTrackDetails(response.data.slug_name) as any);
        dispatch(setTrackSlugName(response.data.slug_name))
        dispatch(setProjectname(response.data.project_name))
        navigate("/library")
      } else {
      }
    }
  };
  
  return (
    <div
      className="modal fade"
      id="ReleaseModal"
      aria-hidden="true"
      aria-labelledby="ReleaseModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width-release">
        <div className="modal-content custom-modal-release">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-32 p-0">
            <h1 className="modal-title">Drop</h1>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-32">
             <TextfieldSM className="release-subheading uni-mb-16">Drop process cannot be reversed before you Drop your Track:</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-16">1. Pull down the Distributed Track from streaming platforms via your distributor</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">2. Ensure all information provided is correct</TextfieldSM>
              
              <div className="d-flex align-items-center gap-3 uni-mb-32">
                
                <img className="releaseImage" src={(path.includes("track-project-rerelease")?(`${process.env.REACT_APP_IMGURL}/${reReleaseTrackDetails?.trackData?.art_work_image[0]?.src}`): (`${process.env.REACT_APP_IMGURL}/${trackDetails?.trackData?.art_work_image[0]?.src}`))} alt="" onError={(e) => (e.currentTarget.src = ErrorImg)}/>
                <div>
                  <h2 className="hot-summer">{path.includes("track-project-rerelease")?reReleaseTrackDetails?.trackData?.track_name: trackDetails?.trackData?.track_name}</h2>
                 {/* <TextfieldMD className="hot-summer-under">{path.includes("track-project-rerelease")?reReleaseTrackDetails?.trackData?.description:trackDetails?.trackData?.description}</TextfieldMD> */}
                 <TextfieldMD className="fc-gray4 text-capitalize">

                  {path.includes("track-project-rerelease")?reReleaseTrackDetails?.collaborators?.[0]?.display_name:trackDetails?.collaborators?.[0]?.display_name}
                    
                    {primaryArtist.length > 0
                      ? primaryArtist.map((data: any, index: number) => (
                          <>
                          {","}{" "}{data.display_name}
                          
                          </>
                        ))
                      : ""}
                    {featuredArtist.length > 0
                      ? featuredArtist.map((data: any, index: number) => (
                          <>
                           {","}{" "}  {data.display_name}
                            
                          </>
                        ))
                      : ""}
                    {hiddenArtist.length > 0
                      ? hiddenArtist.map((data: any, index: number) => (
                          <>
                           {","}{" "}  {data.display_name}
                           
                          </>
                        ))
                      : ""}

{nonCredits.length > 0
                      ? nonCredits.map((data: any, index: number) => (
                          <>
                         {","}{" "}  {data.display_name} 
                            {/* {index !== hiddenArtist.length - 1 && ","}{" "} */}
                          </>
                        ))
                      : ""}
                  </TextfieldMD>
                </div>
              </div>
              
             
             <TextfieldSM className="release-subheading uni-mb-32">1. Your Track will be distributed to streaming platforms again under the same ISRC code .</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">2. You will be notified when the process completes .</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">3. Your Track will be divided into 6,000<span className="dropstock-text"> DropStocks </span> to be split among your Collaborators’ team in your preset %.</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">4. Each Collaborator will receive the DropStocks in the account balance.</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">5. <span className="dropstock-text">DropStocks</span> are freely tradable at any trading platforms accepting NFT 2.0 standard.</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">6. You can apply this <span className="dropstock-text">DropStock</span>  to be included in our DWS portfolio for approval.</TextfieldSM>
              {path.includes("track-project-rerelease")? <TextfieldSM className="release-subheading uni-mb-32">7. We will release your Track under the same ISRC code, and shall notify you via email once we complete the process</TextfieldSM> :""}

             
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksSmallButton>
            </CloseModalButton>
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid m-0" onClick={()=>click("is_released",true)}>
              Release
            </DropworksSmallButton></CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
