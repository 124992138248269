import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { useDispatch, useSelector } from "react-redux";
import { gasFeesCalculate, setWalletDetails } from "../../../../../redux/reducer/walletSlice";
import { RootState } from "../../../../../redux/store";
import { AppDispatch } from "../../../../../redux/store";

export const WalletDropStocksWithdraw = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const { pin, withdraw } = useSelector(
    (state: RootState) => state.wallet.walletData
  );
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema4 = yup.object({
    amount: yup.string().required("PIN is required").min(4, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").max(6, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").matches(/^\d{4}$|^\d{6}$/, "Pin should be 4 or 6 digits.")
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema4),
    mode: "onSubmit",
  });

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const onSubmit = async (amount: any) => {
    console.log(amount,"amount")
    if (amount) {
      setLoading(true)
      const payload = {
        type: withdraw?.toLowerCase() || "",
        pin: pin || "",
        // trackNFTId: trackNFTId || "",
        amount,
      }

      dispatch(gasFeesCalculate(payload)).then((response: any) => {
        console.log(response, "gasFeesCalculate")
        if (response.payload.success) {
          openPopup("ChangePinModal", dispatch);
          setLoading(false);
          handleCloseModalClick()
          if (response.payload.data) {
            dispatch(setWalletDetails({ amount: amount, type: "dropstocks", trackNFTId: "112" }));
            setIsAddressValid(false);
            setLoading(false);
            setError(response.payload.type || "amount", { type: "", message: "" });
            clearErrors("amount");
          }
        } else {
          setLoading(false);
          setIsAddressValid(true);
          console.log(response, "responseresponseresponseresponse")
          if (amount) {
            setError(response.payload.type || "amount", { type: "custom", message: response.payload.message });
          }
        }
      });
    }
  };

  const openModal = () => {
    reset()
    setValue("pin", "");
    setError("pin", { type: "custom", message: "" });
    setLoading(false);
  }
  
    return view({ errors, isSubmitted, loading, register, onSubmit, handleSubmit, navigateTo, closeModalRef, isAddressValid, openModal,reset});
}
export default WalletDropStocksWithdraw;