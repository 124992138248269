import { Carousel } from "react-responsive-carousel";
import "./styles.scss";
import FeaturedImg from "../../../assets/img/FeaturedGroupImg.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { UserLable } from "../../../common/user-labels";
import { BlueTik } from "../../../common/all-icons";
import { ProjectIcon } from "../../../common/project-icon";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import { BeatesIcon } from "../../../common/beats-icon";
import { PopularThisWeek, viewType } from "./modal";
import { FC } from "react";
import { TrackIcon } from "../../../common/track-icon";

export const view: FC<viewType> = ({
  navigateTo,
  beatsarr,
  projectarr,
  popularThisWeek,
  popularTrack,
}) => {
  const labelArray = [
    "Investor",
    "Producer",
    "Vocalist",
    "Lyricist",
    "Beat Maker",
    "Curator",
    "Artwork Designer",
  ];
  const renderArrowPrev = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        className="control-arrow control-prev"
        style={{
          position: "absolute",
          left: "-30px", 
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 100000,
        }}
      >
        &#10094;
      </button>
    );

  const renderArrowNext = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        className="control-arrow control-next"
        style={{
          position: "absolute",
          right: "-30px", 
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 10,
        }}
        
      >
        &#10095;
      </button>
    );

  return (
    <div className="featured container">
      <div className="featured_heading uni-mb-32 mb-32-mbl">
        Popular This Week
      </div>
      <div className="uni-mb-56 featured-carousel-below full-width-child">
        {popularThisWeek.length > 0 && (
          <Carousel
            className="featured-carousel"
            autoPlay={true}
            stopOnHover={true}
            infiniteLoop={true}
            showThumbs={false}
            transitionTime={1000}
            interval={10000}
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}
          >
            {popularThisWeek.map((ele: PopularThisWeek, index: number) => (
              <div
                onClick={navigateTo(`/user-profile/${ele.slug_name}`)}
                className="cstm-height"
              >
              <img
                src={
            ele.featured_image?.[0]?.src
              ? `${process.env.REACT_APP_IMGURL}/${ele.featured_image[0].src}`
              : ele.profile_image?.[0]?.src
              ? `${process.env.REACT_APP_IMGURL}/${ele.profile_image[0].src}`
              : FeaturedImg 
          }
          onError={(e: any) => (e.target.src = FeaturedImg)}
          className="featured-carousel--img img-fluid"
          style={{
            maskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))", 
          }}
            />
                <p className="legend p-0">
                  <Heading3 className="featured-carousel-Heading">
                    {ele.display_name}{" "}
                    <span onClick={(e) => e.stopPropagation()}>
                      {Boolean(ele.is_bluetick) && (
                        <BlueTik className="no-styles bluetick featured-varified bg-danger p-4" />
                      )}
                    </span>
                  </Heading3>
                  <p className="featured-carousel-subHeading uni-mb-24">
                    @{ele.username}
                  </p>
                  <p className="d-flex featured-labels">
                    {ele.role.map((ele) => (
                      <UserLable className="uni-mr-16">{ele}</UserLable>
                    ))}
                  </p>
                </p>
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <div className="featured_heading uni-mb-32">Projects For You</div>
      <div className="library-usericon">
        <ProjectIcon
          arr={projectarr}
          className="all-icon-mobile uni-pl-24 full-width-child"
          className2="project-icon-mobile-view"
          showSwiper={true}
        />
      </div>
      <div className="featured_heading uni-mb-32">Hot Beats</div>
      <div className="library-usericon">
        <BeatesIcon
          arr={beatsarr}
          className="all-icon-mobile uni-pl-24 full-width-child"
          className2="beats-icon-mobile-view"
           showSwiper={true}
        />
      </div>
      <div className="featured_heading uni-mb-32">Hot Tracks</div>
      <div className="library-usericon">
        <TrackIcon
          arr={popularTrack}
          className="all-icon-mobile uni-pl-24 full-width-child"
          className2="beats-icon-mobile-view"
          showSwiper={true}
        />
      </div>
    </div>
  );
};
