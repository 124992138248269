import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";
import iconImg from "../../../../../assets/steps/uploadicon.svg";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import {
  ButtonComponent,
  closeModal,
  CloseModalButton,
} from "../../logo-header/view";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { inviteTrackProject } from "../../../utils/apiServices";
import {
  fetchRereleaseTrackDetails,
  fetchTrackDetails,
  getALlInvitedUserbyRereleaseTrackProject,
  getALlInvitedUserbyTrackProject,
  setTabValueChange,
} from "../../../../../redux/reducer/uploadSlice";
import { AppDispatch } from "../../../../../redux/store";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
const view = () => {
  const { inviteModalUserId, userAllRole } = useSelector(
    (state: RootState) => state.upload
  );
  const [uploadedAudioFile, setUploadedAudioFile] = useState<any>(null);
  const [audioFileAPI, setAudioFileAPI] = useState<any>(null);
  const [message, setMessage] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const path = window.location.pathname;
  const [percentageShare, setPercentageShare] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { trackId, trackDetails, reReleaseTrackDetails, trackSlugname } =
    useSelector((state: RootState) => state.upload);
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema16 = yup.object({
    role: yup.string().required("Role is  required"),
    percentageOffered: yup.string().required("Percentage is required"),
    message: yup.string(),
  });
  const onChange = (data: any) => {
    if (data) {
      setValue("role", data, { shouldValidate: true });
    }
    setSelectedValue(data);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
    setError,
    setValue,
    reset,
    getValues,
  } = useForm<any>({ resolver: yupResolver(schema16), mode: "onSubmit" });
  const onSubmit = async (data: any, e?: React.BaseSyntheticEvent) => {
    if (e) {
      setLoading2(true);
      e.preventDefault(); // Prevent the default form submission behavior
      data = {
        ...data,
        upload_audio: audioFileAPI,
        receiverUserId: inviteModalUserId,
        trackId: trackId,
      };
      const response: any = await inviteTrackProject(data);

      if (response.success) {
        if (path.includes("track-project-overview")) {
          dispatch(getALlInvitedUserbyTrackProject(trackId));
          dispatch(fetchTrackDetails(trackSlugname));
        } else if (path.includes("track-project-rerelease")) {
          dispatch(getALlInvitedUserbyRereleaseTrackProject(trackId));
          dispatch(fetchRereleaseTrackDetails(trackSlugname));
        }
        dispatch(setTabValueChange(1));
        // dispatch(setInvitedUserTrackProject())
        closeModal();
        setLoading2(false);
      } else {
        setError(response.type || "role", {
          type: "custom",
          message: response.message,
        });
        setLoading2(false);
      }
    }
  };

  const handleRemoveAudio = () => {
    setUploadedAudioFile(null);
    setAudioFileAPI(null);
  };
  const handleAudioUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setLoading(true);
    if (file) {
      setUploadedAudioFile(file);
      const formData = new FormData();
      formData.append("uploadingFile", file);

      try {
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_URL}/upload-file`,
          formData
        );
        if (uploadResponse.data.success) {
          setLoading(false);
          setAudioFileAPI(uploadResponse?.data.file_data);
        } else {
          setLoading(false);
          console.error(
            "Else Error in response of uploading image",
            uploadResponse.data.message
          );
        }
      } catch (error) {
        console.error("Catch Error in response of uploading image", error);
      }
    }
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [percentageValue, setPercentageValue] = useState("");

  const options = [
    { value: "Producer", label: "Producer" },
    { value: "Vocalist", label: "Vocalist" },
    { value: "Lyricist", label: "Lyricist" },
    { value: "Beat Maker", label: "Beat Maker" },
    { value: "Curator", label: "Curator" },
    { value: "Cover Designer", label: "Artwork Designer" },
  ];
  const openModal = () => {
    reset();
    setSelectedValue("");
    setPercentageValue("");
    handleRemoveAudio();
    setMessage("");
  };

  return (
    <ModalWrapper modalId="InviteModal2" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="InviteModal2"
        aria-hidden="true"
        aria-labelledby="InviteModal2"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header">
              <Heading3 className="modal-title">Invitation</Heading3>
            </div>
            {/* <form > */}
            <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
              <TextfieldLG className="uni-mb-32 uni-mt-16 ctm-txt">
                Define your invitation parameters
              </TextfieldLG>

              <TextfieldLG className="text-content mt-0 uni-mb-16">
                Role
              </TextfieldLG>

              <DropworksDisableButton
                className={`custom-select-dropdown form-control ${
                  isSubmitted &&
                  (errors.role
                    ? "is-invalid b-red-2"
                    : !errors.role
                    ? "is-valid  b-green-2"
                    : "")
                }`}
              >
                <SelectArrowDropdown
                  // options={userAllRole.map((role:any) => ({ value: role, label: role }))}
                  options={options}
                  selectedValue={selectedValue}
                  placeHolderValue="Select"
                  onChangeValue={onChange}
                ></SelectArrowDropdown>
              </DropworksDisableButton>
              {errors.role && typeof errors.role.message === "string" && (
                <p className="invalid-feedback error-red">
                  {errors.role.message}
                </p>
              )}
              <TextfieldLG className="text-content uni-mt-16 ">
                Percentage to offer​
              </TextfieldLG>
              <DropworksInput
                placeholder="Percentage %"
                className={`form-control ${
                  isSubmitted &&
                  (errors.percentageOffered
                    ? "is-invalid b-red-2"
                    : !errors.percentageOffered
                    ? "is-valid  b-green-2"
                    : "")
                }`}
                value={percentageValue ? percentageValue + "%" : ""}
                maxLength={3}
                onKeyDown={(e: any) => {
                  if (
                    e.key === "Backspace" &&
                    e.target.selectionStart === e.target.value.length
                  ) {
                    e.preventDefault();
                    const updatedValue = percentageValue.slice(0, -1);
                    setPercentageValue(updatedValue);
                    setValue("percentageOffered", updatedValue, {
                      shouldValidate: true,
                    });
                  }
                }}
                onInput={(e: any) => {
                  let check = e.target.value.replace(/[^0-9]/g, "");
                  if (check.charAt(0) === "0") {
                    check = check.slice(1); // Remove the first character if it's '0'
                  }
                  if (
                    Number(check) >
                    (path.includes("track-project-rerelease")
                      ? reReleaseTrackDetails?.trackData?.remainingPercentage -
                        1
                      : trackDetails?.trackData?.remainingPercentage - 1)
                  ) {
                    return;
                  }
                  setPercentageValue(check);
                  setValue("percentageOffered", check, {
                    shouldValidate: true,
                  });
                }}
              />
              {path.includes("track-project-rerelease") ? (
                <span className="text-success">
                  Currently available percentage :{" "}
                  {Math.max(
                    0,
                    (reReleaseTrackDetails?.trackData?.remainingPercentage -
                      1 || 0) - (Number(percentageValue) || 0)
                  )}
                </span>
              ) : (
                <span className="text-success">
                  Currently available percentage :{" "}
                  {Math.max(
                    0,
                    (trackDetails?.trackData?.remainingPercentage - 1 || 0) -
                      (Number(percentageValue) || 0)
                  )}
                  %
                </span>
              )}

              {isSubmitted &&
                errors.percentageOffered &&
                typeof errors.percentageOffered.message === "string" && (
                  <p className="invalid-feedback error-red">
                    {errors.percentageOffered.message}
                  </p>
                )}

              <TextfieldLG className="text-content uni-mb-16 uni-mt-32">
                Message
              </TextfieldLG>
              <DropworksTextArea
                className="invite-textarea "
                placeholder="Message (Optional)"
                value={message}
                onChange={(e: any) => {
                  setValue("message", e.target.value, { shouldValidate: true });
                  setMessage(e.target.value);
                }}
                hideIcon={true}
              />

              <TextfieldLG className="text-content uni-mb-16 uni-mt-32">
                Preview
              </TextfieldLG>
              <TextfieldXSM className="uni-mb-16 mt-0 preview-text">
                You may optionally attach a audio file in this invitation
              </TextfieldXSM>
              <div className="audio-upload-section">
                {audioFileAPI ? (
                  <div className="audio-file-info d-flex align-items-center justify-content-between">
                    <TextfieldSM className="file-name mx-0 my-0">
                      {(() => {
                        const fileName = uploadedAudioFile.name;
                        const extension = fileName.slice(
                          fileName.lastIndexOf(".")
                        );
                        const nameWithoutExtension = fileName.slice(
                          0,
                          fileName.lastIndexOf(".")
                        );

                        return nameWithoutExtension.length > 10
                          ? `${nameWithoutExtension.slice(
                              0,
                              10
                            )}...${extension}`
                          : fileName;
                      })()}
                    </TextfieldSM>

                    <span
                      className="remove-file-icon ml-2"
                      onClick={handleRemoveAudio}
                    >
                      X
                    </span>
                  </div>
                ) : loading ? (
                  <Loader className="uni-mt-24" />
                ) : (
                  <label htmlFor="audio-uploadss" className="upload-label">
                    <div className="beatEmpty_uploadbox flex-column">
                      <img src={iconImg} className="audio-img cursor-pointer" />
                      <label className="uni-pt-16 uni-pb-16 beat-text">
                        Upload
                      </label>
                    </div>
                  </label>
                )}
                <input
                  id="audio-uploadss"
                  type="file"
                  accept=".mp3, .wav, .m4a"
                  onChange={(e) => {
                    handleAudioUpload(e);
                    e.target.value = ""; // Reset the input's value
                  }}
                  className="d-none"
                />
              </div>
            </div>
            <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
              {loading2 ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  {path.includes("user-profile") ? (
                    <ButtonComponent id="InviteModal">
                      <DropworksSmallButton className="btn-wid social-link-modal-btns btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>
                  ) : (
                    <ButtonComponent id="InviteModal8">
                      <DropworksSmallButton className="btn-wid social-link-modal-btns btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>
                  )}
                  <DropworksSmallButton
                    className={"btn-wid social-link-modal-btns m-0"}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Invite
                  </DropworksSmallButton>
                </>
              )}
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
