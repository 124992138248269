import view, { openPopup } from "./view";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CheckAuthentication, setLocalStorageObject } from "../../utils/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { setAuthentication, setHeaderValue, setWalletAddress } from "../../../../redux/reducer/auth";
import { RootState } from "../../../../redux/store";
import { getUserData, logoutApi } from "../../utils/apiServices";
import { setUserData } from "../../../../redux/reducer/userSlice";

const LogoHeader = () => {
  const dropAnimationRef: any = useRef(null);
  const userData = useSelector((state: any) => state.user);
  const { displayName } = userData;


  const dispatch = useDispatch();
  const { authentication: isLoggedin} = useSelector((state: RootState) => state.auth) || false;
  const logoutUser = () => {
    localStorage.removeItem("dropworksToken");
    logoutApi();
    dispatch(setAuthentication(false))
  }
  useEffect(() => {
    let value = CheckAuthentication();
    dispatch(setAuthentication(value));
    dispatch(setHeaderValue(1));
  }, []);
  const navigate = useNavigate();

  const navigateTo = (value: string) => () => {
    navigate(value);

  };
  useEffect(() => {
    const fetchUserData = async () => {
      const result = await getUserData(); // Call your API function
      if (result.success && result.userData) { // Adjust based on your API response
        if (result.userData.data.userdata.address) {
          dispatch(setWalletAddress(result.userData.data.userdata?.address))
        }
        if (result.userData.data.userdata.address === null) {
          openPopup("OneTimePinModal", dispatch);
        }
        else if (result.userData.data.userdata.is_downloaded_pin === 0) {
          openPopup("downloadPinOneTime", dispatch);
        }
        dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
      }
    };

    fetchUserData();
  }, []);

  return view({ dropAnimationRef, navigateTo, isLoggedin, logoutUser, displayName, profileImg: userData?.profileImg});

}

export default LogoHeader;

