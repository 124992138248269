import { FC, useState } from "react";
import "./styles.scss";
import Tabs from "../../../../common/tabs";
import {
  DropworkGhostButton,
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TextfieldLG } from "../../../../common/custom-field/dropstock-text";
import TabsComponent from "../../../../common/tabs-component/view";
import { viewType } from "./modal";
import {
  Heading2,
  Heading5,
} from "../../../../common/custom-field/dropstock-heading";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import CustomTooltip from "../../../../common/custom-tooltip/view";
import { formatNumberWithCommas } from "../../../shared/utils/localStorage";
import { useNavigate } from "react-router-dom";

type SortOrder = 1 | -1 | null;

export const view: FC<viewType> = ({ navigateTo, handleSourceTypeChange }) => {
  const [tabsValue, setTabValue] = useState(0);
  const [sortField, setSortField] = useState<string | undefined>(undefined); // Initialize as undefined
  const [sortOrder, setSortOrder] = useState<SortOrder>(null);

  const { walletDetails, walletDropStocks, walletData, walletTransactions } =
    useSelector((state: RootState) => state.wallet);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  const handleSort = (e: any) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const TrackLink = ({ slugName, name }:any) => {
    const navigate = useNavigate();
  
    return (
      <div
        onClick={() => navigate(`/track-page/${slugName}`)}
        className="cursor-pointer"
      >
        {name || "-"}
      </div>
    );
  };
  return (
    <div className="new-wallet container container-xl container-xxl min-height-cover">
      <Heading2 className="new-wallet-heading uni-mb-40 ">Wallet</Heading2>
      <Tabs
        id="13"
        // tabsTitle={["Overview", "Transactions", "Orders"]}
        tabsTitle={["Overview", "Transactions"]}
        className="uni-mb-40"
        checkTabState={checkTabState}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <div>
            <div className="overview">
              <div className="overview-total-balance uni-mt-40 uni-mb-40 ">
                <div className="wallet-amount-section">
                  <TextfieldLG className="d-grid">
                    Total Balance
                    <div className="balance-amount d-flex">
                      {walletDropStocks?.usdc?.symbol || ""}{" "}
                      {walletDropStocks?.usdc?.balance || ""}
                      <div className="d-flex uni-ml-56 withdraw-deposit gap-3">
                        <ButtonComponent id="WalletWithdrawTypeModal">
                          <DropworksDisableButton className="withdraw-button wallet-btns">
                            Withdraw
                          </DropworksDisableButton>
                        </ButtonComponent>
                        <ButtonComponent id="WalletDepositTypeModal">
                          <DropworksSmallButton className="deposit-button wallet-btns">
                            Deposit
                          </DropworksSmallButton>
                        </ButtonComponent>
                      </div>
                    </div>
                  </TextfieldLG>
                </div>
              </div>
              <Heading5 className="liquid-asstes-head uni-mb-32">
                Liquid assets
              </Heading5>
              <div className="liquid-assets uni-mb-56">
                <div className="table-view">
                  <div className="table-bg-parent">
                    <div className="table-wrapper mobile-view-wrapper">
                      <table className="table custom-table-1 mb-0">
                        <thead className="text-dark d-flex align-items-center justify-content-between mobile-table-thead">
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <th
                              scope="col "
                              className="space-table-head asset-wid"
                            >
                              Asset
                            </th>
                            <th scope="col" className="space-table-head">
                              Quantity/ Value
                            </th>
                            <th scope="col" className="space-table-head">
                              Price/ Avg. Cost
                            </th>
                            <th scope="col" className="space-table-head">
                              Royalties
                            </th>
                          </tr>
                        </thead>
                        <tbody className="uni-mt-32">
                          {/* <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <td className="date-time-2">
                              <div className="d-flex gap-3 align-items-center bottom-table">
                                <span className="select-box wallet-select-box"></span>
                                <div
                                  className="d-flex cursor-pointer"
                                  onClick={navigateTo("/track-page")}
                                >
                                  <TextfieldLG className="my-0  p-0 select-title">
                                    DropWorks Composite
                                  </TextfieldLG>
                                </div>
                              </div>
                            </td>
                            <td className="quantity">
                              <span className="table-data">
                                {walletDropStocks?.dwc?.balance || ""}
                              </span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td className="price">
                              <span>-</span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td>
                              {walletDropStocks?.dwc?.royality.symbol || ""}{" "}
                              {walletDropStocks?.dwc?.royality.balance}
                            </td>
                          </tr>
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <td className="date-time-2">
                              <div className="d-flex gap-3 align-items-center bottom-table">
                                <span className="select-box wallet-select-box"></span>
                                <div
                                  className="d-flex cursor-pointer"
                                  onClick={navigateTo(
                                    "/marketplace-dropworks-select"
                                  )}
                                >
                                  <TextfieldLG className="my-0  p-0 select-title">
                                    DropWorks Select
                                  </TextfieldLG>
                                </div>
                              </div>
                            </td>
                            <td className="quantity">
                              <span className="table-data">
                                {walletDropStocks?.dws?.balance}
                              </span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td className="price">
                              <span>-</span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td>
                              {" "}
                              {walletDropStocks?.dws?.royality?.symbol ||
                                ""}{" "}
                              {walletDropStocks?.dws?.royality?.balance}
                            </td>
                          </tr> */}
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <td className="date-time-2">
                              <div className="d-flex gap-3 align-items-center bottom-table">
                                <span className="select-box wallet-select-box bg-white"></span>
                                <div className="d-flex">
                                  <TextfieldLG className="my-0  p-0 select-title">
                                    USD
                                  </TextfieldLG>
                                </div>
                              </div>
                            </td>
                            <td className="quantity">
                              <span className="table-data">
                                {walletDropStocks?.usdc?.symbol || ""}{" "}
                                {walletDropStocks?.usdc?.balance || ""}
                              </span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td className="price">
                              <span>-</span>
                            </td>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <Heading5 className="liquid-asstes-head uni-mb-32">
                DropStocks
              </Heading5>
              <div className="dropstocks-assets">
                <div className="table-view">
                  <div className="table-bg-parent">
                    <div className="table-wrapper">
                      <table className="table custom-table-2  mb-0">
                        <thead className="text-dark d-flex align-items-center justify-content-between mobile-table-thead">
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <th scope="col" className="space-table-head">
                              Asset
                            </th>
                            <th scope="col" className="space-table-head">
                              Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody className="uni-mt-32">
                          {walletDetails && walletDetails.length > 0 ? (
                            walletDetails.map((wallet: any, index: any) => (
                              <tr
                                key={index}
                                className="d-flex align-items-center justify-content-between table-row-dropstock"
                              >
                                <td className="date-time-2">
                                  <div className="d-flex gap-3 align-items-center bottom-table">
                                    <span className="select-box wallet-select-box">
                                      {wallet?.art_work_image &&
                                      wallet?.art_work_image.length > 0 ? (
                                        <>
                                          <img
                                            className="select-box bg-custom-trans"
                                            src={`${process.env.REACT_APP_IMGURL}/${wallet?.art_work_image[0].src}`}
                                            alt=""
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    <div
                                      className="d-flex cursor-pointer"
                                      onClick={navigateTo(
                                        `/track-page/${wallet.slug_name}`
                                      )}
                                    >
                                      <TextfieldLG className="my-0  p-0 select-title">
                                        {wallet.project_name}
                                      </TextfieldLG>
                                    </div>
                                  </div>
                                </td>
                                <td className="quantity">
                                  <span className="table-data">
                                    {formatNumberWithCommas(
                                      wallet.dropstockCount
                                    )}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center">
                                No DropStocks available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>,

          <div>
            <div className="d-flex gap-3 uni-mb-40 uni-mt-32 mt-mobile-0">
              <DropworkGhostButton
                className="table-btn-wallet"
                onClick={() => handleSourceTypeChange("balance")}
              >
                Balance
              </DropworkGhostButton>
              <DropworkGhostButton
                className="table-btn-wallet"
                onClick={() => handleSourceTypeChange("royalty")}
              >
                Royalties
              </DropworkGhostButton>
            </div>

            {/* Transactions data datable  */}
            <DataTable
              value={walletTransactions}
              className="prime-custom-table"
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="No transactions available."
              dataKey="id"
              sortField={sortField}
              sortOrder={sortOrder} // Correct type now
              onSort={handleSort} // Handle sort event
            >
              <Column
                field="date"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Date
                    {/* Rotate the icon based on sorting */}
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "date" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease", // Smooth transition for rotation
                      }}
                    />
                  </div>
                }
                body={(transaction) => (
                  <div className="date-time">
                    <span className="date">
                      {new Date(transaction.date).toLocaleDateString()}
                    </span>
                    <span className="time">
                      {new Date(transaction.date).toLocaleTimeString()}
                    </span>
                  </div>
                )}
                sortField="date"
              />

              <Column
                field="source"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Source
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "source" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
                body={(transaction) =>
                  transaction.source_type === "track_dropstock" ? (
                    <TrackLink slugName={transaction?.slug_name} name={transaction?.name} />
                  ) : (
                    transaction.source_type || "-"
                  )
                }
              />

              <Column
                field="type"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Type
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "type" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
              />

              <Column
                field="value"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Value
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "value" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
                body={(transaction) =>
                  `${transaction.amount_symbol} ${transaction.amount}`
                }
              />

              <Column
                field="status"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Status
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "status" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
                body={(transaction) =>
                  transaction.status === "success" ? "Completed" : "Processing"
                }
              />

              <Column
                field="method"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Method
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "method" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
              />

              <Column
                field="gas_used"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Gas used
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "gas_used" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
                body={(transaction) => (
                  <div>
                    <CustomTooltip title={transaction.gas_used} position="top">
                      <span
                        data-pr-tooltip={transaction.gas_used}
                        data-pr-position="top"
                        style={{ cursor: "pointer" }}
                      >
                        {Number(transaction.gas_used).toFixed(4)}{" "}
                      </span>
                    </CustomTooltip>
                  </div>
                )}
              />

              <Column
                field="gas_unit"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Gas Unit
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "gas_unit" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
              />
            </DataTable>
          </div>,

          // Order Data Table for we release marketplace
          // <div>
          //   <div className="d-flex gap-3 uni-mb-40">
          //     <DropworkGhostButton className="table-btn-wallet">
          //       Active
          //     </DropworkGhostButton>
          //     <DropworkGhostButton className="table-btn-wallet">
          //       History
          //     </DropworkGhostButton>
          //   </div>

          //   {/* Orders data datable  */}
          //   <DataTable
          //     value={[]}
          //     className="prime-custom-table"
          //     tableStyle={{ minWidth: "50rem" }}
          //     emptyMessage="No orders available."
          //     dataKey="id"
          //     // sortField={sortField}
          //     // sortOrder={sortOrder} // Correct type now
          //     // onSort={handleSort} // Handle sort event
          //   >
          //     <Column
          //       field="date"
          //       sortable
          //       header={
          //         <div style={{ display: "flex", alignItems: "center" }}>
          //           Date
          //           {/* Rotate the icon based on sorting */}
          //           <RiArrowDropDownLine
          //             style={{
          //               marginLeft: "0.5rem",
          //               // transform:
          //               //   sortField === "date" && sortOrder === 1
          //               //     ? "rotate(180deg)"
          //               //     : "rotate(0deg)",
          //               transition: "transform 0.3s ease", // Smooth transition for rotation
          //             }}
          //           />
          //         </div>
          //       }
          //       body={(order) => (
          //         <div className="date-time">
          //           <span className="date">
          //             {new Date(order.date).toLocaleDateString()}
          //           </span>
          //           <span className="time">
          //             {new Date(order.date).toLocaleTimeString()}
          //           </span>
          //         </div>
          //       )}
          //       sortField="date"
          //     />

          //     <Column
          //       field="Symbol"
          //       sortable
          //       header={
          //         <div style={{ display: "flex", alignItems: "center" }}>
          //           Symbol
          //           <RiArrowDropDownLine
          //             style={{
          //               marginLeft: "0.5rem",
          //               // transform:
          //               //   sortField === "source" && sortOrder === 1
          //               //     ? "rotate(180deg)"
          //               //     : "rotate(0deg)",
          //               transition: "transform 0.3s ease",
          //             }}
          //           />
          //         </div>
          //       }
          //       body={(order) =>
          //         order.source_type === "track"
          //           ? order.name || "-"
          //           : order.source_type || "-"
          //       }
          //     />

          //     <Column
          //       field="Type"
          //       sortable
          //       header={
          //         <div style={{ display: "flex", alignItems: "center" }}>
          //           Type
          //           <RiArrowDropDownLine
          //             style={{
          //               marginLeft: "0.5rem",
          //               // transform:
          //               //   sortField === "type" && sortOrder === 1
          //               //     ? "rotate(180deg)"
          //               //     : "rotate(0deg)",
          //               transition: "transform 0.3s ease",
          //             }}
          //           />
          //         </div>
          //       }
          //     />

          //     <Column
          //       field="Quantity/Value"
          //       sortable
          //       header={
          //         <div style={{ display: "flex", alignItems: "center" }}>
          //           Quantity/Value
          //           <RiArrowDropDownLine
          //             style={{
          //               marginLeft: "0.5rem",
          //               // transform:
          //               //   sortField === "value" && sortOrder === 1
          //               //     ? "rotate(180deg)"
          //               //     : "rotate(0deg)",
          //               transition: "transform 0.3s ease",
          //             }}
          //           />
          //         </div>
          //       }
          //       body={(order) =>
          //         `${order.amount_symbol} ${order.amount}`
          //       }
          //     />

          //     <Column
          //       field="status"
          //       sortable
          //       header={
          //         <div style={{ display: "flex", alignItems: "center" }}>
          //           Status
          //           <RiArrowDropDownLine
          //             style={{
          //               marginLeft: "0.5rem",
          //               // transform:
          //               //   sortField === "status" && sortOrder === 1
          //               //     ? "rotate(180deg)"
          //               //     : "rotate(0deg)",
          //               transition: "transform 0.3s ease",
          //             }}
          //           />
          //         </div>
          //       }
          //       body={(order) =>
          //         order.status === "success" ? "Completed" : "Processing"
          //       }
          //     />
          //   </DataTable>
          // </div>,
        ]}
      />
    </div>
  );
};
