import { FC } from "react";
import "./styles.scss";
import { DropworksSearchInput } from "../../../../common/custom-field/dropworks-search-input";
import { TrackIcon } from "../../../../common/track-icon";
import { ProjectIcon } from "../../../../common/project-icon";
import { BeatesIcon } from "../../../../common/beats-icon";
import searchIcon from "../../../../assets/img/searchIcon.svg";
import {
  Heading2,
  Heading5,
} from "../../../../common/custom-field/dropstock-heading";
import Tabs from "../../../../common/tabs";
import TabsComponent from "../../../../common/tabs-component/view";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoCloseCircleOutline } from "react-icons/io5";
import { UserIcon } from "../../../../common/user-icon";

export const view: FC = ({
  tabsValue,
  checkTabState,
  dropDownArrTracks,
  dropDownArrRoles,
  selectedRoles,
  beatsarr,
  projectarr,
  trackarr,
  userArr,
  filteredUserArr,
  handleRoleChange1,
  selectedRoles1,
  filteredTrackarr,
  hendelChange,
  searchBeatsarr,
  searchProjectsarr,
  searchTracksarr,
  handleRoleChange,
}: any) => {
  return (
    <div className="search container container-xxl min-height-cover">
      <Heading2 className="mx-0 my-0 ">Favorites</Heading2>
      {tabsValue === 4 ? (
        <DropworksSearchInput
          className="uni-my-40 search-custm-input"
          getSearchValue={(e: any) => hendelChange(e)}
        />
      ) : (
        ""
      )}

      <Tabs
        id="12"
        tabsTitle={[
          "Users",
          "Tracks",
          "Projects",
          "Beats",
          <img
            src={searchIcon}
            alt="Image Tab "
            className="favorites-mobile-search"
          />,
        ]}
        className="uni-mb-40 uni-mt-40"
        checkTabState={checkTabState}
      />

      <TabsComponent
        tabsValue={tabsValue}
        components={[
          //****User Tab****//
          <>
            <div className="uni-mb-16">
              <div className="dropworks-dropdown">
                <button
                  className="dropworks-dropdown-button dropdown-toggle filter-btn uni-fw-700"
                  type="button"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {dropDownArrRoles.heading}{" "}
                  <RiArrowDropDownLine className="fs-4" />
                </button>

                <ul
                  className="dropdown-menu dropworks-dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  {dropDownArrRoles.option.map((data: any) => (
                    <li key={data}>
                      <label className="dropdown-item d-flex align-items-center justify-content-between dropworks-dropdown-menu-option">
                        <span className="text-wrap favorites-filter-data-font">
                          {data}
                        </span>
                        <input
                          type="checkbox"
                          name="role"
                          value={data}
                          checked={selectedRoles.includes(data)}
                          onChange={() => handleRoleChange(data)}
                          className="custom-checkbox me-2"
                        />
                        <span className="custom-radio"></span>
                      </label>
                    </li>
                  ))}
                </ul>
                {selectedRoles.map((role: any) => (
                  <button
                    key={role}
                    className="dropworks-dropdown-button filter-btn"
                  >
                    {role}{" "}
                    <IoCloseCircleOutline
                      onClick={() => handleRoleChange(role)}
                      className="dropworks-dropdown-button-icon"
                    />
                  </button>
                ))}
              </div>
            </div>
            <div className="search-usericon">
              <UserIcon
                arr={selectedRoles?.length > 0 ? filteredUserArr : userArr}
              />
            </div>
          </>,
          //****Tracks Tab****//
          <>
            <div className="uni-mb-16">
              <div className="dropworks-dropdown">
                <button
                  className="dropworks-dropdown-button dropdown-toggle  uni-fw-700"
                  type="button"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {dropDownArrTracks.heading}{" "}
                  <RiArrowDropDownLine className="fs-4" />
                </button>

                <ul
                  className="dropdown-menu dropworks-dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  {dropDownArrTracks.option.map((data: any) => (
                    <li key={data}>
                      <label className="dropdown-item d-flex align-items-center justify-content-between dropworks-dropdown-menu-option ">
                        <span className="text-wrap favorites-filter-data-font filter-option">
                          {data}
                        </span>
                        <input
                          type="checkbox"
                          name="role"
                          value={data}
                          checked={selectedRoles1.includes(data)}
                          onChange={() => handleRoleChange1(data)}
                          className="custom-checkbox me-2"
                        />
                        <span className="custom-radio"></span>
                      </label>
                    </li>
                  ))}
                </ul>

                {selectedRoles1.map((role: any) => (
                  <button key={role} className="dropworks-dropdown-button">
                    {role}{" "}
                    <IoCloseCircleOutline
                      onClick={() => handleRoleChange1(role)}
                      className="dropworks-dropdown-button-icon"
                    />
                  </button>
                ))}
              </div>
            </div>
            <div className="search-usericon">
              <TrackIcon
                arr={selectedRoles1?.length > 0 ? filteredTrackarr : trackarr}
              />
            </div>
          </>,
          //****Projects Tab****//
          <>
            <div className="search-usericon">
              <ProjectIcon arr={projectarr} />
            </div>
          </>,
          //****Beats Tab****//
          <>
            <div className="search-usericon">
              <BeatesIcon arr={beatsarr} />
            </div>
          </>,
          //****Search Tab****//
          <>
            <div className="search-usericon">
              {searchBeatsarr?.length > 0 ? (
                <>
                  <Heading5 className="search-subheading">Beats</Heading5>
                  <BeatesIcon arr={searchBeatsarr} />
                </>
              ) : (
                ""
              )}
              {searchTracksarr?.length > 0 ? (
                <>
                  <Heading5 className="search-subheading">track</Heading5>
                  <TrackIcon arr={searchTracksarr} />
                </>
              ) : (
                ""
              )}
              {searchProjectsarr?.length > 0 ? (
                <>
                  <Heading5 className="search-subheading">Project</Heading5>
                  <ProjectIcon arr={searchProjectsarr} />
                </>
              ) : (
                ""
              )}
            </div>
          </>,
        ]}
      />
    </div>
  );
};
