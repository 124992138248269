import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { ButtonComponent } from '../../logo-header/view';
import { FC} from 'react';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import Loader from '../../loader/view';
import { verificationOtp } from '../popup-modal';


const view:FC<verificationOtp> = ({phNumber,updateNumber,OpenPopup3,message,loading=false,modal3,cooldown,handleResendCode,timer,closeModalRef}) => {

  return (
    <div
    className="modal fade"
    id="Modal4"
    aria-hidden="true"
    aria-labelledby="Modal4"
    tabIndex={-1}
    data-bs-backdrop="static" 
    data-bs-keyboard="false"  
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
    <CloseModalButton ref={closeModalRef} className='d-none'></CloseModalButton>
      <div className="modal-content ">
    <div className='modal-cross-icon'><ButtonComponent id="acc-secured" ><img src={crossIcon} className='fs-1 uni-mr-16'/></ButtonComponent></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Protect your account</Heading3>
        </div>
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Confirm the code we’ve sent to your phone</TextfieldLG>
          <DropworksInput className="mb-0" placeholder="Code" value={phNumber}
          maxLength={6}
                  onInput={(e: any) => {
                    let check = e.target.value.replace(/[^0-9]/g, "");
                    updateNumber(check)
                    // Remove non-numeric characters
                  }} />
              {message && <p  className='text-danger w-100 '>{message}</p>}
          <TextfieldXSM
              className={`ctm-txt2 uni-mb-56 ${cooldown ? 'disabled' : ''}`}

            >
              <button onClick={handleResendCode} className='resend-btn mbl-mb-8 '
                disabled={cooldown}>  {cooldown ? `Resend in ${timer}s` : 'Resend Code'}</button>
            </TextfieldXSM>
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        {loading?<Loader />:<DropworksSmallButton  disabled={phNumber?false:true} className={phNumber?"btn-wid  modal-btns m-0":"btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"} onClick={OpenPopup3}>Next</DropworksSmallButton>}
        </div>
        
      </div>
    </div>
  </div>
  )
}

export default view;
