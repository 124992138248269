import { useNavigate } from "react-router-dom";
import { view } from "./view";
import { useEffect, useState } from "react";
import {
  getFavoritesData,
  getFavoritesSearch,
} from "../../../shared/utils/apiServices";

export const Favorites = () => {
  const navigate = useNavigate();

  const [favoritesData, setFavoritesData] = useState<any>([]);
  const [favoritesSearchData, setFavoritesSearchData] = useState<any>([]);
  const [userArr, setUserArr] = useState([
    // { heading: "Usdhfdhsdghdahadfher Name", subheading: "@username" },
  ]);

  const [filteredUserArr, setFilteredUserArr] = useState([
    
    { heading: "", subheading: "", image: "", slugName: ""},
  ]);
  const [beatsarr, setBeatsarr] = useState([]);
  const [projectarr, setProjectarr] = useState<any>([]);
  const [trackarr, setTrackarr] = useState([]);
  const [selectedRoles1, setSelectedRoles1] = useState<string[]>([]);
  const [filteredTrackarr, setFilteredTrackarr] = useState([
    { heading: "", subheading: "", image: "", slugName: "" },
  ]);
  const [searchBeatsarr, setSearchBeatsarr] = useState([]);
  const [searchTracksarr, setSearchTracksarr] = useState([]);
  const [searchProjectsarr, setSearchProjectsarr] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [tabsValue, setTabValue] = useState(0);
  const dropDownArrRoles = {
    heading: "Roles",
    option: [
      "Music Preneur",
      "Producer",
      "Vocalist",
      "Lyricist",
      "Beat Maker",
      "Curator",
      "Artwork Designer",
    ],
  };
  const dropDownArrTracks={
    heading: "Genre",
    option: [
      "R&B",
      "Hip Hop",
      "Rock",
      "Pop",
      "Electropop",
      "Dance - pop",
      "J - pop",
      "K - pop",
      "EDM",
      "House",
      "Deep House",
      "Tech House",
      "Progressive House",
      "Electro House",
      "Techno",
      "Peak Time Techno",
      "Melodic House Techno",
      "Progressive Techno",
      "Trance",
      "Future Bass",
      "Latina",
      "Alterative",
      "LoFi",
      "Ballad",
      "Others",
    ],
  };

  const navigateTo = (value: string) => () => {
    navigate(value);
  };

  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  const handleRoleChange = (role: string) => {
    console.log(role,selectedRoles,"rolerole")
    setSelectedRoles((prev) =>
      prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
    );
  };

  const handleRoleChange1 = (role: string) => {
    setSelectedRoles1((prev) =>
      prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
    );
  };

  const hendelChange = async (data: any) => {
    const response = await getFavoritesSearch(data);
    if (response.success) {
      setFavoritesSearchData(response.favoritesSearchData);
    }
  };

  useEffect(() => {
    const fetchFavoritesData = async () => {
      const result = await getFavoritesData();
      if (result?.success) {
        setFavoritesData(result.favoritesData);
      }
    };

    fetchFavoritesData();
  }, []);

  useEffect(() => {
    if (Array.isArray(favoritesData?.userData)) {

      setUserArr(
        favoritesData?.userData.map((item: any) => ({
          heading: item.display_name,
          subheading: item.username,
          image: item.profile_image[0].src,
          slugName: item.slug_name,
          fullUserData: [item],
        }))
      );
      
    }
  }, [favoritesData?.userData]);

  useEffect(() => {
    if (Array.isArray(favoritesData?.beatData)) {
      setBeatsarr(
        favoritesData?.beatData.map((item: any) => ({
          heading: item.beat_name,
          subheading: item.description,
          image: item.art_work_image[0].src,
          beatName: item.beat_slug_name,
          fullBeatData: [item],
        }))
      );
    }
  }, [favoritesData?.beatData]);

  useEffect(() => {
    if (Array.isArray(favoritesData?.projectData)) {
      setProjectarr(
        favoritesData?.projectData.map((item: any) => ({
          heading: item.project_name,
          subheading: item.description,
          image: item.art_work_image[0]?.src,
          slugName: item.slug_name,
        }))
      );
    }
  }, [favoritesData.projectData]);

  useEffect(() => {
    if (Array.isArray(favoritesData?.trackData)) {
      setTrackarr(
        favoritesData?.trackData.map((item: any) => ({
          heading: item.track_name,
          subheading: item.description,
          image: item.art_work_image[0].src,
          slugName: item.slug_name,
        }))
      );
    }
  }, [favoritesData.trackData]);

  useEffect(() => {
  
    if (selectedRoles.length > 0) {
      // Filter the users where all selected roles are included in the user's roles
      const filteredUser = favoritesData?.userData?.filter((user: any) =>
        selectedRoles.every((role: any) => user.role?.includes(role)) // Ensure all selectedRoles exist in user.role
      );
  
      // Safely map the filtered user data
      setFilteredUserArr(
        filteredUser?.map((item: any) => ({
          heading: item.display_name,
          subheading: item.username,
          image: item.profile_image?.[0]?.src || '', // Safely access the image URL
          slugName: item.slug_name,
        })) || [] // Ensure empty array if no results
      );
    } else {
      // If no selected roles, just map all the user data
      setFilteredUserArr(
        favoritesData?.userData?.map((item: any) => ({
          heading: item.display_name,
          subheading: item.username,
          image: item.profile_image?.[0]?.src || '', 
          slugName: item.slug_name,
        })) || []
      );
    }
  }, [selectedRoles, favoritesData]);

  useEffect(() => {
    if (selectedRoles1.length > 0) {
      console.log( favoritesData?.trackData,"trackData")
      const filteredTrack = favoritesData?.trackData?.filter((track: any) =>
        selectedRoles1.some((role: any) => track.genre.includes(role))
      );
      setFilteredTrackarr(
        filteredTrack?.map((item: any) => ({
          heading: item.track_name,
          subheading: item.description,
          image: item.art_work_image[0].src || '',
          slugName: item.slug_name,
        }))|| []
      );
    } else {
      setFilteredTrackarr(
        favoritesData?.trackData?.map((item: any) => ({
          heading: item.track_name,
          subheading: item.description,
          image: item.art_work_image[0].src || '',
          slugName: item.slug_name,
        }))|| []
      );
    }
  }, [selectedRoles1]);


  useEffect(() => {
    if (Array.isArray(favoritesSearchData?.beatData)) {
      setSearchBeatsarr(
        favoritesSearchData?.beatData.map((item: any) => ({
          heading: item.beat_name,
          subheading: item.description,
          image: item.art_work_image[0].src,
          beatName: item.beat_slug_name,
          fullBeatData: [item],
        }))
      );
    }

    if (Array.isArray(favoritesSearchData?.projectData)) {
      setSearchProjectsarr(
        favoritesSearchData?.projectData.map((item: any) => ({
          heading: item.project_name,
          subheading: item.description,
          image: item.art_work_image[0]?.src,
          slugName: item.slug_name,
        }))
      );
    }

    if (Array.isArray(favoritesSearchData?.trackData)) {
      setSearchTracksarr(
        favoritesSearchData?.trackData.map((item: any) => ({
          heading: item.track_name,
          subheading: item.description,
          image: item.art_work_image[0].src,
          slugName: item.slug_name,
        }))
      );
    }
  }, [favoritesSearchData]);

  return view({
    tabsValue,
    dropDownArrTracks,
    dropDownArrRoles,
    selectedRoles,
    beatsarr,
    projectarr,
    trackarr,
    searchBeatsarr,
    searchProjectsarr,
    searchTracksarr,
    checkTabState,
    handleRoleChange,
    handleRoleChange1,
    selectedRoles1,
    filteredTrackarr,
    userArr,
    filteredUserArr,
    hendelChange,
    navigateTo,
  });
};
