import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { CopyBox } from "../../../../../common/copy-box";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const view: FC<any> = ({ closeModalRef, openModal }) => {
  const { receiverAddress } = useSelector((state: RootState) => state.auth);
  return (
    <ModalWrapper modalId="WalletUsdcDepositModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="WalletUsdcDepositModal"
        aria-hidden="true"
        aria-labelledby="WalletUsdcDepositModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content uni-md-popup">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-16">
              <Heading3 className="modal-title">USDC Deposit</Heading3>
            </div>

            <div className="modal-body uni-mb-56">
              <TextfieldLG className="ctm-txt ctm-md-lh uni-fw-700 mt-0">
                Account deposit of USD Coin
              </TextfieldLG>
              <TextfieldXSM className="ds-ctm-txt mt-0 uni-mb-8 uni-mb-56 ">
                Use the below information to deposit. Make sure the address and
                the network are the same, otherwise you may not receive the
                funds.
              </TextfieldXSM>
              <div className="align-items-center parent-qr">
                <div className="bg-light uni-p-24 border-radius-10">
                  <QRCode
                    value={receiverAddress || "not found"}
                    size={152}
                    level="H"
                  />
                </div>
                <div className="w-100">
                  <TextfieldLG className="gray4 uni-fw-700">
                    Account Address
                  </TextfieldLG>
                  <CopyBox text={receiverAddress} showButton={false} className="uni-mb-16 w-100" />
                </div>
              </div>
              <div className="custom-pass-space uni-mt-32">
                <TextfieldLG className="gray4 uni-fw-700 m-0">Network</TextfieldLG>
                <DropworksInput
                  disabled={true}
                  placeholder="Polygon POS"
                  className={`uni-mt-16  form-control login-cstm-pass `}
                />
              </div>
            </div>
            <div className="modal-footer social-mobile-modal-footer   ps-0 pt-0 pb-0 uni-pr-72">
              <CloseModalButton>
                <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns">
                  Cancel
                </DropworksSmallButton>
              </CloseModalButton>
              <CloseModalButton>
                <DropworksSmallButton className="btn-wid m-0 my-0 social-link-modal-btns">
                  Confirm
                </DropworksSmallButton>
              </CloseModalButton>
            </div>
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
