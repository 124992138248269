import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import clipIcon from "../../../assets/steps/clip.svg";
import sendIcon from "../../../assets/steps/send button.svg";
import PdfImage from"../../../assets/img/pdf-image.png"
import DocImage from"../../../assets/img/doc-image.png"
import searchCrossIcon from "../../../assets/img/searchCrossIcon.svg";
import axios from "axios";
import EmojiPicker, { EmojiStyle, Theme } from "emoji-picker-react";
import { BsEmojiSmile } from "react-icons/bs";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  clearInput: () => void;
  sendMessage: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void; // Updated type
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  inputValue: any;
  callApiforfileupload:(data:any)=>void
  FunctionOnSecondSVG?: () => void;
  userData?:{name:string,value:string}
  onFileUploaded:(data:any)=>any
  onFileSizeError:(data:boolean)=>void
}


export const view: FC<InputProps> = ({
  inputValue,
  handleInputChange,
  clearInput,
  sendMessage: handleSendMessage,
  handleKeyDown,
  className = "",
  userData,
  onFileSizeError,
  callApiforfileupload,
  placeholder = "Search",
  id,
  ...rest
}) => {

  const textareaRef = useRef<HTMLTextAreaElement>(null); // Reference for the textarea
  const [totalRows,setLineCount]=useState(1)
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Trigger reflow to recalculate scrollHeight accurately
      textarea.style.height = 'auto'; // Reset the height temporarily
      const scrollHeight = textarea.scrollHeight; // Get accurate scrollHeight after reset

      // Determine line height based on updated scrollHeight
      const lineHeight = scrollHeight < 59 ? 1 : scrollHeight < 79 ? 2 : scrollHeight < 101 ? 3 : 4;

      setLineCount(lineHeight);
    }
  }, [inputValue]);


  const handleFileChange = async(event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {

      const maxFileSize = 20 * 1024 * 1024; // 20MB
      if (uploadedFile.size > maxFileSize) {
        onFileSizeError(true);
        // return;
      }
      else{
        callApiforfileupload(uploadedFile)
      }
      // setFile(uploadedFile);
      // setFileType(uploadedFile.type);
  }
  };

  const handleIconClick = () => {
    document.getElementById(`${id || `file-input`}`)?.click();
  };

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);

  // Toggle picker visibility
  const togglePicker = () => {
    setIsPickerOpen((prev) => !prev);
  };

  // Close picker on clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="message">
      {/* <div className="uni-ml-72">{renderPreview()}</div>  */}
      <div className={`message-inp  ${className}`} 
       style={{height:totalRows === 1 ?'56px':totalRows ===2?"78px":totalRows===3?"100px":"130px"}} 
      >

<button onClick={togglePicker} className="bg-transparent uni-pl-16"><BsEmojiSmile color="white"/></button>
     {isPickerOpen && (
        <div ref={pickerRef} style={{ position: "absolute", zIndex: 1000 ,bottom:60}}>
          <EmojiPicker
            theme={Theme.DARK}
            onEmojiClick={(emoji) => handleInputChange(emoji.emoji)}
            skinTonesDisabled={true}
          />
        </div>
      )}

      <input
        id={id || "file-input"}
        type="file"
        style={{ display: 'none' }} // Hide the input
        onChange={(e)=>{handleFileChange(e);e.target.value=''}}
        accept="image/*,application/pdf,video/*,.doc,.docx" // Accept specific file types
      />
 
        <img src={clipIcon} className="message-inp-icon mt-auto cursor-pointer uni-mb-16" alt="icon" onClick={handleIconClick} />
        <textarea
        ref={textareaRef}
        className="message-inp-input"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type your message here..."
        rows={1} 
   />
        <div className="message-inp-closeicon  mt-auto" >
          {/* {inputValue.length > 0 && ( */}
          <img
            src={sendIcon}
            className="message-inp-cross-icon  mt-auto   mx-auto uni-mb-16"
            onClick={handleSendMessage}
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
};
