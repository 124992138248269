import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { FC } from "react";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";
import king from "../../../../../assets/img/king.png";
import verfiedIcon from "../../../../../assets/steps/verified-badge.svg";
import { MdOutlineFileDownload } from "react-icons/md";
import download from "../../../../../assets/steps/download.svg";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { BlueTik } from "../../../../../common/all-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import UserIcon from "../../../../../assets/steps/Group 18414.svg";
import { dateFormat } from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";

interface viewType {
  navigateTo: (path: string) => () => void;
}

const view: FC<viewType> = ({ navigateTo }) => {
  const invite = useSelector((state: RootState) => state.invite);
  const navigate = useNavigate();
  return (
    <div
      className="modal fade"
      id="AcceptedInviteSent"
      aria-hidden="true"
      aria-labelledby="AcceptedInviteSent"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title ">
              Invite <span className="invite-received-accepted">Accepted</span>{" "}
            </Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            {/* <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">{invite.sentINvite[invite.selectInde]?.receiver_user_id?.display_name} accepted the invite to join your project</TextfieldLG> */}
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">
              {invite.sentINvite[invite.selectInde]?.receiver_user_id
                ?.display_name &&
                invite.sentINvite[
                  invite.selectInde
                ].receiver_user_id.display_name
                  .charAt(0)
                  .toUpperCase() +
                  invite.sentINvite[
                    invite.selectInde
                  ].receiver_user_id.display_name.slice(1)}
                   <span className="px-1">accepted the invite to join your project</span> 
            </TextfieldLG>

            <CloseModalButton>
              <div
                className="d-flex align-items-center uni-mb-32 invite_firstcolumn"
                onClick={() =>
                  navigate(
                    `/user-profile/${
                      invite.sentINvite[invite.selectInde]?.receiver_user_id
                        ?.slug_name
                    }`
                  )
                }
              >
                <img
                  src={`${process.env.REACT_APP_IMGURL}/${
                    invite.sentINvite[invite.selectInde]?.receiver_user_id
                      ?.profile_image?.[0]?.src
                  }`}
                  onError={(e) => (e.currentTarget.src = UserIcon)}
                  className="invite_firstcolumn_imagebox"
                  alt=""
                />

                <div
                  onClick={navigateTo("/user-profile")}
                  className="invite-modal-pointer"
                >
                  <h2 className="fw-700 text-light uni-mr-8">
                    {invite.sentINvite[invite.selectInde]?.receiver_name}
                    <span>
                      {" "}
                      <BlueTik />
                    </span>{" "}
                  </h2>
                  <TextfieldSM className="text-light">
                    @
                    {
                      invite.sentINvite[invite.selectInde]?.receiver_user_id
                        ?.username
                    }
                  </TextfieldSM>
                </div>
              </div>
            </CloseModalButton>

            <TextfieldSM className=" received-label uni-mb-16">
              Project
            </TextfieldSM>
            <DropworksInput
              placeholder={
                invite.sentINvite[invite.selectInde]?.track_id?.project_name
              }
              readOnly
              className="uni-mb-32 cstm-inp-invite disable-item"
            ></DropworksInput>

            <TextfieldSM className="received-label uni-mb-16 ">
              Role
            </TextfieldSM>
            <DropworksInput
              placeholder={
                invite.sentINvite[invite.selectInde]?.role.toLowerCase() ==
                "cover designer"
                  ? "Artwork Designer"
                  : invite.sentINvite[invite.selectInde]?.role
              }
              readOnly
              className="uni-mb-32 cstm-inp-invite disable-item"
            ></DropworksInput>

            <TextfieldSM className=" received-label uni-mb-16 ">
              Percentage to offer
            </TextfieldSM>
            <DropworksInput
              placeholder={
                invite.sentINvite[invite.selectInde]?.percentage_offered
              }
              readOnly
              className="uni-mb-32 cstm-inp-invite disable-item"
            ></DropworksInput>
            <TextfieldSM className="received-label uni-mb-16 ">
              Message
            </TextfieldSM>

            <DropworksTextArea
              className="uni-mb-32 cstm-txt-invite uni-p-16 disable-item"
              hideIcon={true}
              readOnly
              placeholder={invite.sentINvite[invite.selectInde]?.message}
            ></DropworksTextArea>
            {invite.sentINvite[invite.selectInde]?.upload_audio ? (
              <>
                <TextfieldLG className="text-light uni-mb-16">
                  Preview
                </TextfieldLG>
                <div className="d-flex justify-content-between uni-p-16 align-items-center uni-mb-32">
                  <TextfieldSM className="file-name">
                    {
                      invite.sentINvite[invite.selectInde]?.upload_audio?.[0]
                        ?.fileName
                    }
                  </TextfieldSM>

                  <TextfieldSM className="file-name">
                    <a
                      href={`${process.env.REACT_APP_IMGURL}/${
                        invite.sentINvite[invite.selectInde]?.upload_audio?.[0]
                          ?.src
                      }`}
                      download={
                        invite.sentINvite[invite.selectInde]?.upload_audio?.[0]
                          ?.fileName
                      }
                    >
                      <img src={download} alt="" className="uni-ml-8" />
                    </a>
                  </TextfieldSM>
                </div>
              </>
            ) : (
              ""
            )}
            <div className=" uni-mb-32">
              {/* <TextfieldSM className='file-name uni-mb-8'>View <span className='collaboration'>Collaboration Agreement</span></TextfieldSM> */}
              <TextfieldSM className="received-date ">
                Received on{" "}
                {dateFormat(invite.sentINvite[invite.selectInde]?.created_at)}
              </TextfieldSM>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
