import { FC } from "react";
import view from "./view";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";



export const MessageChatModal: FC<any> = () => {
  const {trackId}=useSelector((state:RootState)=>state.upload)

    const DontShow = () => null;

    return view();
}
export default MessageChatModal


