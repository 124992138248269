import { useState } from "react";
import { FC } from "react";
import "./styles.scss";
import { viewType } from "./modal";
import { FaChevronRight } from "react-icons/fa6";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";
import Tabs from "../../../common/tabs";
import settingicon from "../../../assets/steps/settingicon.svg";
import TabsComponent from "../../../common/tabs-component/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { updatReadCount } from "../../shared/utils/apiServices";
import { useNavigate } from "react-router-dom";
import MARKDOWN from "markdown-to-jsx";
import {
  fetchNotification,
  setInvitTab,
  setIsnotificationPage,
  setNotificationAfterClick,
  setSelectedNotification,
} from "../../../redux/reducer/uploadSlice";

const view: FC<viewType> = ({ navigateTo }) => {
  const [currentSection, setCurrentSection] = useState("Announcements");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notificationData } = useSelector((state: RootState) => state.upload);
  const [showMore, setshowMore] = useState({
    showMore1: false,
    showMore2: false,
    showMore3: false,
    showMore4: false,
    showMore5: false,
    showMore6: false,
    showMore7: false,
    showMore8: false,
    showMore9: false,
    showMore10: false,
    showMore11: false,
    showMore12: false,
    showMore13: false,
  });

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedType, setExpandedType] = useState("");

  function updateInviteData(data: any, itemIndex: number, type: string) {
    if (itemIndex < 0 || itemIndex >= data[type].items.length) {
      console.error("Invalid index for items");
      return data;
    }

    const updatedData = {
      ...data,
      [type]: {
        // Use dynamic key based on 'type'
        ...data[type],
        items: data[type].items.map((item: any, index: number) =>
          index === itemIndex ? { ...item, read_count: 0 } : { ...item }
        ),
      },
      allCount:
        data.allCount > 0 && data[type].items?.[itemIndex].read_count !== 0
          ? data.allCount - 1
          : data.allCount,
    };
    if (type == "announcement") {
      updatedData[type].announcementCount =
        updatedData[type].announcementCount > 0 &&
        updatedData[type].items?.[itemIndex].read_count !==
          data[type].items?.[itemIndex].read_count
          ? updatedData[type].announcementCount - 1
          : updatedData[type].announcementCount;
    } else if (type == "wallet") {
      updatedData[type].walletCount =
        updatedData[type].walletCount > 0 &&
        updatedData[type].items?.[itemIndex].read_count !==
          data[type].items?.[itemIndex].read_count
          ? updatedData[type].walletCount - 1
          : updatedData[type].walletCount;
    } else if (type == "invites") {
      updatedData[type].inviteCount =
        updatedData[type].inviteCount > 0 &&
        updatedData[type].items?.[itemIndex].read_count !==
          data[type].items?.[itemIndex].read_count
          ? updatedData[type].inviteCount - 1
          : updatedData[type].inviteCount;
    } else if (type == "marketplace") {
      updatedData[type].marketplaceCount =
        updatedData[type].marketplaceCount > 0 &&
        updatedData[type].items?.[itemIndex].read_count !==
          data[type].items?.[itemIndex].read_count
          ? updatedData[type].marketplaceCount - 1
          : updatedData[type].marketplaceCount;
    } else if (type == "favourites") {
      updatedData[type].favouriteCount =
        updatedData[type].favouriteCount > 0 &&
        updatedData[type].items?.[itemIndex].read_count !==
          data[type].items?.[itemIndex].read_count
          ? updatedData[type].favouriteCount - 1
          : updatedData[type].favouriteCount;
    }

    return updatedData;
  }

  const renderDescription = (
    description: string,
    isExpanded: boolean,
    type: string
  ) => {
    if (!description) return null;

    const [firstSentence, ...rest] = description.split(".");
    const remainingText = rest.join(".").trim();

    return (
      <>
        {/* Render the first sentence */}
        <p className="d-flex align-items-center uni-fs-400 fc-g3 ellipsis-icon justify-content-start">
          {firstSentence}.
        </p>

        {/* Render remaining text conditionally */}
        {isExpanded &&
          remainingText &&
          remainingText.split(".").map(
            (sentence, index) =>
              sentence.trim() && ( // Ensure empty strings are skipped
                <p
                  key={index}
                  className="d-flex align-items-center uni-fs-400 fc-g3 justify-content-start"
                >
                  {sentence}.
                </p>
              )
          )}
      </>
    );
  };

  const renderDescriptionForMark = (
    description: string,
    isExpanded: boolean,
    type: string
  ): string => {
    if (!description) return "";

    const [firstSentence, ...rest] = description.split(".");
    const remainingText = rest.join(".").trim();

    let result = `${firstSentence}.`;

    if (isExpanded && remainingText) {
      result += remainingText
        .split(".")
        .map((sentence) => sentence.trim())
        .filter((sentence) => sentence) // Skip empty strings
        .join(".");
    }

    return result;
  };

  const toggleExpand = async (index: any, type: string, id: any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    setExpandedType(type);
    const data = {
      type: type,
      id: id,
    };
    let newNotification = updateInviteData(notificationData, index, type);
    dispatch(setNotificationAfterClick(newNotification));
    const response = await updatReadCount(data);
    if (response.success) {
      // console.log("success after api is called")
    } else {
      // console.log("Failed to fetch data");
    }
  };

  const handleSectionClick = (section: string) => {
    setCurrentSection(section);
    setStatus("");
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
    setExpandedIndex(null);
  };

  const updateRead = async (type: string, item: string) => {
    const data = {
      type: type,
      is_own_item: item,
    };
    dispatch(setInvitTab(item));
    const response = await updatReadCount(data);
    if (response.success) {
      navigate("/invite");
      dispatch(fetchNotification() as any);
    }
  };

  return (
    <div className="invite container-xl container-xxl container min-height-cover">
      <Heading2 className="uni-mb-56">
        Notifications
        <span>
          <img
            src={settingicon}
            className="uni-ml-16 uni-fs-24 notification-setting-icon cursor-pointer"
            onClick={() => {
              navigate("/profile-setting");
              dispatch(setIsnotificationPage(true));
            }}
          />
        </span>
      </Heading2>

      <Tabs
        className="uni-mb-56"
        id="4"
        tabsTitle={[
          <div className="d-flex">
            Announcements
            {notificationData?.announcement?.announcementCount > 0 &&
            notificationData?.announcement ? (
              <span className="notify-yellow-dot uni-ml-8 "></span>
            ) : (
              ""
            )}
          </div>,
          <div className="d-flex">
            Wallet
            {notificationData?.wallet?.walletCount > 0 &&
            notificationData?.wallet ? (
              <span className="notify-yellow-dot uni-ml-8 "></span>
            ) : (
              ""
            )}
          </div>,
          <div className="d-flex">
            Studio
            {notificationData?.invites?.inviteCount > 0 &&
            notificationData?.invites ? (
              <span className="notify-yellow-dot uni-ml-8 "></span>
            ) : (
              ""
            )}
          </div>,
          <div className="d-flex">
            Marketplace
            {notificationData?.marketplace?.marketplaceCount > 0 &&
            notificationData?.marketplace ? (
              <span className="notify-yellow-dot uni-ml-8 "></span>
            ) : (
              ""
            )}
          </div>,
          <div className="d-flex">
            Favorites
            {notificationData?.favourites?.favouriteCount > 0 &&
            notificationData?.favourites ? (
              <span className="notify-yellow-dot uni-ml-8 "></span>
            ) : (
              ""
            )}
          </div>,
        ]}
        checkTabState={checkTabState}
      />

      <TabsComponent
        tabsValue={tabsValue}
        components={[
          //**** Announcements Notification ****//
          <>
            {notificationData?.announcement?.items?.length > 0
              ? notificationData?.announcement?.items?.map(
                  (data: any, index: any) => {
                    const isExpanded = expandedIndex === index;
                    const type = notificationData?.announcement?.items[0].type;
                    return (
                      <div className="uni-py-24 uni-fs-700 notify-section">
                        <p className="d-flex justify-content-between uni-mb-8">
                          <p className=" notify-subheading  justify-content-start">
                            {data?.message}
                            {data?.read_count == 1 ? (
                              <span className="notify-yellow-dot uni-ml-8"></span>
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="uni-fs-400 fc-g5 d-flex notification-mobile-date ">
                            {new Date(data?.created_at)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                              })
                              .split(" ")
                              .join(" ")
                              .toLowerCase()}
                          </p>
                        </p>
                        <p className="d-flex justify-content-between uni-mb-8  notification-longtext-mbl">
                          <p>
                            <p
                              className={`d-flex align-items-center uni-fs-400 fc-g3 ${
                                isExpanded
                                  ? "ellipsis-icon-isExpanded"
                                  : "ellipsis-icon"
                              } justify-content-start`}
                            >
                              <MARKDOWN>
                                {renderDescriptionForMark(
                                  data?.text,
                                  isExpanded,
                                  notificationData?.announcement
                                )}
                              </MARKDOWN>
                            </p>

                            {isExpanded && type == "announcement" ? (
                              <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start"></p>
                            ) : (
                              ""
                            )}
                          </p>
                          {isExpanded && type == "announcement" ? (
                            data.is_own_item == 0 || data.is_own_item == 1 ? (
                              <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                                <FaChevronRight
                                  className="cursor-pointer chevronright-mobileview"
                                  onClick={() => {
                                    updateRead(data.type, data.is_own_item);
                                    dispatch(setSelectedNotification(data));
                                    dispatch(setIsnotificationPage(true));
                                  }}
                                />
                              </p>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </p>

                        <p className="d-flex ">
                          <span
                            className="notify-show-more uni-fw-400 "
                            onClick={() =>
                              // setshowMore({ ...showMore, showMore8: !showMore.showMore8 })
                              toggleExpand(index, data.type, data._id)
                            }
                          >
                            {isExpanded && type == "announcement"
                              ? "Show Less"
                              : "Show More"}
                          </span>
                        </p>
                      </div>
                    );
                  }
                )
              : "No notifications available"}
          </>,

          //**** Wallet Notification ****//
          <>
            {notificationData?.wallet?.items?.length > 0
              ? notificationData?.wallet?.items?.map(
                  (data: any, index: any) => {
                    const isExpanded = expandedIndex === index;
                    const type = notificationData?.wallet?.items[0].type;
                    return (
                      <div className="uni-py-24 uni-fs-700 notify-section">
                        <p className="d-flex justify-content-between uni-mb-8">
                          <p className="d-flex align-items-center notify-subheading  justify-content-start">
                            {data?.message}
                            {data?.read_count == 1 ? (
                              <span className="notify-yellow-dot uni-ml-8"></span>
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="uni-fs-400 fc-g5 d-flex notification-mobile-date ">
                            {new Date(data?.created_at)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                              })
                              .split(" ")
                              .join(" ")
                              .toLowerCase()}
                          </p>
                        </p>
                        <p className="d-flex justify-content-between uni-mb-8  notification-longtext-mbl">
                          <p>
                            <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                              {renderDescription(
                                data?.description,
                                isExpanded,
                                notificationData?.wallet
                              )}
                            </p>

                            {isExpanded && type == "wallet" ? (
                              <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start"></p>
                            ) : (
                              ""
                            )}
                          </p>
                          {isExpanded && type == "wallet" ? (
                            data.is_own_item == 0 || data.is_own_item == 1 ? (
                              <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                                <FaChevronRight
                                  className="cursor-pointer chevronright-mobileview"
                                  onClick={() => {
                                    updateRead(data.type, data.is_own_item);
                                    dispatch(setSelectedNotification(data));
                                    dispatch(setIsnotificationPage(true));
                                  }}
                                />
                              </p>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </p>

                        <p className="d-flex ">
                          <span
                            className="notify-show-more uni-fw-400 "
                            onClick={() =>
                              // setshowMore({ ...showMore, showMore8: !showMore.showMore8 })
                              toggleExpand(index, data.type, data._id)
                            }
                          >
                            {isExpanded && type == "wallet"
                              ? "Show Less"
                              : "Show More"}
                          </span>
                        </p>
                      </div>
                    );
                  }
                )
              : "No notifications available"}
          </>,

          //**** Invites Notification ****//
          <>
            {notificationData?.invites?.items?.length > 0
              ? notificationData?.invites?.items?.map(
                  (data: any, index: any) => {
                    const isExpanded = expandedIndex === index;
                    const type = notificationData?.invites?.items[0].type;
                    return (
                      <div className="uni-py-24 uni-fs-700 notify-section">
                      <p className="d-flex justify-content-between uni-mb-8">
                        <p className=" notify-subheading  justify-content-start">
                          {data?.message}
                          {data?.read_count == 1 ? (
                            <span className="notify-yellow-dot uni-ml-8"></span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p className="uni-fs-400 fc-g5 d-flex notification-mobile-date ">
                          {new Date(data?.created_at)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                            })
                            .split(" ")
                            .join(" ")
                            .toLowerCase()}
                        </p>
                      </p>
                      <p className="d-flex justify-content-between uni-mb-8  notification-longtext-mbl">
                        <p>
                          <p
                            className={`d-flex align-items-center uni-fs-400 fc-g3 ${
                              isExpanded
                                ? "ellipsis-icon-isExpanded"
                                : "ellipsis-icon"
                            } justify-content-start`}
                          >
                            <MARKDOWN>
                              {renderDescriptionForMark(
                                data?.description,
                                isExpanded,
                                notificationData?.invites
                              )}
                            </MARKDOWN>
                          </p>

                          {isExpanded && type == "invites" ? (
                            <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start"></p>
                          ) : (
                            ""
                          )}
                        </p>
                        {isExpanded && type == "invites" ? (
                          data.is_own_item == 0 || data.is_own_item == 1 ? (
                            <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                              <FaChevronRight
                                className="cursor-pointer chevronright-mobileview"
                                onClick={() => {
                                  updateRead(data.type, data.is_own_item);
                                  dispatch(setSelectedNotification(data));
                                  dispatch(setIsnotificationPage(true));
                                }}
                              />
                            </p>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </p>

                      <p className="d-flex ">
                        <span
                          className="notify-show-more uni-fw-400 "
                          onClick={() =>
                            // setshowMore({ ...showMore, showMore8: !showMore.showMore8 })
                            toggleExpand(index, data.type, data._id)
                          }
                        >
                          {isExpanded && type == "invites"
                            ? "Show Less"
                            : "Show More"}
                        </span>
                      </p>
                    </div>
                    );
                  }
                )
              : "No notifications available"}
          </>,

          //**** Marketplace Notification ****//
          <>
            {notificationData?.marketplace?.items?.length > 0
              ? notificationData?.marketplace?.items?.map(
                  (data: any, index: any) => {
                    const isExpanded = expandedIndex === index;
                    const type = notificationData?.marketplace?.items[0].type;
                    return (
                      <div className="uni-py-24 uni-fs-700 notify-section">
                        <p className="d-flex justify-content-between uni-mb-8">
                          <p className="d-flex align-items-center notify-subheading  justify-content-start">
                            {data?.message}
                            {data?.read_count == 1 ? (
                              <span className="notify-yellow-dot uni-ml-8"></span>
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="uni-fs-400 fc-g5 d-flex notification-mobile-date ">
                            {new Date(data?.created_at)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                              })
                              .split(" ")
                              .join(" ")
                              .toLowerCase()}
                          </p>
                        </p>
                        <p className="d-flex justify-content-between uni-mb-8  notification-longtext-mbl">
                          <p>
                            <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                              {renderDescription(
                                data?.description,
                                isExpanded,
                                notificationData?.marketplace
                              )}
                            </p>

                            {isExpanded && type == "marketplace" ? (
                              <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start"></p>
                            ) : (
                              ""
                            )}
                          </p>
                          {isExpanded && type == "marketplace" ? (
                            data.is_own_item == 0 || data.is_own_item == 1 ? (
                              <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                                <FaChevronRight
                                  className="cursor-pointer chevronright-mobileview"
                                  onClick={() => {
                                    updateRead(data.type, data.is_own_item);
                                    dispatch(setSelectedNotification(data));
                                    dispatch(setIsnotificationPage(true));
                                  }}
                                />
                              </p>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </p>

                        <p className="d-flex ">
                          <span
                            className="notify-show-more uni-fw-400 "
                            onClick={() =>
                              // setshowMore({ ...showMore, showMore8: !showMore.showMore8 })
                              toggleExpand(index, data.type, data._id)
                            }
                          >
                            {isExpanded && type == "marketplace"
                              ? "Show Less"
                              : "Show More"}
                          </span>
                        </p>
                      </div>
                    );
                  }
                )
              : "No notifications available"}
          </>,

          //**** favourites Notification ****//
          <>
            {notificationData?.favourites?.items?.length > 0
              ? notificationData?.favourites?.items?.map(
                  (data: any, index: any) => {
                    const isExpanded = expandedIndex === index;
                    const type = notificationData?.favourites?.items[0].type;
                    return (
                      <div className="uni-py-24 uni-fs-700 notify-section">
                        <p className="d-flex justify-content-between uni-mb-8">
                          <p className="d-flex align-items-center notify-subheading  justify-content-start">
                            {data?.message}
                            {data?.read_count == 1 ? (
                              <span className="notify-yellow-dot uni-ml-8"></span>
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="uni-fs-400 fc-g5 d-flex notification-mobile-date ">
                            {new Date(data?.created_at)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                              })
                              .split(" ")
                              .join(" ")
                              .toLowerCase()}
                          </p>
                        </p>
                        <p className="d-flex justify-content-between uni-mb-8  notification-longtext-mbl">
                          <p>
                            <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                              {renderDescription(
                                data?.description,
                                isExpanded,
                                notificationData?.favourites
                              )}
                            </p>

                            {isExpanded && type == "favourites" ? (
                              <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start"></p>
                            ) : (
                              ""
                            )}
                          </p>
                          {isExpanded && type == "favourites" ? (
                            <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                              <FaChevronRight
                                className="cursor-pointer chevronright-mobileview"
                                onClick={() =>
                                  navigate(
                                    `/project-page/${data?.project_slug_name}`
                                  )
                                }
                              />
                            </p>
                          ) : (
                            ""
                          )}
                        </p>

                        <p className="d-flex ">
                          <span
                            className="notify-show-more uni-fw-400 "
                            onClick={() =>
                              toggleExpand(index, data.type, data._id)
                            }
                          >
                            {isExpanded && type == "favourites"
                              ? "Show Less"
                              : "Show More"}
                          </span>
                        </p>
                      </div>
                    );
                  }
                )
              : "No notifications available"}
          </>,
        ]}
      />
    </div>
  );
};

export default view;
