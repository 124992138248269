import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useState } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { HrLineMD } from "../../../../../common/hr-line";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  gasFeesCalculate,
  isAddressValidate,
  setWalletDetails,
} from "../../../../../redux/reducer/walletSlice";
import { AppDispatch } from "../../../../../redux/store";

export const view = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { trackNFTId, pin, type, amount, fiatSymbol, fiatPrice } = useSelector(
    (state: RootState) => state.wallet.walletData
  );
  const [loading, setLoading] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [allInsertValue, setAllInsertvValue] = useState({
    address: "",
  });
  const [netFeeDetails, setNetFeeDetails] = useState({
    fiatPrice1: "0.00",
    fiatSymbol1: "USDC",
  });
  const [allErrors, setAllErrors] = useState({
    address: 1,
    submit: 1,
    addressMessage: "",
  });
  const [receiverAddress1, setReceiverAddress1] = useState("");
  const closeModalRef = useRef<HTMLAnchorElement>(null);

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const IsValid = async (receiverAddress: any) => {
    setLoading(true)
    if (receiverAddress) {
      const payload = {
        receiverAddress,
      };
      dispatch(isAddressValidate(payload)).then((response: any) => {
        if (response.payload.success) {
          setLoading(false)
          setReceiverAddress1(receiverAddress);
          getNetworkFees(amount,receiverAddress);
          setAllErrors((prev) => ({ ...prev, address: 3, addressMessage: "" }));
        } else {
          setLoading(false)
          if (receiverAddress) {
            setAllErrors((prev) => ({
              ...prev,
              address: 2,
              addressMessage: response.payload.message,
            }));
          }
        }
      });
    }
  };

  const getNetworkFees = async (amount: any,receiverAddress:any) => {
    console.log(amount, receiverAddress, "receiverAddress1");
    if (amount && receiverAddress) {
      setLoading(true);
      const payload = {
        type: type || "",
        pin: pin || "",
        trackNFTId: trackNFTId || "",
        amount: amount || "",
        receiverAddress: receiverAddress,
      };

      dispatch(gasFeesCalculate(payload)).then((response: any) => {
        if (response.payload.success) {
          if (response.payload.data) {
            const { fiatPrice, fiatSymbol, cryptoPrice, cryptoSymbol } =
              response.payload.data;
            dispatch(
              setWalletDetails({
                receiverAddress: receiverAddress,
                amount: amount,
                fiatPrice: fiatPrice,
                fiatSymbol: fiatSymbol,
                cryptoPrice: cryptoPrice,
                cryptoSymbol: cryptoSymbol,
                type: "dropstock",
              })
            );
            setNetFeeDetails({
              fiatPrice1: fiatPrice,
              fiatSymbol1:fiatSymbol,
            })
            setIsAddressValid(false);
            setLoading(false);
            setAllErrors((prev) => ({ ...prev, amount: 3, amountMessage: "" }));
          }
        } else {
          setLoading(false);
          setIsAddressValid(true);
        }
      });
    }
  };

  const openModal = () => {
    setAllErrors({
      address: 1,
      submit: 1,
      addressMessage: "",
    });
    setAllInsertvValue({
      address: "",
    });
    setLoading(false);
  };

  const handleBlurIsValid = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value) {
      setAllErrors((prev) => ({ ...prev, address: 3 }));
      IsValid(value);
    } else {
      setAllErrors((prev) => ({
        ...prev,
        address: 2,
        addressMessage: "Enter address",
      }));
    }
  };

  return (
    <ModalWrapper
      modalId="WalletFeesDropStocksWithdrawModal"
      onBackdropClick={openModal}
    >
      <div
        className="modal fade"
        id="WalletFeesDropStocksWithdrawModal"
        aria-hidden="true"
        aria-labelledby="WalletFeesDropStocksWithdrawModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header">
              <Heading3 className="modal-title">DropStocks Withdraw</Heading3>
            </div>
            <div className="modal-body uni-mb-56 text-white">
              <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh uni-fw-700">
                Account withdraw of DropStocks
              </TextfieldLG>
              <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                Input the details below to perform the withdraw safely. Make
                sure your target wallet supports the specified network,
                otherwise you may not receive the assets.
              </TextfieldXSM>
              <div>
                <TextfieldLG className="gray4 uni-fw-700">
                  Account Address
                </TextfieldLG>
                <DropworksInput
                  placeholder="Address"
                  disabled={loading}
                  value={allInsertValue.address}
                  onChange={(e) => {
                    setIsAddressValid(true);
                    setAllInsertvValue((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }));
                    if (e.target.value.length > 0) {
                      setAllErrors((prev) => ({
                        ...prev,
                        address: 3,
                        addressMessage: "",
                      }));
                    } else {
                      setAllErrors((prev) => ({
                        ...prev,
                        address: 2,
                        addressMessage: "Enter address",
                      }));
                    }
                  }}
                  className={`uni-mt-16 uni-mb-32 form-control login-cstm-pass   ${
                    allErrors.address === 1
                      ? ""
                      : allErrors.address === 2
                      ? "is-invalid b-red-2 mb-0"
                      : "is-valid  b-green-2"
                  }`}
                  onBlur={handleBlurIsValid}
                />
                {allErrors.address === 2 && (
                  <p className="invalid-feedback error-red uni-mb-16">
                    {allErrors.addressMessage}{" "}
                  </p>
                )}
                <TextfieldLG className="gray4 uni-fw-700">Network</TextfieldLG>
                <DropworksInput
                  disabled={true}
                  value={"Polygon POS"}
                  className={`uni-mt-16 uni-mb-12  login-cstm-pass`}
                />
                <TextfieldXSM className="ds-ctm-txt gray4 b-line r-text m-0">
                  <a
                    target="_blank"
                    className="gray4"
                    rel="noopener noreferrer"
                    href={process.env.REACT_APP_POLYGON_URL}
                  >
                    Contract Address
                  </a>
                </TextfieldXSM>
                <HrLineMD className="uni-mb-32 uni-mt-32" />
                <TextfieldLG className="gray4 uni-mb-16 uni-fw-700">
                  Network Fee:
                </TextfieldLG>
                <Heading3 className="modal-title m-0">
                  {netFeeDetails.fiatPrice1} {netFeeDetails.fiatSymbol1}
                </Heading3>
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  <ButtonComponent
                    id="WalletDropstockWithdrawModal"
                    className="buttoncomponent-mobile-modle"
                  >
                    <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns">
                      Back
                    </DropworksSmallButton>
                  </ButtonComponent>{" "}
                  <ButtonComponent id="WalletNetworkConfirmation">
                    <DropworksSmallButton
                      disabled={isAddressValid || allErrors.address === 2}
                      className="btn-wid m-0 my-0 social-link-modal-btns"
                    >
                      Next
                    </DropworksSmallButton>
                  </ButtonComponent>
                </>
              )}
            </div>

            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
