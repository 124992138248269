import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react"
import { getTrackProjectBeatsDetailsById } from "../../shared/utils/apiServices"
import { useDispatch, useSelector } from "react-redux"
import { setTrackModalId } from "../../../redux/reducer/reportSlice"
import { setTrackBannerImg } from "../../../redux/reducer/uploadSlice";
import { getformatDate} from "../../shared/utils/localStorage"
import { clearIsLike, setIsLiked} from "../../../redux/reducer/beatSlice"
import { setMusicPageData } from "../../../redux/reducer/musicSlice"
import { RootState } from "../../../redux/store"

export const TrackPage = () => {
    const slugName = useParams()
    const trackSlugName = slugName.slugName;
    const [trackData, setTrackData] = useState<any>()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const {musicPageData,musicPlayer}= useSelector((state: RootState) => state.music);


    useEffect(() => {
        const fetchUserData = async () => {
            const result = await getTrackProjectBeatsDetailsById(trackSlugName,1);
               dispatch(clearIsLike());
            if (result.success) {
                let newData= result.featuredBeatsData?.data?.track?.collaborators.map((ele:any)=>{return(ele.collaborator_name)});
                let  trackDetailsReleaseDate=getformatDate(result.featuredBeatsData?.data?.track?.created_at);
                let trackDeatilsCreatorList=[result.featuredBeatsData?.data?.track?.creator_user_name];
                let trackDetailsUniqueId=result.featuredBeatsData?.data?.track?.unique_id;
                if(Boolean(newData.length))
                {
                    trackDeatilsCreatorList= [...trackDeatilsCreatorList,...newData];
                }
                dispatch(setIsLiked(result?.featuredBeatsData?.data?.track.favoriteData ? true : false));
                setTrackData({...result.featuredBeatsData?.data?.track,trackPopupdetail:{trackDetailsReleaseDate,trackDeatilsCreatorList,trackDetailsUniqueId}});
                dispatch(setTrackBannerImg(result.featuredBeatsData?.data?.track?.art_work_image));
                dispatch(setMusicPageData({
                      musicPageName:result.featuredBeatsData?.data?.track?.track_name,
                      musicPageAudioSrc:result.featuredBeatsData?.data?.track?.upload_file?.[0]?.src,
                      musicPageImgSrc:result.featuredBeatsData?.data?.track?.art_work_image?.[0]?.src,
                      showPageOptions:false,
                      musicPageLiked:Boolean(result.featuredBeatsData?.data?.track?.favoriteData?._id),
                      showPageMusicBar:false,
                      musicPageCreator:result.featuredBeatsData?.data?.track?.user_id?.firstname + " " + result.featuredBeatsData?.data?.track?.user_id?.lastname,
                      musicPageOwnedByMe:result.featuredBeatsData?.data?.track?.user_id?._id === user.id,
                      musicPageDescription:result.featuredBeatsData?.data?.track?.description,
                      musicPageId:result.featuredBeatsData?.data?.track?._id,
                      musicPagePath:`/track-page/${result.featuredBeatsData?.data?.track?.slug_name}`,
                      musicPageType:"track",
                    }))
     
            }
            else{
                navigate("/PageNotFound")
            }
        };

        fetchUserData();
    }, [trackSlugName])

    useEffect(()=>{
        if(musicPageData.musicPageId ===musicPlayer.musicId){
        let newData={...trackData,favoriteData:musicPlayer.musicLiked?{_id:"abc"}:"",audioLiked:musicPlayer.musicLiked};
        setTrackData(newData)
        }
    },[musicPlayer.musicId , musicPlayer.musicLiked])

    const navigateTo = (value: string) => () => {
        navigate(value)
    }

    return (view({ navigateTo, trackData }))
}