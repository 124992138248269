import { TextfieldLG, TextfieldSM } from '../../../../../common/custom-field/dropstock-text';
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button';
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import './styles.scss';
import { closeModal, CloseModalButton } from '../../logo-header/view';
import userIcon from '../../../../../assets/steps/UserIcon.svg';
import { useEffect, useState } from 'react';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { getBlockedUserList, updateUnBlockList } from '../../../utils/apiServices';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = (blockedUsers:string[],setBlockedUsers:any,openModal:any) => {

const [loading,setLoading]=useState<boolean>(false);
const [unBlockList,setUnBlockList] =useState<string[]>([])


  const handleToggleBlock = (id:number) => {
    setBlockedUsers((prevUsers:any) =>
      prevUsers.map((user:any) =>
        user.id === id ? { ...user, blocked: !user.blocked } : user
      )
    );
  };

  useEffect(()=>{
setUnBlockList(blockedUsers
  .map((ele: any) => (!ele.blocked ? ele.id : null))
  .filter((id: any) => id !== null) )
  },[blockedUsers])

const UnblockListUpdate = async()=>{
const response = await updateUnBlockList(unBlockList);
if(response.success)
{
  closeModal();
 }
else{
//console.log("error in unb;locklist apui")
}
}


  return (
    <ModalWrapper modalId="BlockedUser1" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="BlockedUser1"
      aria-hidden="true"
      aria-labelledby="BlockedUser1"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Blocked Users</Heading3>
          </div>
 
          <div className="modal-body uni-mb-56 pb-0 ps-0 pt-0 uni-pr-72">
            <TextfieldLG className="uni-mt-16 uni-mb-56 ctm-txt">Manage blocked users</TextfieldLG>
            <div className='blockeduser-mobile-view'>
            {blockedUsers.length ===0 && <TextfieldLG className="uni-mt-16 uni-my-56 ctm-txt text-center">Blocked users list is empty</TextfieldLG>}
            {blockedUsers?.map((user:any) => (
              <div key={user.id} className='d-flex blocked-user-mobile  col-6 col-lg-12 col-sm-6 uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
                <div className='d-flex align-items-center blocked-user-profile-mobile gap-3'>
                  {user.blocked ?(
                    <>
                      <img src={userIcon} alt={user.name} className='without-blocked' />
                    <TextfieldSM className='block-username '>{user.name || '#USERNAME'}  </TextfieldSM>
                    </>
                  ) :(
                    <>
                    <img src={userIcon} alt={user.name} className='block-img' />
                    <TextfieldSM className='block-username-block'>{user.name  || '#USERNAME'}</TextfieldSM>
                    </>
                  )}
                </div>
       
                {user.blocked ? (
                  <DropworksDisableButton className='unblock-button' onClick={() => handleToggleBlock(user.id)}>
                    Unblock
                  </DropworksDisableButton>
                ) : (
                  <DropworksDisableButton className='undo-button' onClick={() => handleToggleBlock(user.id)}>
                    Undo
                  </DropworksDisableButton>
                )}
              </div>
             
            ))}
            </div>
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            {/* <CloseModalButton> */}
              <DropworksSmallButton  disabled={unBlockList.length<1} className={unBlockList.length>0?"btn-wid m-0  social-link-modal-btns":"btn-wid m-0  social-link-modal-btns custom-inactive-btn text-secondary disable-interactions"} onClick={UnblockListUpdate}>Save</DropworksSmallButton>
            {/* </CloseModalButton> */}
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  );
}

export default view;
