import { useNavigate } from "react-router-dom";
import { view } from "./view";
import { useEffect, useState } from "react";
import {
  getFeaturedBeatsData,
  getFeaturedProjectData,
  getPopularThisWeek,
  getPopularTrack,
} from "../../shared/utils/apiServices";
import { PopularThisWeek, TrackData } from "./modal";
import { log } from "console";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { getformatDate, mixMasterAudio } from "../../shared/utils/localStorage";

const MarketplaceFeatured = () => {
  const navigate = useNavigate();
  const [featuredProject, setFeaturedProject] = useState<string[]>([]);
  const [featuredBeats, setFeaturedBeats] = useState<string[]>([]);
  const user =useSelector((state:RootState)=>state.user)
  const {musicPlayer} =useSelector((state:RootState)=>state.music)

  const [projectarr, setProjectarr] = useState<any>([
    { heading: "", subheading: "", image: "", slugName: "" },
  ]);

  const [beatsarr, setBeatsarr] = useState<any>([
    { heading: "", subheading: "", image: "", beatName: "", fullBeatData: "" },
  ]);

  const [popularThisWeek, setPopularThisWeek] = useState<
    PopularThisWeek[] | []
  >([]);
  const [popularTrack, setPopularTrack] = useState<TrackData[] | []>([]);


  useEffect(()=>{
    fetchBeatData();
    fetchTrackData();
    fetchPopularThisWeek();
    fetchProjectData();

  },[])
  const fetchProjectData = async () => {
    const result = await getFeaturedProjectData();
    if (result) {
      setFeaturedProject(result.featuredProjectData.data);
    }
  };
  const fetchBeatData = async () => {
    const result = await getFeaturedBeatsData();
    if (result) {
      setFeaturedBeats(result.featuredBeatsData.data);
    }
  };

  const fetchPopularThisWeek = async () => {
    const result = await getPopularThisWeek();
    if (result.success) {
      setPopularThisWeek(result.getPopularThisWeek);
    }
  };
  

  const fetchTrackData = async () => {
    const result = await getPopularTrack();
    if (result.success) {
      setPopularTrack(
        result.getPopularTrack.map((item: any) => ({
          createrName:item?.collaborators,
          audioUrl:mixMasterAudio(item.upload_file),
          audioLiked:Boolean(item?.favoriteData?._id),
          audioPath:`track-page/${item?.slug_name}`,
          audioName:item?.track_name,
          fullTrackData:[item],
          audioByMe:item?.user_id?._id === user.id,
          heading: item?.track_name,
          subheading: item?.description || "track description",
          id: item?._id,
          _id: item?._id,
                  trackDetailsReleaseDate: getformatDate(item?.created_at),
                  trackDeatilsCreatorList: [item?.creator_user_name],
                  trackDetailsUniqueId: item?.unique_id,
          image:
            item?.featured_image?.[0]?.src && item?.featured_image?.length > 0
              ? item?.featured_image?.[0]?.src
              : item?.art_work_image?.[0]?.src || item?.default_artwork,

          slugName: item?.slug_name,
        }))
      );
    }
  };
  

  useEffect(() => {
    if (Array.isArray(featuredProject)) {
      setProjectarr(
        featuredProject.map((item: any) => ({
          collaborators: item?.collaborators,
          heading: item?.project_name,
          subheading: item?.description,
          image:
            item?.featured_image?.[0]?.src && item?.featured_image?.length > 0
              ? item?.featured_image?.[0]?.src
              : item?.art_work_image?.[0]?.src || item?.default_artwork,
          slugName: item?.slug_name,
          id: item?._id,
        }))
      );
    }
  }, [featuredProject]);

  const changeData=()=>{
    if(musicPlayer.musicType ==="track" && Boolean(popularTrack.length) )
    {
      let newUpdatedData=popularTrack.map((ele:any)=>{
        if(ele?._id && ele?._id === musicPlayer.musicId)
          {
            let newData={favoriteData:musicPlayer.musicLiked?{_id:"abc"}:"",audioLiked:musicPlayer.musicLiked};
            return {...ele,...newData}
          }
          else{
            return ele ;
          }
        })
        setPopularTrack(newUpdatedData);
      }
      else if(musicPlayer.musicType ==="beat" && Boolean(beatsarr.length)) {
      let newUpdatedData=beatsarr.map((ele:any)=>{
        if(ele._id && ele?._id === musicPlayer.musicId)
        {
          let newdata= [{...ele.fullBeatData?.[0],favoriteData:musicPlayer.musicLiked?{_id:"abcd"}:""}];
          return {...ele,fullBeatData:newdata}
        }
        else{
          return ele ;
        }
      })
     
     setBeatsarr(newUpdatedData);  
   
    }
  }
 
  useEffect(()=>{
    changeData();
  },[musicPlayer.musicId , musicPlayer.musicLiked])

  useEffect(() => {
    if (Array.isArray(featuredBeats)) {
      setBeatsarr(
        featuredBeats.map((item: any) => ({
          userDetails: item?.user_id,
          heading: item?.beat_name,
          subheading: item?.description,
          // image: item?.featured_image?.length > 0 && null
          //   ? item?.featured_image?.[0]?.src
          //   : item?.art_work_image?.[0]?.src,
          image:
            item?.featured_image?.[0]?.src && item?.featured_image?.length > 0
              ? item?.featured_image?.[0]?.src
              : item?.art_work_image?.[0]?.src,
          beatName: item?.beat_slug_name,
          fullBeatData: [item],
          id: item?._id,
          _id: item?._id,

        }))
      );
    }
  }, [featuredBeats]);

  const navigateTo = (value: string) => () => {
    navigate(value);
  };

  return view({
    navigateTo,
    projectarr,
    beatsarr,
    popularThisWeek,
    popularTrack,
  });
};



export default MarketplaceFeatured;
