import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import { CloseModalButton } from "../../logo-header/view";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import { useState } from "react";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = ({
  DWPreferences,
  handleSelectChange,
  selectedItems,
  submitData,
  openModal,
}: any) => {
  return (
    <ModalWrapper modalId="DawsModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="DawsModal"
        aria-hidden="true"
        aria-labelledby="DawsModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content modal-content-boxes-modal ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header  ps-0 pt-0">
              <Heading3 className="modal-title">DAWs</Heading3>
            </div>

            <div className="modal-body uni-mb-56">
              <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt">
                Select the DAWs that you are familiar with{" "}
              </TextfieldLG>
              <TextfieldXSM className="ctm-daws-text">
                Choose up to 2
              </TextfieldXSM>
              <TextfieldXSM className="ctm-daws-text daws-content-mobile-space uni-mb-56">
                (Don’t select any if you do not want this do be displayed on
                your profile)
              </TextfieldXSM>
              <div className="investor-box-wrapper">
                <div className="d-flex uni-mb-24 flex-wrap investor-box-parent-daws">
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">FL Studio</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("FL Studio")}
                        onChange={(e) => handleSelectChange(e, "FL Studio")}
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("FL Studio")
                        }
                      />
                    </p>
                  </label>
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">Ableton Live</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Ableton Live")}
                        onChange={(e) => handleSelectChange(e, "Ableton Live")}
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Ableton Live")
                        }
                      />
                    </p>
                  </label>
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">Apple Logic Pro</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Apple Logic Pro")}
                        onChange={(e) =>
                          handleSelectChange(e, "Apple Logic Pro")
                        }
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Apple Logic Pro")
                        }
                      />
                    </p>
                  </label>
                  <label className="investor-boxs-daws cursor-pointer investor-mobile-ui">
                    <p className="investor-boxs-under">Avid Pro Tools</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Avid Pro Tools")}
                        onChange={(e) =>
                          handleSelectChange(e, "Avid Pro Tools")
                        }
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Avid Pro Tools")
                        }
                      />
                    </p>
                  </label>
               
                {/* <div className="d-flex uni-mb-24 investor-box-parent"> */}
                  {/* <label className="investor-boxs cursor-pointer">
                    <p className="investor-boxs-under">Avid Pro Tools</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Avid Pro Tools")}
                        onChange={(e) =>
                          handleSelectChange(e, "Avid Pro Tools")
                        }
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Avid Pro Tools")
                        }
                      />
                    </p>
                  </label> */}
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">Steinberg Cubase</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Steinberg Cubase")}
                        onChange={(e) =>
                          handleSelectChange(e, "Steinberg Cubase")
                        }
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Steinberg Cubase")
                        }
                      />
                    </p>
                  </label>
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">PreSonus Studio One</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("PreSonus Studio One")}
                        onChange={(e) =>
                          handleSelectChange(e, "PreSonus Studio One")
                        }
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("PreSonus Studio One")
                        }
                      />
                    </p>
                  </label>
                  {/* <label className="investor-boxs cursor-pointer investor-mobile-ui">
                    <p className="investor-boxs-under">Avid Pro Tools</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Avid Pro Tools")}
                        onChange={(e) =>
                          handleSelectChange(e, "Avid Pro Tools")
                        }
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Avid Pro Tools")
                        }
                      />
                    </p>
                  </label> */}
                  {/* <label className="investor-boxs cursor-pointer investor-mobile-ui">
                    <p className="investor-boxs-under">Steinberg Cubase</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Steinberg Cubase")}
                        onChange={(e) =>
                          handleSelectChange(e, "Steinberg Cubase")
                        }
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Steinberg Cubase")
                        }
                      />
                    </p>
                  </label> */}
                {/* </div> */}
                {/* <div className="d-flex uni-mb-24 investor-box-parent"> */}
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">Bitwig Studio</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Bitwig Studio")}
                        onChange={(e) => handleSelectChange(e, "Bitwig Studio")}
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Bitwig Studio")
                        }
                      />
                    </p>
                  </label>
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">Garage Band</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Garage Band")}
                        onChange={(e) => handleSelectChange(e, "Garage Band")}
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Garage Band")
                        }
                      />
                    </p>
                  </label>
                  <label className="investor-boxs-daws cursor-pointer">
                    <p className="investor-boxs-under">Others</p>
                    <p className="investor-boxs-radiobutton">
                      <DropworksRadioCheckbox
                        checked={selectedItems.includes("Others")}
                        onChange={(e) => handleSelectChange(e, "Others")}
                        disabled={
                          selectedItems.length === 2 &&
                          !selectedItems.includes("Others")
                        }
                      />
                    </p>
                  </label>
                {/* </div> */}
              </div>
            </div>
            </div>

            <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72 btn-mo-mr-8">
              {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}

              <CloseModalButton>
                <DropworksSmallButton
                  className="btn-wid modal-btns m-0"
                  onClick={() => submitData({ DW_preferences: selectedItems })}
                >
                  Save
                </DropworksSmallButton>
              </CloseModalButton>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
