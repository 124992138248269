import { FC, useEffect } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/steps/uploadicon.svg";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import { DropworksNormalButton } from "../../../common/custom-field/dropworks-button";
import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import "react-image-crop/dist/ReactCrop.css";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Loader from "../../shared/components/loader/view";
import { useNavigate } from "react-router-dom";

const view: FC<viewType> = (
  { navigateTo },
  {
    beatName,
    setBeatName,
    beatDescription,
    setBeatDescription,
    uploadedImage,
    handleAudioUpload,
    handleRemoveAudio,
    uploadedAudioFile,
    handleSubmit,
    audioImage,
    error,
    audioFileAPI,
    beatSlugName,
    beatId,
    setError,
    loading,
  }: any
) => {
  const upload = useSelector((state: RootState) => state.upload);
  const navigate=useNavigate()
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 1000) {
          navigate("/create");
        }
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  return (
    <div className="container">
      <Heading3 className="mx-0 my-0 heading-beat-empty">Add a Beat</Heading3>
      <TextfieldLG className="subHeading-beat-empty uni-mt-16 uni-mb-56">
        Fill out all information
      </TextfieldLG>
      <div className="row d-flex uni-mb-56 justify-content-between px-0 py-0">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0 beat-first-col">
          <TextfieldLG className="text-fields-beat uni-mb-16 mt-0">
            Beat Name
          </TextfieldLG>
          <DropworksInput
            placeholder="Name"
            className="uni-mb-32"
            onChange={(e: any) => setBeatName(e.target.value)}
            value={beatName}
          />
          <TextfieldLG className="text-fields-beat uni-mb-16 mt-0">
            Descriptions
          </TextfieldLG>
          <DropworksTextArea
            placeholder="Description (Optional)"
            className="uni-mb-32"
            rows={2}
            onChange={(e: any) => setBeatDescription(e.target.value)}
            value={beatDescription}
          />

          <TextfieldLG className="text-fields-beat mt-0">
            Audio File
          </TextfieldLG>
          <TextfieldXSM className="text-fields-xsm-beat uni-mb-16 uni-mt-16">
            Allowed formats:{" "}
            <span className="font-yellow">.MP3, .WAV, .M4A</span>
          </TextfieldXSM>

          <div className="audio-upload-section">
            {audioFileAPI && uploadedAudioFile ? (
              <div className="audio-file-info d-flex align-items-center justify-content-between">
                <TextfieldSM className="file-name mx-0 my-0">
                  {(() => {
                    const fileName = uploadedAudioFile?.name
                      ? uploadedAudioFile?.name
                      : uploadedAudioFile?.fileName;
                    const extension = fileName?.slice(
                      fileName.lastIndexOf(".")
                    );
                    const nameWithoutExtension = fileName?.slice(
                      0,
                      fileName.lastIndexOf(".")
                    );

                    return nameWithoutExtension?.length > 10
                      ? `${nameWithoutExtension?.slice(0, 10)}...${extension}`
                      : fileName;
                  })()}
                </TextfieldSM>

                <span
                  className="remove-file-icon ml-2"
                  onClick={handleRemoveAudio}
                >
                  X
                </span>
              </div>
            ) : loading ? (
              <Loader className="uni-mt-24" />
            ) : (
              <label htmlFor="audio-upload" className="upload-label">
                <div className="beatEmpty_uploadbox flex-column">
                  <img src={iconImg} className="audio-img" />
                  <label className="uni-pt-16 uni-pb-16 beat-text">
                    Upload
                  </label>
                </div>
              </label>
            )}
            <input
              id="audio-upload"
              type="file"
              accept=".mp3, .wav, .m4a"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const validTypes = [
                    "audio/mp3",
                    "audio/wav",
                    "audio/m4a",
                    "audio/mpeg",
                    "audio/x-m4a",
                  ];
                  if (validTypes.includes(file.type)) {
                    handleAudioUpload(e); // Trigger the audio upload handler
                  } else {
                    setError(
                      "Invalid file type. Upload an audio file (.mp3, .wav, .m4a ,mpeg)."
                    );
                    setTimeout(() => {
                      setError("");
                    }, 3000);
                  }
                }
                e.target.value = ""; // Reset the input's value
              }}
              className="d-none"
            />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
          <TextfieldLG>Artwork</TextfieldLG>
          <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-32 modal-second-head-font">
            Use a 1:1 aspect ratio with dimensions of 1500px or higher
          </TextfieldLG>

          <div className="beatEmpty_uploadbox2 flex-column mb-3">
            {audioImage ? (
              <ButtonComponent id="BeatArtWorkFirst" className="w-100 h-100">
                {" "}
                <img
                  src={
                    audioImage?.includes("http")
                      ? audioImage
                      : `${process.env.REACT_APP_IMGURL}/${audioImage}`
                  }
                  alt="Upload Icon"
                  className="upload-img-2 w-100 h-100"
                />{" "}
              </ButtonComponent>
            ) : (
              <>
                <ButtonComponent id="BeatArtWorkFirst">
                  <img
                    src={iconImg}
                    alt="Upload Icon"
                    className="upload-img-2"
                  />
                </ButtonComponent>

                <label className="mt-4 beat-text">Beat Artwork</label>
              </>
            )}
          </div>
        </div>
      </div>
      {error ? (
        <>
          <div className="invalid-feedback w-476  ml-auto">{error}</div>
        </>
      ) : (
        ""
      )}
      <div className="d-flex justify-content-end release-button">
        {uploadedAudioFile && beatName?.length > 0 && audioImage ? (
          <DropworksNormalButton
            className={`btn-beat-empty ${
              uploadedAudioFile && beatName?.length > 0 && audioImage
                ? "beat-empty-button-done"
                : "beat-empty-button"
            }`}
            onClick={handleSubmit}
          >
            Release
          </DropworksNormalButton>
        ) : (
          <DropworksNormalButton
            disabled
            className={`btn-beat-empty ${
              uploadedAudioFile && beatName?.length > 0 && audioImage
                ? "beat-empty-button-done"
                : "beat-empty-button"
            }`}
            onClick={handleSubmit}
          >
            Release
          </DropworksNormalButton>
        )}
      </div>
    </div>
  );
};

export default view;
