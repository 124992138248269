import React from "react";
import ChatComponent from "../../shared/components/documentdocxfile";
import MessageChatAudio from "../../shared/components/message-audiofile";
import VideoMessage from "./videomessage";
import "./styles.scss";

interface ChatMessageProps {
  chattingTab: string;
  item: any;
  handleDownload: (e: any, item: any) => void;
  deleteChat: (index: number) => void;
  handleDropdownToggle: (index: number) => void;
  activeDropdown: number | null;
  index: number;
  truncateStringWithExtension: (str: string) => string;
  getFileTypeImage: (fileName: string, fileSrc: string) => string;
  loaderSvg: string;
  ErrorImg: string;
}

const MessageRecieved: React.FC<ChatMessageProps> = ({
  chattingTab,
  item,
  handleDownload,
  deleteChat,
  handleDropdownToggle,
  activeDropdown,
  index,
  truncateStringWithExtension,
  getFileTypeImage,
  loaderSvg,
  ErrorImg,
}) => {
  const renderFileContent = () => {
    const fileName = item.is_attached_file?.[0]?.fileName || "Unknown File";
    const fileSrc = item.is_attached_file?.[0]?.src || "";

    if (
      fileName.endsWith(".pdf") ||
      fileName.endsWith(".txt") ||
      fileName.endsWith(".xls") ||
      fileName.endsWith(".xlsx") ||
      fileName.endsWith(".docx")
    ) {
      return (
        <ChatComponent
          is_recieved={true}
          fileName={fileName}
          fileType={
            fileName.endsWith(".pdf")
              ? "PDF"
              : fileName.endsWith(".txt")
              ? "TXT"
              : fileName.endsWith(".docx")
              ? "DOCX"
              : "EXCEL"
          }
          sender_name={item.sender_name}
          fileSize={
            item.is_attached_file?.[0]?.fileSize
              ? `${(item.is_attached_file?.[0]?.fileSize / 1024).toFixed(2)} KB`
              : "Unknown Size"
          }
          src={
            item.is_attached_file?.[0]?.src
              ? item.is_attached_file?.[0]?.src
              : ""
          }
          messageTime={item.time || "00:00"}
        />
      );
    } else if (fileName.endsWith(".mp3") || fileName.endsWith(".mpeg")) {
      return (
        <MessageChatAudio
          src={fileSrc || "/new.mp3"}
          is_recieved={true}
          messageTime={item.time || "00:00"}
        />
      );
    } else if (fileName.endsWith(".mp4")) {
      return <VideoMessage videoUrl={fileSrc} />;
    } else {
      return (
        <a onClick={(e) => handleDownload(e, item)}>
          <img
            src={getFileTypeImage(fileName, fileSrc)}
            alt="File Preview"
            className="message-image"
            onError={(e) => (e.currentTarget.src = loaderSvg)}
          />
        </a>
      );
    }
  };

  return (
    <div className="newmessage-receiver">
      {chattingTab === "Artist" && (
        <img
          src={`${process.env.REACT_APP_IMGURL}/${item.imgUrl}` || ""}
          className={
            chattingTab === "Artist"
              ? "img-profilepic uni-mr-8"
              : "img-profilepic-project"
          }
          onError={(e) => (e.currentTarget.src = ErrorImg)}
          alt="image"
        />
      )}
      <p className="newmessage-receiver-chat-triangle"></p>

      {item.is_attached ? (
        <div className="newmessage-receiver-message d-block">
          {/* <ArrowDropdown
         pagename="call-funtion"
          className2="parent-arrow2"
         arr={[
         "Delete"
          ]}
         /> */}
          <div>{renderFileContent()}</div>
          <div className="newmessage-receiver-message-receiver-message  custom-received-msg uni-mt-8 w-100">
            <div>
              {truncateStringWithExtension(
                item.is_attached_file?.[0]?.fileName
              )}
            </div>
            <div className="d-flex justify-content-end ">
              <span className="uni-fw-400 uni-ml-8 newmessage-sender-message-time d-flex">
                {/* {!item.is_delete && (
                  <ArrowDropdown
                    pagename="call-funtion"
                    className="unil-px-8 py-0 mobile-p-0"
                    arr={["Delete"]}
                    onClickOptions={(selected: any) => deleteChat(index)}
                    isOpen={activeDropdown === index}
                    onToggle={() => handleDropdownToggle(index)}
                  />
                )} */}
                <span className="uni-pr-8">{item.time}</span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="newmessage-receiver-message">
          {/* <ArrowDropdown */}
          {/* pagename="call-funtion" */}
          {/* //  className="px-0 mobile-p-0 arrowdropdown-message-file" */}
          {/* className2="parent-arrow2" */}
          {/* arr={[ */}
          {/* "Delete" */}
          {/* ]} */}
          {/* /> */}
          <span className="newmessage-receiver-message-received-message">
            <div className="sender-name">{item.sender_name}</div>
            <span>{item.message}</span>
          </span>
          <span className="fs-gray3 uni-fw-400 newmessage-receiver-message-time text-end">
            {item.time}
          </span>
        </div>
      )}
    </div>
  );
};

export default MessageRecieved;
