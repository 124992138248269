import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton, openPopup } from '../../logo-header/view';
import './styles.scss';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { useState } from 'react';
import { updateEmail } from '../../../utils/apiServices';
import Loader from '../../loader/view';
import { useDispatch } from 'react-redux';

const view = () => {
  const [selectedValue,setSelectedValue]=useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [message,setMessage]=useState<string>("");
  const dispatch =useDispatch();
  const OpenPopup3 = async () => {
    setLoading(true);
    const response: any = await updateEmail({type:selectedValue});
    if (response.success) {
      setLoading(false);
 openPopup('EmailModal1',dispatch)
    } else {
      setLoading(false);
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

  return (
    <div
    className="modal fade"
    id="EmailModal"
    aria-hidden="true"
    aria-labelledby="EmailModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
<div className="modal-dialog modal-dialog-centered uni-sm-popup">
      <div className="modal-content custom-mobile-email-width">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header  ps-0 pt-0 uni-pr-72"> 
          <Heading3 className="modal-title">Change Email Address</Heading3>
        </div>
        
        <div className="modal-body">
          <TextfieldLG className=" uni-mt-16 uni-mb-56 ctm-txt modal-second-head-font">Choose identity confirmation method</TextfieldLG>
          <div className='d-flex investor-box-parent-deposit d-flex justify-content-between uni-mt-8 '>
               <div className='investor-boxs-deposit cursor-pointer' onClick={()=>setSelectedValue("email")}>
                    <p className='investor-boxs-under-modal2'>Email</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={selectedValue==="email"}/></p>
               </div>
               <div className='investor-boxs-deposit cursor-pointer' onClick={()=>setSelectedValue("sms")}>
                    <p className='investor-boxs-under-modal2'>SMS</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox  checked={selectedValue==="sms"}/></p>
               </div>
          </div>
        </div>  
       {message && <p className='text-danger'>{message}</p>}
        <div className="modal-footer social-mobile-modal-footer uni-mt-40 ps-0 pt-0 pb-0 uni-pr-72">
        {loading?<Loader />:<DropworksSmallButton  disabled={!selectedValue} className={selectedValue?"btn-wid  modal-btns m-0":"btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"} onClick={OpenPopup3}>Next</DropworksSmallButton>}
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

