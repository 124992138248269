
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import verfiedIcon from "../../../../../assets/steps/verified-badge.svg";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

const view = (doBlockUser: any, userData: any, blockUser: any) => {
  const BlockedUserId = useSelector((state: RootState) => state.messageChat);
  const imageUrl = BlockedUserId.Data.imgUrl;

  const path = window.location.pathname;
  return (
    <div
      className="modal fade"
      id="BlockedModal"
      aria-hidden="true"
      aria-labelledby="BlockedModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img
                src={crossIcon}
                className="fs-1 uni-mr-16 modal-crossicon-mobile"
              />
            </CloseModalButton>
          </div>
          <div className="modal-header uni-mb-40 p-0">
            <Heading3 className="modal-title">Block </Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <div>
                {path.includes("user-profile") ? (
                  <img
                    src={`${process.env.REACT_APP_IMGURL}/${userData?.profile_image?.[0]?.src}`}
                    className="block-profile-box"
                    alt=""
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_IMGURL}/${imageUrl}`}
                    className="block-profile-box"
                    alt=""
                  />
                )}
              </div>
              {path.includes("user-profile") ? (
                <div>
                  <h2 className="fw-700 displayname">
                    {userData?.firstname} {userData?.lastname}
                    <img src={verfiedIcon} alt="" className="uni-ml-8" />{" "}
                  </h2>
                  <p className="userName-text">@{userData?.username}</p>
                </div>
              ) : (
                <div>
                  <h2 className="fw-700 displayname">
                    {BlockedUserId.Data.name}
                    <img src={verfiedIcon} alt="" className="uni-ml-8" />{" "}
                  </h2>
                  <p className="userName-text">@USERNAMd</p>
                </div>
              )}
            </div>
            <ul>
              <li className="list-item uni-mb-24">
                <span className="list-text ">
                  {path.includes("user-profile")
                    ? `${userData?.firstname}${" "}${userData?.lastname}`
                    : `${BlockedUserId?.Data?.name}`}{" "}
                  won’t be able to message you or find your profile and content
                  on Dropworks
                </span>
              </li>
              <li className="list-item uni-mb-24">
                <span className="list-text">
                  {path.includes("user-profile")
                    ? `${userData?.firstname}${" "}${userData?.lastname}`
                    : `${BlockedUserId?.Data?.name}`}{" "}
                  won’t be notified that you blocked them
                </span>
              </li>
              <li className="list-item uni-mb-24">
                <span className="list-text">
                  You can unblock{" "}
                  {path.includes("user-profile")
                    ? `${userData?.firstname}${" "}${userData?.lastname}`
                    : `${BlockedUserId?.Data?.name}`}{" "}
                  at any time in settings
                </span>
              </li>
            </ul>
          </div>
          <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0">
            <CloseModalButton  className="mbl-anchor-full-btn">
              <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 mbl-full-width-btn uni-mr-16">
                Cancel
              </DropworksSmallButton>
            </CloseModalButton>
            {path.includes("user-profile") ? (
              <ButtonComponent
                id="SuccessfullyBlocked"
                className="mbl-anchor-full-btn"
              >
                <DropworksSmallButton
                  onClick={blockUser}
                  className="btn-wid mbl-full-width-btn m-0"
                >
                  Block
                </DropworksSmallButton>
              </ButtonComponent>
            ) : (
              <ButtonComponent
                id="SuccessfullyBlocked"
               className="mbl-anchor-full-btn"
              >
                <DropworksSmallButton
                  onClick={doBlockUser}
                  className="btn-wid mbl-full-width-btn m-0"
                >
                  Block
                </DropworksSmallButton>
              </ButtonComponent>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
