import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { closeModal, CloseModalButton, openPopup } from '../../logo-header/view';
import './styles.scss';
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { deleteBanner, getUserData, updateUser } from '../../../utils/apiServices';
import { setUserData } from '../../../../../redux/reducer/userSlice';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';
import Loader from '../../loader/view';

const view = () => {
  const {socialModal} =useSelector((state:RootState)=>state.modal);
  const {social_links}=useSelector((state:RootState)=>state.user);
  const trimmedString = socialModal.replace(/\s+/g, '').toLocaleLowerCase();
  const [socialLink,setSocialLink]= useState<string>("") ;
  const [message,setMessage] =useState("");
  const[saveClick,setSaveClick]=useState(false);
  const[isSave,setIssave]=useState(false)
  const[loading,setLoading]=useState(false)

  const validateCustomUrl = (check:string) => {
   
    const urlRegex = new RegExp(`^https:\/\/(www\\.|open\\.)?(${trimmedString})\\.com\/[a-zA-Z0-9/._~!$&'()*+,;=:-]+$`);
   urlRegex.test(check) ? setMessage("The URL is valid.") : setMessage("The URL is not valid.");
  };

  useEffect(()=>{
    // setSocialLink(`https://www.${trimmedString==="spotify"?"open.spotify":trimmedString}.com/user/abc`);
    setMessage("");
    let socialLink=social_links.filter((ele:any)=>ele.name===socialModal || ele.name===socialModal.toLocaleLowerCase() );
    if(socialLink?.[0])
    {
      setSocialLink(socialLink?.[0].link)
      validateCustomUrl(socialLink?.[0].link)
      setIssave(true)
    }
  },[trimmedString])


  const handleChange = (event:any) => {
    const trimmedString = event.target.value.trim();
    setSocialLink(trimmedString); // Update the state with trimmed value
    validateCustomUrl(trimmedString); // Validate the trimmed string
  };



  
const dispatch =useDispatch();

  const saveLink =async()=>{
    
if(message==="The URL is valid." ){
  setLoading(true)
const response =await updateUser({name:socialModal,link:socialLink});
if (response) {
  openPopup("SocialLinksMain", dispatch)
    const result = await getUserData(); // Call your API function
    if (result.success && result.userData) { // Adjust based on your API response
        dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
        let socialLink=result.userData.data.userdata.social_links.filter((ele:any)=>ele.name===socialModal || ele.name===socialModal.toLocaleLowerCase() );
        if(socialLink?.[0])
          {
            setSocialLink(socialLink?.[0].link)
          }
          setLoading(false)
    }
    setLoading(false)
  }
}else{
  setMessage("The URL is not valid.")
  setSaveClick(true)
}

  }

const onDeleleteBanner= async()=>{
  setLoading(true)
  let socialLink=social_links.filter((ele:any)=>ele.name===socialModal || ele.name===socialModal.toLocaleLowerCase() );
    
    const response=await deleteBanner( `social_link_id=${socialLink?.[0]?._id}`)
    
    if(response.success){
      const result = await getUserData(); 
      if (result.success && result.userData) {
        openPopup("SocialLinksMain", dispatch)
        dispatch(setUserData(result.userData.data.userdata)); 
        setLoading(false)
      }
      setLoading(false)
    }else{
      setLoading(false)
    }
  }



  const openModal=()=>{
    let socialLink=social_links.filter((ele:any)=>ele.name===socialModal || ele.name===socialModal.toLocaleLowerCase() );
    if(socialLink?.[0])
      {
        setSocialLink(socialLink?.[0].link)
      }
      else{
        // setSocialLink(`https://www.${trimmedString==="spotify"?"open.spotify":trimmedString}.com/user/acb`)
      }
      setMessage("")
}
  
  return (
    <ModalWrapper modalId="SocialLink8" onBackdropClick={openModal}>
    <div
    className="modal fade"
    id="SocialLink8" 
    aria-hidden="true"
    aria-labelledby="SocialLink8"
    tabIndex={-1}
    data-bs-theme="dark"
    >
    <div className="modal-dialog modal-dialog-centered uni-md-popup">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Social Links</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72  ">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt social-link-mobile-txt modal-second-head-font mb-16-mbl ">Input your {socialModal} public link</TextfieldLG>
          <DropworksInput className={`mb-0 dropinput chg-plchold-col form-control ${message=="The URL is not valid."&& saveClick?"is-invalid b-red-2":saveClick?"is-valid b-green-2":""}` } value={socialLink} onChange={handleChange} placeholder={`https://${trimmedString}.com/`} />
          {message==="The URL is not valid." && saveClick &&  <p className='text-danger'>{message}</p>}
          {isSave? <TextfieldXSM className='text-end cursor-pointer delete-spotify' onClick={()=>onDeleleteBanner()}>Delete Link</TextfieldXSM>:""}
         
        </div>  
        
        <div className="modal-footer ps-0 pt-0 pb-0  social-mobile-modal-footer uni-mt-56  uni-pr-72">
        {loading ? (
            <Loader className="uni-mt-16" />
          ) :<>
        <ButtonComponent  id="SocialLinksMain" className='buttoncomponent-mobile-modle'>
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 social-link-modal-btns my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> 
        </ButtonComponent>
        {/* <ButtonComponent id="SocialLinksMain" className='buttoncomponent-mobile-modle'>
          
        <DropworksSmallButton  className="btn-wid m-0 social-link-modal-btns">Save</DropworksSmallButton>
        </ButtonComponent> */}

        <DropworksSmallButton   className="btn-wid  modal-btns m-0"  onClick={saveLink}>Save</DropworksSmallButton>
        </>}

        </div>
      </div>
    </div>
  </div>
  </ModalWrapper>
  )
}

export default view;


