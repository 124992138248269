import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import { CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import Countries from "../../../../../i18n/country";
const view = ({
  selectedCountry,
  handleSelectChange,
  submitData,
  openModal,
}: any) => {
  const options = Countries.map((ele:any)=>{return{value:ele.name,label:ele.name}})
 
  return (
    <ModalWrapper modalId="RegionModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="RegionModal"
        aria-hidden="true"
        aria-labelledby="RegionModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0">
              <Heading3 className="modal-title">Country / Region</Heading3>
            </div>

            <div className="modal-body uni-mb-56">
              <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh">
              Select
              </TextfieldLG>
              <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
              For your better experience
              </TextfieldXSM>
              {/* <DropworksSelect className="uni-mb-56" /> */}
              {/* <DropworksSelect className="uni-mb-56">
              <option value="">Region</option>
              </DropworksSelect> */}
              <DropworksDisableButton className="custom-select-dropdown">
                <SelectArrowDropdown
                  options={options}
                  placeHolderValue="Select"
                  selectedValue={selectedCountry}
                  onChangeValue={(value) => handleSelectChange(value)}
                  isSearch={true}
                ></SelectArrowDropdown>
              </DropworksDisableButton>
            </div>
            <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0">
              <CloseModalButton>
                <DropworksSmallButton
                  className="btn-wid  modal-btns m-0"
                  onClick={() => submitData({ country: selectedCountry })}
                >
                  Save
                </DropworksSmallButton>
              </CloseModalButton>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
