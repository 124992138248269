import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { deleteMessageForUser, getUnreadMessageList, getUserGroupList, groupChatHistroy, muteMessages, oneToOneChatHistroy } from "../../shared/utils/apiServices";
import { getAllUserStatus } from "../../../redux/reducer/messageChatSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";

export const MessageChat = () => {
  const [allUserData, setAllUserData] = useState<string[]>([]);
  const [allGroupData, setAllGroupData] = useState<string[]>([]);
  const [allUnread, setAllUnread] = useState<string[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<any>('');
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const [chattingTab, setChattingTab] = useState('');
  const [chattingStatus, setChattingStatus] = useState(false);
  const [name, setName] = useState()
  const [imgUrl, setImgUrl] = useState()
  const [muteStatus, setMuteStatus] = useState(false);
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [groupsChatHistory, setGroupsChatHistory] = useState([]);
  const [messageId, setMessageId] = useState([]);
  const [loading, setLoading] = useState({
    allUsers: true,
    unreadUsers: true,
    groupUsers: true
  })

  const navigate = useNavigate();
  const slugName = useParams()
  const UserId = slugName.slugName
  const dispatch = useDispatch<AppDispatch>();
  const [parentTabValue, setParentTabValue] = useState(0);



  const fetchGroupUser = async () => {
    // setLoading((prev) => ({ ...prev, groupUsers: true }));
    try {
      const result = await getUserGroupList();
      if (result) {
        setAllGroupData(result.groupsStatus.data);
        setLoading((prev) => ({ ...prev, groupUsers: false }));
      }
    } catch (error) {
      console.error("Error fetching groupUser data:", error);
      setLoading((prev) => ({ ...prev, groupUsers: false }));
    }
  };
  const fetchUnreadMessage = async () => {
    // setLoading((prev) => ({ ...prev, groupUsers: true }));

    try {
      const result = await getUnreadMessageList();
      if (result) {
        setAllUnread(result.unreadMessage.data);
        setLoading((prev) => ({ ...prev, unreadUsers: false }));
      }
    }
    catch (error) {
      setLoading((prev) => ({ ...prev, unreadUsers: false }));
      console.error('Error fetching unread message', error);
    }
  }
  useEffect(() => {
    // setLoading((prev) => ({ ...prev, allUsers: true }));

    dispatch(getAllUserStatus()).then((res) => setLoading((prev) => ({ ...prev, allUsers: false }))).catch((err) => { setLoading((prev) => ({ ...prev, allUsers: false })); })
    // fetchAllUsers(); 
    fetchGroupUser();
    fetchUnreadMessage();

  }, []);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const result = await oneToOneChatHistroy(selectedUserId);
        if (result.success) {
          setChatHistory(result.onChatHistroy.data.map((messageData: any, ind: number) => ({
            actionType: messageData.actionType === 0 ? 1 : 0,
            message: messageData.message,
            is_attached: messageData.is_attached,
            is_attached_file: messageData.is_attached_file,
            time: new Date(messageData.chat_datetime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: selectedUserId,
            imgUrl: messageData?.profile_image?.[0]?.src,
            delivered: messageData.delivered,
            read_seen: messageData.read_seen,
            is_delete: false
          })));
          setMessageId(result.onChatHistroy.data.map((messageData: any, ind: number) => ({
            id: messageData._id,
          })))

        }




      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (selectedUserId) {
      fetchChatHistory();
    }
  }, [selectedUserId])

  
  useEffect(() => {
    const fetchGroupChatHistory = async () => {
      try {
        setGroupsChatHistory([]);
        const result = await groupChatHistroy(selectedGroupId);

        if (result) {

          const newData = result?.groupsChatHistroy?.data?.map((ele: any) => {
            if (ele.actionType === 0 || ele.actionType === 1) {
              return ele;
            }
            else {
              return { ...ele, actionType: 3 }
            }
          }
          )
          setGroupsChatHistory(newData.map(
            (messageData: any) => ({
              // actionType: messageData.actionType === 0 ? 1 : 0,
              actionType: messageData.actionType === 0 ? 1 : messageData.actionType === 1 ? 0 : 3,
              message: messageData.message,
              time: new Date(messageData.chat_datetime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              id: selectedGroupId,
              imgUrl: messageData?.profile_image?.[0]?.src,
              is_attached: messageData.is_attached,
              is_attached_file: messageData.is_attached_file,
              read_seen: messageData.read_seen,
              sender_name: messageData.sender_name,
              delivered: messageData.delivered,
              is_delete: false
            })
          ));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchGroupChatHistory();
  }, [selectedGroupId])




  const fetchMutedData = async () => {

    try {
      const muteData = chattingTab === "user"
        ? { mute_user_id: selectedUserId }
        : { mute_group_id: selectedGroupId };

      const result = await muteMessages(muteData);

      if (result.success) {
        console.log("Successfully muted:", result.mutedMessage);
      } else {
        console.error("Mute failed:", result.message);
      }
    } catch (error) {
      console.error("Error fetching muted data:", error);
    }
  };

  const deleteuserchat = async (index: number) => {

    if (messageId && index < messageId?.length) {
      let { id } = messageId ? messageId[index] : { id: false };

      try {
        const result = await deleteMessageForUser(id);
        if (result) {
          setAllGroupData(result.deleteUserMessage.data);
        }
      } catch (error) {
        console.error("Error fetching groupUser data:", error);
      }
    }
  };


  const navigateTo = (value: string) => () => {
    navigate(value)
  }

  return (view({ navigateTo, setParentTabValue, chatHistory, setAllUnread, setAllUserData, fetchMutedData, setGroupsChatHistory, groupsChatHistory, setChatHistory, chattingTab, name, muteStatus, setMuteStatus, setName, imgUrl, setImgUrl, setChattingTab, allUserData, allGroupData, chattingStatus, setChattingStatus, selectedUserId, setSelectedUserId, setSelectedGroupId, selectedGroupId, allUnread, deleteuserchat, fetchUnreadMessage, loading }))
}