import { FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import Tabs from "../../../common/tabs";
import { DropworksSearchInput } from "../../../common/custom-field/dropworks-search-input";
import OffNotification from "../../../assets/steps/muted icon.svg";
import { DropworksMessageInput } from "../../../common/custom-field/dropworks-input-messagebox";
import { ArrowDropdown } from "../../../common/icons";
import TabsComponent from "../../../common/tabs-component/view";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";
import { Link } from "react-router-dom";
import { BackArrowIcon } from "../../../common/all-icons";
import loaderSvg from "../../../assets/steps/tube-spinner.svg";
import {
  messageSeen,
  onMessageReceived,
  sendMessage,
} from "../../shared/utils/socketServices";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedBlockedId,
  setShowRightSide,
  setuserListData,
} from "../../../redux/reducer/messageChatSlice";
import {
  currentTime,
  ErrorImg,
  getformatDate,
  getformatTime,
  getLocalStorageObject,
  todaysDate,
} from "../../shared/utils/localStorage";
import { openPopup } from "../../shared/components/logo-header/view";
import { RootState } from "../../../redux/store";
import { TextfieldMD } from "../../../common/custom-field/dropstock-text";
import { muteMessages } from "../../shared/utils/apiServices";
import MessageRecieved from "./messageRecieved";
import NewMessageSender from "./messageSend";
import Loader from "../../shared/components/loader/view";
import newchat from "../../../assets/steps/new chat.svg";
import { MdAdd } from "react-icons/md";
import { setbeatPage } from "../../../redux/reducer/reportSlice";
interface MessageData {
  chat_type: number;
  message_to?: any;
  message: string;
  group_id?: string;
}
interface SelectedUserType {
  imgUrl: any;
  name: any;
  id: any;
}
interface ReceivedMessage {
  message: string;
  from: string;
  to: string;
  group_id: string;
  chat_type?: number;
  actionType?: number;
  profile_image?: any;
  senderName: any;
  is_attached: boolean;
  is_attached_file: any;
}
interface ChatObject {
  imgUrl: string;
  name: string;
  seen: boolean;
  date: string;
  time: string;
  type: string;
  id: string;
  text: string;
  allowNotification: boolean;
  chatStatus: string;
  lastChatTime: string;
}
declare global {
  interface Window {
    showDirectoryPicker: () => Promise<FileSystemDirectoryHandle>;
  }
  interface FileSystemDirectoryHandle {
    entries(): AsyncIterableIterator<[string, FileSystemHandle]>;
  }
}
export const view: FC<any> = ({
  allUserData,
  allGroupData,
  userchatHistroy,
  selectedUserId,
  setSelectedUserId,
  selectedGroupId,
  setSelectedGroupId,
  allUnread,
  groupchatHistroy,
  setChattingTab,
  chattingTab,
  name,
  imgUrl,
  setName,
  setImgUrl,
  setChatHistory,
  chatHistory,
  setGroupsChatHistory,
  groupsChatHistory,
  setAllUnread,
  fetchMutedData,
  setAllUserData,
  deleteuserchat,
  fetchUnreadMessage,
  setParentTabValue,
  loading,
}) => {
  let token = localStorage.getItem("dropworksToken");
  const [message, setMessage] = useState<string>("");
  const [isOnetoOne, setIsOnetoOne] = useState(true);
  const chatHistoryRef = useRef<HTMLDivElement | null>(null);
  const [option, setOption] = useState<string>("Unmute");
  const [allChat, setAllChat] = useState<any>([]);
  const selfUserId = useSelector((state: RootState) => state.user.id);
  const { usersMessageList ,username,tabValue:reduxTabValue} = useSelector(
    (state: RootState) => state.messageChat
  );
  const {
    alluserListId,
    userImg,
    userName,
    chattingTab_redux,
    tabValue: tabValue_redux,
    showRightSide,
  } = useSelector((state: RootState) => state.messageChat);

  useEffect(() => {
    if (
      alluserListId &&
      userImg &&
      userName &&
      chattingTab_redux &&
      chattingTab_redux === "user"
        ? alluserListId !== selectedUserId
        : alluserListId !== selectedGroupId
    ) {
   
      setChattingTab(chattingTab_redux);
      if (chattingTab_redux === "user") {
        setSelectedUserId(alluserListId);
        setIsOnetoOne(true);
      } else {
        setIsOnetoOne(false);
        setSelectedGroupId(alluserListId);
      }
      setImgUrl(userImg);
      setName(userName);
      checkTabState(tabValue_redux);
      const selectedUserArray = {
        imgUrl: userImg,
        name: userName,
        id: alluserListId,
      };
      dispatch(selectedBlockedId(selectedUserArray as SelectedUserType));
    }
  }, [alluserListId]);

  useEffect(() => {
    let datas = getLocalStorageObject("chat-tab");
    if (
      datas &&
      !alluserListId &&
      !userImg &&
      !userName &&
      !chattingTab_redux &&
      !chattingTab_redux
    ) {
      dispatch(setuserListData(datas));
    }
  }, []);

  useEffect(() => {
    setAllUserData(usersMessageList);

    if (chattingTab === "user" && selectedUser) {
      if (
        usersMessageList.filter((ele) => ele?._id == selectedUserId).length == 0
      ) {
        setChatHistory([]);
        setChattingTab("");
        setSelectedUserId("");
        setActiveIndex1(-1);
        setSelectedUser("");
      }
    }
    fetchUnreadMessage();
  }, [usersMessageList]);
  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTo({
          top: chatHistoryRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 500);
  };
  const dispatch = useDispatch();

  const toggleAllowNotification = (
    array: ChatObject[],
    id: string
  ): ChatObject[] => {
    return array.map((item) => {
      if (item.id === id) {
        return { ...item, allowNotification: !item.allowNotification };
      }
      return item;
    });
  };

  const onOptionChangeGroup = async (selectedOption: string, id?: any) => {
    const newChat = toggleAllowNotification(groupChat, id || selectedGroupId);
    setGroupChat(newChat);
    setOption(selectedOption);
    let muteData = { mute_group_id: selectedGroupId };
    try {
      const result = await muteMessages(muteData);

      if (result.success) {
        console.log("Successfully muted:", result.mutedMessage);
      } else {
        console.error("Mute failed:", result.message);
      }
    } catch (error) {
      console.error("Error fetching muted data:", error);
    }
  };

  const deleteChat = (index: number) => {
    if (chattingTab === "user") {
      let alldata = [...chatHistory];
      alldata[index] = { ...alldata[index], is_delete: true };
      setChatHistory(alldata);
      deleteuserchat(index);
    } else {
      let alldata = [...groupsChatHistory];
      alldata[index] = { ...alldata[index], is_delete: true };
      setGroupsChatHistory(alldata);
    }
  };
  const onOptionChange = (selectedOption: string, userData?: any) => {
    userData && dispatch(selectedBlockedId(userData));
    if (selectedOption === "Delete") {
      openPopup("DeleteChat", dispatch);
    }
    if (selectedOption === "Block") {
      openPopup("BlockedModal", dispatch);
    }
    if (selectedOption === "Unmute") {
      const newChat = toggleAllowNotification(
        allChat,
        userData?.id || selectedUserId
      );
      setAllChat(newChat);
      fetchMutedData();
      setOption("Mute");
    }
    if (selectedOption === "Report") {
      openPopup("ReportRecentChatModal", dispatch);
      dispatch(setbeatPage(true));
    }
    if (selectedOption === "Mute" || selectedOption === "mute") {
      const newChat = toggleAllowNotification(
        allChat,
        userData?.id || selectedUserId
      );
      setAllChat(newChat);
      fetchMutedData();
      setOption("Unmute");
    }
  };

  // Function definition
  const updateAllChats = (
    msg: any,
    checkArray: any[],
    seen: boolean = false
  ) => {
    const index = checkArray.findIndex(
      (ele: any) => ele.id === (msg.chat_type === 1 ? msg.to : msg.from)
    );
    if (index !== -1) {
      setActiveIndex1(0);
      const updatedElement = {
        ...checkArray[index],
        seen: seen,
        message: msg.message,
        text: msg.message,
        date: todaysDate(),
        lastChatTime: currentTime(),
        is_attached: msg.is_attached || 0,
        is_attached_file: msg.is_attached_file || "",
      };
      return [
        updatedElement,
        ...checkArray.slice(0, index), // Elements before the found element
        ...checkArray.slice(index + 1), // Elements after the found element
      ];
    } else {
      setActiveIndex1(0);
      return [
        {
          id: msg.from,
          name: msg.senderUsername,
          seen: seen,
          message: msg.message,
          text: msg.message,
          type: msg.chat_type === 1 ? "Artist" : "user",
          imgUrl: msg.imgUrl?.[0]?.src || imgUrl,
          date: todaysDate(),
          lastChatTime: currentTime(),
          is_attached: msg.is_attached || 0,
          is_attached_file: msg.is_attached_file || "",
        },
        ...checkArray,
      ];
    }
  };

  const updateAllUnreadMessage = (msg: any, seen: boolean = true) => {
    const index = unreadMessage.findIndex(
      (ele: any) => ele.id === (msg.chat_type === 1 ? msg.to : msg.from)
    );
    if (index !== -1) {
      setActiveIndex2(0);
      const updatedElement = {
        ...unreadMessage[index],
        seen: seen,
        message: msg.message,
        text: msg.message,
        date: todaysDate(),
        lastChatTime: currentTime(),
        is_attached: msg.is_attached || 0,
        is_attached_file: msg.is_attached_file || "",
      };
      setUnreadMessage([
        updatedElement,
        ...unreadMessage.slice(0, index), // Elements before the found element
        ...unreadMessage.slice(index + 1), // Elements after the found element
      ]);
    }
  };

  // useEffect(() => {

  //   const userDatas = getLocalStorageObject("chat_user");

  //   if (userDatas || alluserListId) {

  //     const userData = userDatas || {
  //       userName: alluserListId?.userName || '',
  //       user_id: alluserListId?.alluserListId || '',
  //       imgUrl: alluserListId?.userImg || '',
  //     };
  //     setChattingTab("user");
  //     setSelectedUserId(userData.user_id);
  //     setImgUrl(userData.imgUrl);
  //     setName(userData.userName);
  //     const selectedUserArray = {
  //       imgUrl: userData.imgUrl,
  //       name: userData.userName,
  //       id: userData.user_id,
  //     };
  //     dispatch(selectedBlockedId(selectedUserArray as SelectedUserType));
  //   }
  // }, [alluserListId]);

  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    //connectSocket(token);
    onMessageReceived((msg: ReceivedMessage) => {
      if (chattingTab === "user" && selectedUser?.id === selectedUserId) {
        messageSeen("activeChatUserId", selectedUserId);
      } else if (chattingTab === "Artist" && selectedGroupId) {
        messageSeen("group_id", selectedGroupId);
      }
      const isOneToOneMessage =
        msg.chat_type === 0 &&
        msg.from &&
        msg.to &&
        selectedUserId &&
        (msg.from === selectedUserId || msg.to === selectedUserId);
      const isGroupMessage =
        msg.chat_type === 1 &&
        msg.from &&
        msg.to &&
        selectedGroupId &&
        (msg.from === selectedGroupId || msg.to === selectedGroupId);
      if (isOneToOneMessage && selfUserId != selectedUserId) {
        setChatHistory((prevChatHistory: any) => [
          ...prevChatHistory,
          {
            actionType: 1,
            message: msg.message,
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: selectedUserId,
            imgUrl: "",
            read_seen: "",
            sender_name: "",
            delivered: 0,
            is_delete: false,
            is_attached: msg.is_attached,
            is_attached_file: msg?.is_attached_file,
          },
        ]);
      } else if (isGroupMessage) {
        setGroupsChatHistory((prevChatHistory: any) => [
          ...prevChatHistory,
          {
            actionType: msg.actionType || 3,
            message: msg.message,
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: selectedGroupId,
            imgUrl: msg?.profile_image?.[0]?.src,
            read_seen: "",
            sender_name: msg.senderName,
            delivered: 0,
            is_delete: false,
            is_attached: msg.is_attached,
            is_attached_file: msg?.is_attached_file,
          },
        ]);
      } else {
        if (msg.chat_type == 0 && selfUserId != selectedUserId) {
          setAllChat(updateAllChats(msg, allChat));
        } else if (selfUserId != selectedUserId) {
          setGroupChat(updateAllChats(msg, [...groupChat]));
        }
      }
      scrollToBottom();
      divRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    });

    //return () => disconnectSocket();
  }, [token, selectedUserId, selectedGroupId]);

  const [selectedUser, setSelectedUser] = useState<any>();

  const [unreadMessage, setUnreadMessage] = useState<any>([]);
  const [groupChat, setGroupChat] = useState<any>([]);

  useEffect(() => {
    const updatedArray = allUserData.map((data: any, index: number) => ({
      imgUrl: data?.profile_image?.[0]?.src,
      name: data.display_name,
      project: data.beat_name,
      seen: data.unreadCount === 0,
      date: data.lastChatTime ? getformatDate(data.lastChatTime) : "",
      time: getformatTime(data.lastChatTime),
      type: data.type,
      id: data._id,
      text: data.text,
      username:data.username,
      allowNotification: Boolean(data.mute),
      chatStatus: data.chatStatus,
      lastChatTime: new Date(data.lastChatTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    }));
    setAllChat(updatedArray);
    // }
  }, [allUserData]);

  useEffect(() => {
    if (allUnread.length > 0) {
      const updatedArray = allUnread.map((data: any, index: number) => ({
        imgUrl: data?.profile_image?.[0]?.src,
        name: data.sender_name,
        project: data.beat_name,
        seen: false,
        date: data.lastChatTime ? getformatDate(data.lastChatTime) : "",
        time: getformatTime(data.lastChatTime),
        type: data.isGroup ? "Artist" : "user",
        id: data.group_id || data._id,
        text: data.text,
        allowNotification: Boolean(data.mute),
        chatStatus: data.chatStatus,
        lastChatTime: new Date(data.lastChatTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      }));
      setUnreadMessage(updatedArray);
    }
  }, [allUnread]);
  useEffect(() => {
    if (allGroupData?.length > 0) {
      const updatedArray = allGroupData.map((data: any, index: number) => ({
        groupimgUrl: data?.art_work_image?.[0]?.src || data?.default_artwork,
        groupname: data.project_name,
        seen: data.unreadCount === 0,
        date: data.latestMessageTime ? getformatDate(data.latestMessageTime) : "",
        time: getformatTime(data.latestMessageTime),
        type: "Artist",
        id: data._id,
        text: data.text,
        allowNotification: Boolean(data.mute),
      }));
      setGroupChat(updatedArray);
    }
  }, [allGroupData]);

  useEffect(() => {
    scrollToBottom();
  }, [
    chatHistory,
    selectedUserId,
    isOnetoOne,
    groupsChatHistory,
    selectedGroupId,
  ]);

  const getFilteredChatHistory = () => {
    if (isOnetoOne) {
      return chatHistory.filter((item: any) => item.id === selectedUserId);
    } else {
      return groupsChatHistory.filter(
        (item: any) => item.id === selectedGroupId
      );
    }
  };

  const [show, setShow] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  const handleSendMessage = async (message: string) => {
    if (message.trim()) {
      let messageData: MessageData;

      if (!isOnetoOne && selectedGroupId) {
        messageData = {
          chat_type: 1,
          group_id: selectedGroupId,
          message,
        };
      } else {
        messageData = {
          chat_type: 0,
          message_to: selectedUserId,
          message,
        };
      }
      if (isOnetoOne) {
        setChatHistory((prevHistory: any) => [
          ...prevHistory,
          {
            actionType: 0,
            message: messageData.message,
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: selectedUserId,
            sender_name: "",
            delivered: 0,
            is_delete: false,
          },
        ]);
        setAllChat(
          updateAllChats(
            { from: selectedUserId, message, senderUsername: name },
            [...allChat],
            true
          )
        );
        updateAllUnreadMessage({
          from: selectedUserId,
          message,
          senderUsername: name,
        });
      } else {
        setGroupChat(
          updateAllChats(
            { from: selectedGroupId, message, senderUsername: name },
            [...groupChat],
            true
          )
        );

        updateAllUnreadMessage({
          from: selectedGroupId,
          message,
          senderUsername: name,
        });

        setActiveIndex3(0);
        setGroupsChatHistory((prevHistory: any) => [
          ...prevHistory,
          {
            actionType: 0,
            message: messageData.message,
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: selectedGroupId,
            delivered: 0,
            is_delete: false,
            // sender_name: messageData.sender_name
          },
        ]);
      }
      try {
        const result = await sendMessage(
          messageData,
          isOnetoOne ? selectedUserId : selectedGroupId
        );
        if (result) {
          if (chattingTab === "user") {
            setChatHistory((prevHistory: any) => {
              const updatedHistory = prevHistory.map((ele: any) => ({
                ...ele,
                delivered: result > ele.delivered ? result : ele.delivered,
              }));
              return updatedHistory;
            });
          } else if (chattingTab === "Artist") {
            setGroupsChatHistory((prevHistory: any) => {
              const updatedHistory = prevHistory.map((ele: any) => ({
                ...ele,
                delivered: result > ele.delivered ? result : ele.delivered,
              }));
              return updatedHistory;
            });
          }
        }
      } catch (error) {
        updateErrorMessage(true, "Network Error or Server Error");
      }
      setMessage("");
    }

    scrollToBottom();
  };


  const click = () => {
    setShow(!show);
  };
  const [dropdown, setdropDown] = useState(false);

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
    setParentTabValue(value);
    setActiveIndex1(null);
    setActiveIndex2(null);
    setActiveIndex3(null);
  };
  const [activeIndex1, setActiveIndex1] = useState<number | null>(null);
  const [activeIndex2, setActiveIndex2] = useState<number | null>(null);
  const [activeIndex3, setActiveIndex3] = useState<number | null>(null);

  const getClassNames = (ele: any) => {
    return `newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
      activeIndex1 === ele.id ? "active-chat" : ""
    }`;
  };

  useEffect(() => {
    const getChattingTabDetails = () => {
      const currentChat = allChat
        .flat()
        .find((ele: any) => ele.id === selectedUserId);
      if (currentChat) {
        setName(currentChat?.name);
        setImgUrl(currentChat?.imgUrl);
        setOption(currentChat?.allowNotification ? "Unmute" : "Mute");
        const selectedUserArray = {
          imgUrl: currentChat?.imgUrl,
          name: currentChat?.name,
          id: currentChat?.id,
        };
        dispatch(selectedBlockedId(selectedUserArray as SelectedUserType));
      } else {
        setOption("Mute");
      }
    };
    getChattingTabDetails();

  }, [selectedUserId, allUserData]);

  const getGroupChattingTabDetails = () => {
    const currentChat = groupChat
      .flat()
      .find((ele: any) => ele.id === selectedGroupId);
    return currentChat
      ? {
          groupname: currentChat.groupname,
          groupimgUrl: currentChat.groupimgUrl,
        }
      : { name: "Name Not Found", imgUrl: "" };
  };
  const { groupname, groupimgUrl } = getGroupChattingTabDetails();

  const handleDropdownToggle = (index: number) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };
  const [errorMessage, setErrorMessage] = useState<string>("");
  const updateErrorMessage = (check: boolean, message?: string) => {
    if (check) {
      setErrorMessage(
        message ||
          "File size exceeds the 20MB limit. Upload a smaller file."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }
  };

  const handleDownload = async (e: any, item: any) => {
    e.preventDefault(); // Prevent default link behavior.

    const fileUrl = `${process.env.REACT_APP_IMGURL}/${item.is_attached_file?.[0]?.src}`;
    const fileName = item.is_attached_file?.[0]?.src || "download";
    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }

      const contentLength = response.headers.get("content-length");
      if (!contentLength) {
        throw new Error("Unable to determine file size.");
      }

      const total = parseInt(contentLength, 10);
      let loaded = 0;

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Unable to read file stream.");
      }

      const chunks: Uint8Array[] = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        if (value) {
          chunks.push(value);
          loaded += value.length;

          // Calculate and report progress
          const progress = Math.round((loaded / total) * 100);
        }
      }

      // Combine chunks into a single Blob
      const blob = new Blob(chunks);
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link); // Append to DOM to make it clickable
      link.click();
      document.body.removeChild(link); // Clean up after download

      // Release the blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const onFileuploaded = (data: any) => {
    if (data) {
      let messageData: any;
      if (!isOnetoOne && selectedGroupId) {
        messageData = {
          chat_type: 1,
          group_id: selectedGroupId,
          message,
          is_attached: 1,
          is_attached_file: data,
        };
      } else {
        messageData = {
          chat_type: 0,
          message_to: selectedUserId,
          message,
          is_attached: 1,
          is_attached_file: data,
        };
      }

      sendMessage(messageData, isOnetoOne ? selectedUserId : selectedGroupId);

      isOnetoOne
        ? setChatHistory((prevItems: any) => {
            if (prevItems.length > 0) {
              return [
                ...prevItems.slice(0, prevItems.length - 1), // Copy all items except the last one
                { ...prevItems[prevItems.length - 1], is_attached_file: data }, // Update the last object
              ];
            }
            return prevItems; // Return the original array if empty
          })
        : setGroupsChatHistory((prevItems: any) => {
            // Check if there is at least one item in the array
            if (prevItems.length > 0) {
              // Create a new array with the last object updated
              return [
                ...prevItems.slice(0, prevItems.length - 1), // Copy all items except the last one
                { ...prevItems[prevItems.length - 1], is_attached_file: data }, // Update the last object
              ];
            }
            return prevItems; // Return the original array if empty
          });
      if (isOnetoOne) {
        setActiveIndex1(0);
        setAllChat(
          updateAllChats(
            {
              chat_type: 0,
              from: isOnetoOne ? selectedUserId : selectedGroupId,
              to: isOnetoOne ? selectedUserId : selectedGroupId,
              message: "",
              is_attached: 1,
              is_attached_file: "",
            },
            [...allChat],
            true
          )
        );
      } else {
        setActiveIndex3(0);
        setGroupChat(
          updateAllChats(
            {
              chat_type: 1,
              from: isOnetoOne ? selectedUserId : selectedGroupId,
              to: isOnetoOne ? selectedUserId : selectedGroupId,
              message: "",
              is_attached: 1,
              is_attached_file: "",
            },
            [...groupChat],
            true
          )
        );
      }

      let checkV = activeIndex2 || -1;
      if (tabsValue === 1 && checkV > -1) {
        updateAllUnreadMessage({
          from: isOnetoOne ? selectedUserId : selectedGroupId,
          to: isOnetoOne ? selectedUserId : selectedGroupId,
          message,
          senderUsername: name,
          is_attached: 1,
        });
      }
    } else {
      isOnetoOne
        ? setChatHistory((prevChatHistory: any) => [
            ...prevChatHistory,
            {
              actionType: 0,
              message: "",
              time: new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              id: selectedUserId,
              imgUrl: "",
              read_seen: "",
              sender_name: "",
              delivered: "",
              is_attached: 1,
              is_attached_file: "",
            },
          ])
        : setGroupsChatHistory((prevChatHistory: any) => [
            ...prevChatHistory,
            {
              actionType: 0,
              message: "",
              time: new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              id: selectedGroupId,
              imgUrl: "",
              read_seen: "",
              sender_name: "",
              delivered: "",
              is_attached: 1,
              is_attached_file: "",
            },
          ]);
    }
  };

  type FileExtension = "png" | "jpeg" | "jpg" | "anonymous" | string; // Add any other types as needed

  type ExtensionMap = {
    [key in FileExtension]: string;
  };

  function truncateStringWithExtension(
    input: string,
    maxLength: number = 10
  ): string {
    // Find the position of the last dot (.) to identify the extension
    const dotIndex = input?.lastIndexOf(".");
    if (!dotIndex) {
      return input;
    }
    // If there's no dot or the string length is less than or equal to maxLength, return as-is
    if (dotIndex === -1 || input.length <= maxLength) {
      return input;
    }

    const base = input.substring(0, dotIndex);
    const extension = input.substring(dotIndex);

    // If the base part is longer than maxLength, truncate and add '...'
    if (base.length > maxLength) {
      return base.substring(0, maxLength) + "..." + extension;
    } else {
      return input; // Return full string if the base part is within maxLength
    }
  }

  function getFileTypeImage(filePath?: string, filesrc?: string): string {
    // Get the file extension from the file path and convert to lowercase
    const extension: FileExtension =
      filePath?.split(".")?.pop()?.toLowerCase() || "";
    if (!extension) {
      return "no-image";
    }

    const extensionMap: ExtensionMap = {
      png: `${process.env.REACT_APP_IMGURL}/${filesrc}`,
      jpeg: `${process.env.REACT_APP_IMGURL}/${filesrc}`,
      jpg: `${process.env.REACT_APP_IMGURL}/${filesrc}`,
      gif: `${process.env.REACT_APP_IMGURL}/${filesrc}`,
      anonymous: loaderSvg,
    };
    return extensionMap[extension] || loaderSvg;
  }

  return (
    <div className="newmessage pb-3 px-0 mt-0-mbl">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 w-100">
        <div className="d-flex">
          <div
            className={`newmessage-left uni-pt-56 px-0 pb-0 position-relative ${
              dropdown || showRightSide ? "mobile-d-none" : ""
            } `}
          >
            <Heading2 className="newmessage-left-heading uni-mb-24 uni-ml-16 d-flex align-items-center justify-content-between">
              <span> Messages</span>
            </Heading2>
            <Tabs
              id="10"
              tabsTitle={["All", "Unread", "Projects"]}
              className="uni-mb-24 uni-ml-16 message-tabs"
              checkTabState={checkTabState}
              changeTabValue={reduxTabValue}
            />

            <DropworksSearchInput className="uni-mb-24 uni-ml-16 search-input-message" />
            <div className="newmessage-left-chatsection">
              <TabsComponent
                tabsValue={tabsValue}
                components={[
                  <>
                    {loading.allUsers ? (
                      <div>
                        <Loader />
                      </div>
                    ) : allChat?.length > 0 ? (
                      allChat.map((ele: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${getClassNames(
                              ele
                            )} ${activeIndex1 === index ? "active-chat" : ""}`}
                            onClick={() => {
                              if (selectedUserId !== ele.id || !isOnetoOne) {
                                let alldata = [...allChat];
                                alldata[index] = {
                                  ...alldata[index],
                                  seen: true,
                                };
                                setAllChat(alldata);
                                setChatHistory([]);
                                setAllUnread(
                                  allUnread.filter(
                                    (elem: any) => elem?._id != ele?.id
                                  )
                                );
                                setUnreadMessage(
                                  allUnread.filter(
                                    (elem: any) => elem?._id != ele?.id
                                  )
                                );
                                setChattingTab("user");
                                setSelectedUserId(ele.id);
                                setActiveIndex1(index);
                                setSelectedUser(ele);
                                dispatch(selectedBlockedId(ele));
                                setdropDown(true);
                                setIsOnetoOne(true);
                                setErrorMessage("");
                                const userPayload = {
                                  userImg: ele.imgUrl || "",
                                  userName: ele.name || "",
                                  alluserListId: ele?.id || "",
                                  chattingTab: "user",
                                  tabValue: 0,
                                  username:ele.username
                                };
                                dispatch(setuserListData(userPayload));
                              }
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_IMGURL}/${ele.imgUrl}`}
                              className={
                                ele.type === "user"
                                  ? "img-profilepic"
                                  : "img-profilepic-project"
                              }
                              onError={(e) => (e.currentTarget.src = ErrorImg)}
                              alt="image"
                            />
                            <div className=" align-items-center uni-ml-16 mobile-pr-16 m-w-100">
                              <p className="newmessage-left-chatsection-chat-heading">
                                {ele.type === "user" ? ele.name : ele.project}
                                <p>
                                  {!ele.seen ? (
                                    <p className="newmessage-left-chatsection-chat-time">
                                      {ele.lastChatTime}
                                    </p>
                                  ) : (
                                    <p className="newmessage-left-chatsection-chat-date">
                                      {ele.date}
                                    </p>
                                  )}
                                </p>
                              </p>
                              <p className="newmessage-left-chatsection-chat-text">
                                <p className="newmessage-left-chatsection-chat-text-long-text">
                                  {ele.is_attached ? "attached_file" : ele.text}
                                </p>
                                <p className="d-flex align-items-center">
                                  {ele.allowNotification ? (
                                    <img
                                      className="img-offNotification uni-mx-8"
                                      src={OffNotification}
                                    />
                                  ) : (
                                    <span className="uni-pl-40"></span>
                                  )}
                                  {ele.seen ? (
                                    <ArrowDropdown
                                      pagename="call-funtion"
                                      className="px-0 mobile-p-0"
                                      arr={[
                                        ele.allowNotification
                                          ? "Unmute"
                                          : "Mute",
                                        "Block",
                                        "Report",
                                        "Delete",
                                      ]}
                                      onClickOptions={(stelected: any) =>
                                        onOptionChange(stelected, ele)
                                      }
                                      isOpen={activeDropdown === index}
                                      onToggle={() =>
                                        handleDropdownToggle(index)
                                      }
                                    />
                                  ) : (
                                    <p className={"notify-yellow-dot"}></p>
                                  )}
                                </p>
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="uni-p-40 text-center">No chats available</div>
                    )}
                  </>,
                  <>
                    {loading.unreadUsers ? (
                      <div>
                        <Loader />
                      </div>
                    ) : unreadMessage?.length > 0 ? (
                      unreadMessage.map((ele: any, index: any) => {
                        return (
                          <div
                            className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
                              activeIndex2 === index ? "active-chat" : ""
                            }`}
                            onClick={() => {
                              setChattingTab(ele.type),
                                setActiveIndex2(index),
                                setdropDown(true);
                              setName(ele.name);
                              setImgUrl(ele.imgUrl);
                              setChatHistory([]);
                              if (ele.type === "user") {
                                setSelectedUserId(ele.id);
                                setIsOnetoOne(true);
                                setSelectedUser(ele);
                                const userPayload = {
                                  userImg: ele.imgUrl || "",
                                  userName: ele.display_name || "",
                                  alluserListId: ele?.id || "",
                                  chattingTab: "user",
                                  tabValue: 1,
                                  username:ele.username
                                };
                                dispatch(setuserListData(userPayload));
                              } else {
                                setSelectedGroupId(ele.id),
                                  setIsOnetoOne(false),
                                  setActiveIndex3(index),
                                  setdropDown(true);
                                setOption(
                                  ele.allowNotification ? "Unmute" : "Mute"
                                );
                                const userPayload = {
                                  userImg: ele.groupimgUrl || "",
                                  userName: ele.groupname || "",
                                  alluserListId: ele?.id || "",
                                  chattingTab: "Artist",
                                  tabValue: 1,
                                };
                                dispatch(setuserListData(userPayload));
                              }

                              let alldata = [...unreadMessage];
                              alldata[index] = {
                                ...alldata[index],
                                seen: true,
                              };
                              setUnreadMessage(alldata);
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_IMGURL}/${ele.imgUrl}`}
                              className={
                                ele.type === "user"
                                  ? "img-profilepic"
                                  : "img-profilepic-project"
                              }
                              onError={(e) => (e.currentTarget.src = ErrorImg)}
                              alt="image"
                            />
                            <div className=" align-items-center uni-ml-16 mobile-pr-16 m-w-100">
                              <p className="newmessage-left-chatsection-chat-heading ">
                                {/* {ele.type === 'user' ? ele.name : ele.project } */}
                                {ele.name}
                                <p>
                                  {!ele.seen ? (
                                    <p className="newmessage-left-chatsection-chat-time">
                                      {ele.time}
                                    </p>
                                  ) : (
                                    <p className="newmessage-left-chatsection-chat-date">
                                      {ele.date}
                                    </p>
                                  )}
                                </p>
                              </p>
                              <p className="newmessage-left-chatsection-chat-text">
                                <div className="newmessage-left-chatsection-chat-text-long-text">
                                  {ele.is_attached ? "attached_file" : ele.text}
                                </div>
                                <p className="d-flex align-items-center">
                                  {!ele.allowNotification && (
                                    <img
                                      className="img-offNotification uni-mx-8"
                                      src={OffNotification}
                                    />
                                  )}
                                  {!ele.seen && (
                                    <p
                                      className={
                                        ele.allowNotification
                                          ? "notify-yellow-dot uni-ml-40"
                                          : "notify-yellow-dot "
                                      }
                                    ></p>
                                  )}
                                  {ele.seen && !ele.allowNotification && (
                                    <ArrowDropdown
                                      pagename="call-funtion"
                                      className="px-0 mobile-p-0"
                                      arr={
                                        ele.type === "user"
                                          ? [
                                              ele.allowNotification
                                                ? "Unmute"
                                                : "Mute",
                                              "Block",
                                              "Report",
                                              "Delete",
                                            ]
                                          : [
                                              ele.allowNotification
                                                ? "Unmute"
                                                : "Mute",
                                            ]
                                      }
                                      onClickOptions={(stelected: any) => {
                                        ele.type === "user"
                                          ? onOptionChange(stelected, ele)
                                          : onOptionChangeGroup(
                                              stelected,
                                              ele?.id
                                            );
                                      }}
                                      // id={`${index}`}
                                      isOpen={activeDropdown === index}
                                      onToggle={() =>
                                        handleDropdownToggle(index)
                                      }
                                    />
                                  )}
                                </p>
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="uni-p-40 text-center">
                        No Unread Chats
                      </div>
                    )}
                  </>,
                  <>
                    {loading.groupUsers ? (
                      <div>
                        <Loader />
                      </div>
                    ) : groupChat?.length > 0 ? (
                      groupChat.map((ele: any, index: any) => {
                        return (
                          <div
                            className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
                              activeIndex3 === index ? "active-chat" : ""
                            }`}
                            onClick={() => {
                              let alldata = [...groupChat];
                              alldata[index] = {
                                ...alldata[index],
                                seen: true,
                              };
                              setGroupChat(alldata);
                              setChattingTab(ele.type),
                                setAllUnread((prev: any) =>
                                  prev.filter(
                                    (elem: any) => elem?._id != ele?.id
                                  )
                                );
                              setSelectedGroupId(ele.id),
                                setIsOnetoOne(false),
                                setActiveIndex3(index),
                                setdropDown(true);
                              setOption(
                                ele.allowNotification ? "Unmute" : "Mute"
                              );
                              const userPayload = {
                                userImg: ele.groupimgUrl || "",
                                userName: ele.groupname || "",
                                alluserListId: ele?.id || "",
                                chattingTab: "Artist",
                                tabValue: 2,
                              };
                              dispatch(setuserListData(userPayload));
                            }}
                          >
                            {/* src={`${process.env.REACT_APP_IMGURL}/${ele.imgUrl}`} */}
                            <img
                              // src={ele.groupimgUrl}
                              src={`${process.env.REACT_APP_IMGURL}/${ele.groupimgUrl}`}
                              className={
                                ele.type === "Project"
                                  ? "img-profilepic"
                                  : "img-profilepic-project"
                              }
                              onError={(e) => (e.currentTarget.src = ErrorImg)}
                              alt="image"
                            />
                            <div className=" align-items-center uni-ml-16 mobile-pr-16 m-w-100">
                              <p className="newmessage-left-chatsection-chat-heading ">
                                {ele.groupname}{" "}
                                <p>
                                  {!ele.seen ? (
                                    <p className="newmessage-left-chatsection-chat-time">
                                      {ele.time}
                                    </p>
                                  ) : (
                                    <p className="newmessage-left-chatsection-chat-date">
                                      {ele.date}
                                    </p>
                                  )}
                                </p>
                              </p>
                              <p className="newmessage-left-chatsection-chat-text">
                                <p className="newmessage-left-chatsection-chat-text-long-text">
                                  {ele.is_attached ? "attached_file" : ele.text}
                                </p>
                                <p className="d-flex align-items-center">
                                  {ele.allowNotification ? (
                                    <img
                                      className="img-offNotification uni-mx-8"
                                      src={OffNotification}
                                    />
                                  ) : (
                                    <span className="uni-pl-40"></span>
                                  )}
                                  {ele.seen ? (
                                    <ArrowDropdown
                                      pagename="call-funtion"
                                      className="px-0 mobile-p-0"
                                      arr={[
                                        ele.allowNotification
                                          ? "Unmute"
                                          : "Mute",
                                      ]}
                                      onClickOptions={(stelected: any) =>
                                        onOptionChangeGroup(stelected, ele?.id)
                                      }
                                      isOpen={activeDropdown === index}
                                      onToggle={() =>
                                        handleDropdownToggle(index)
                                      }
                                    />
                                  ) : (
                                    <p className={"notify-yellow-dot"}></p>
                                  )}
                                </p>
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="uni-p-40 text-center">No group chats available</div>
                    )}
                  </>,
                ]}
              />
            </div>
            <span
              className="add-icon-btn  cursor-pointer "
              onClick={() => openPopup("UserListModal", dispatch)}
            >
              <MdAdd size={32} />
            </span>
          </div>

          <div
            className={`full-width-child newmessage-right uni-px-24 ${
              dropdown || showRightSide ? "" : "mobile-d-none"
            } `}
          >
            {chattingTab ? (
              <>
                <div className="newmessage-right-heading-content d-flex align-items-center">
                  <span
                    onClick={() => {
                      dispatch(setShowRightSide(false));
                      setdropDown(false);
                    }}
                  >
                    <BackArrowIcon className="uni-mr-16 hide-on-web" />
                  </span>{" "}
                  <img
                    src={
                      chattingTab === "user"
                        ? `${process.env.REACT_APP_IMGURL}/${imgUrl}`
                        : `${process.env.REACT_APP_IMGURL}/${groupimgUrl}`
                    }
                    className={
                      chattingTab === "user"
                        ? "img-profilepic"
                        : "img-profilepic-project"
                    }
                    onError={(e) => (e.currentTarget.src = ErrorImg)}
                    alt="image"
                  />
                  <p className="newmessage-right-heading-content-heading uni-mr-16">
                    <Link
                      to={
                        chattingTab === "user" ? `/user-profile/${username}` : "#"
                      }
                      onClick={(e) => {
                        if (chattingTab === "Artist") {
                          e.preventDefault();
                        }
                      }}
                      className="uni-pl-16"
                    >
                      {chattingTab === "user" ? name : groupname}
                    </Link>
                  </p>
                  {chattingTab === "Artist" ? (
                    <ArrowDropdown
                      pagename="call-funtion"
                      height="6px"
                      showArrowDropdown={false}
                      onClickOptions={(selected: string) =>
                        onOptionChangeGroup(selected)
                      }
                      arr={[option]}
                    ></ArrowDropdown>
                  ) : (
                    <ArrowDropdown
                      pagename="call-funtion"
                      height="6px"
                      showArrowDropdown={false}
                      onClickOptions={(selected: string) =>
                        onOptionChange(selected, selectedUser)
                      }
                      arr={[option, "Block", "Report", "Delete"]}
                    ></ArrowDropdown>
                  )}
                </div>
                {errorMessage && (
                  <TextfieldMD className="bg-danger uni-p-16 text-center w-100">
                    {errorMessage}
                  </TextfieldMD>
                )}
                <div className="newmessage-right-chatting justify-content-end uni-py-32">
                  <div
                    className="newmessage-chat-history flex-column"
                    ref={chatHistoryRef}
                  >
                    {getFilteredChatHistory().map(
                      (item: any, index: number) => (
                        <>
                          {item.actionType === 1 && !item.is_delete && (
                            <MessageRecieved
                              chattingTab={chattingTab}
                              item={item}
                              handleDownload={handleDownload}
                              deleteChat={deleteChat}
                              handleDropdownToggle={handleDropdownToggle}
                              activeDropdown={activeDropdown}
                              index={index}
                              truncateStringWithExtension={
                                truncateStringWithExtension
                              }
                              getFileTypeImage={getFileTypeImage}
                              loaderSvg={loaderSvg}
                              ErrorImg={ErrorImg}
                            />
                          )}

                          {item.actionType === 0 && !item.is_delete && (
                            <NewMessageSender
                              item={item}
                              index={0}
                              mainIndex={index}
                              handleDownload={handleDownload}
                              getFileTypeImage={getFileTypeImage}
                              deleteuserchat={deleteChat}
                              handleDropdownToggle={handleDropdownToggle}
                              activeDropdown={null}
                              loaderSvg={loaderSvg}
                              ErrorImg={ErrorImg}
                              onDelete={deleteChat}
                            />
                          )}
                          {item.actionType === 3 && (
                            <div className="">
                              <p className="text-center group-add uni-mb-8">
                                <span className="uni-p-8"> {item.message}</span>
                              </p>
                            </div>
                          )}
                        </>
                      )
                    )}
                  </div>
                  <button className="d-none" id="clickedbutton"></button>
                  <div className="newmessage-send-message">
                    <DropworksMessageInput
                      userData={
                        chattingTab === "user"
                          ? { name: "selected_user_id", value: selectedUserId }
                          : { name: "group_id", value: selectedGroupId }
                      }
                      placeholder="Type Search"
                      message={handleSendMessage}
                      onFileSizeError={(e: boolean) => updateErrorMessage(e)}
                      onFileUploaded={(data: any) => onFileuploaded(data)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="newmessage-right-heading-content d-flex align-items-center">
                <div className="newmessage-right-chatting justify-content-center align-items-center uni-py-32">
                  <span
                    className="newmessage-right-start-coversartion uni-px-32 cursor-pointer"
                    onClick={() => openPopup("UserListModal", dispatch)}
                  >
                    Start Conversation
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <button id="clickedbutton" className="d-none"></button>
    </div>
  );
};

export const closeArrowDropdown = () => {
  const button = document.getElementById("clickedbutton") as HTMLButtonElement;
  if (button) {
    button.click(); // Trigger the button's click event
  } else {
    console.error("Button with ID 'clickedbutton' not found");
  }
};
