import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { getLibraryData } from "../../../shared/utils/apiServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { getformatDate, mixMasterAudio } from "../../../shared/utils/localStorage";

export const Library = () => {
  const [libraryProject, setLibraryProject] = useState<string[]>([]);
  const [libraryRerelease, setLibraryRerelease] = useState<string[]>([]);
  const {musicPlayer} =useSelector((state:RootState)=>state.music)

  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const [trackarr, setTrackarr] = useState([
    { heading: "", subheading: "", image: "", slugName: "",id:"" }
  ]);

  const [projectarr, setProjectarr] = useState([
    { heading: "", subheading: "", image: "", slugName: "", isRerelease: "" }
  ]);

  const [beatsarr, setBeatsarr] = useState([
    { heading: "", subheading: "", image: "", beatName: "", fullBeatData: "" ,id:""}
  ]);
 
  const user =useSelector((state:RootState)=>state.user)
  useEffect(() => {
    const fetchAlldata = async () => {
      const result = await getLibraryData();
      if (result) {
        setLibraryProject(result.allLibraryData?.data?.projectData);
        setTrackarr(result.allLibraryData?.data.trackData?.map((item: any) => (
          {
            heading: item?.project_name,
            subheading: item?.description,
            image: item?.art_work_image[0]?.src || item?.default_artwork,
            slugName: item?.slug_name,
            createrName:item?.collaborators,
            id:item?._id,
            audioUrl:mixMasterAudio(item.upload_file),
            audioLiked:Boolean(item?.favoriteData?._id),
            audioPath:`track-page/${item?.slug_name}`,
            audioName:item?.track_name,
            fullTrackData:[item],
            audioByMe:true,
            trackDetailsReleaseDate:getformatDate(item?.created_at),
            trackDeatilsCreatorList:[item?.creator_user_name],
            trackDetailsUniqueId:item?.unique_id
          }
        )))
        setBeatsarr(result.allLibraryData?.data?.beatData?.map((item: any) => (
          {
            heading: item?.beat_name,
            subheading: item?.description,
            image: item?.art_work_image[0]?.src,
            beatName: item?.beat_slug_name,
            userDetails: item?.userDeatils,
            fullBeatData: [item],
            id:item._id
          }
        )))
        setLibraryRerelease(() => result.allLibraryData?.data?.releasedTrackData?.map((item: any) => ({
          heading: item?.project_name,
          subheading: item?.description,
          image: item?.art_work_image[0]?.src || item?.default_artwork,
          slugName: item?.slug_name,
          isRerelease: item?.is_rereleased_track,
          is_approved:item.is_approved,
          showLabel:item.is_released
        })))
      }
    };

    fetchAlldata()
  }, [])


  useEffect(()=>{
    if(musicPlayer.musicType ==="track" && Boolean(trackarr.length))
    {
      let newUpdatedData=trackarr.map((ele)=>{
        if(ele?.id && ele?.id === musicPlayer.musicId)
        {
          return {...ele,audioLiked:musicPlayer.musicLiked ,favoriteData:musicPlayer.musicLiked ?{_id:"abc"}:""}
        }
        else{
          return ele ;
        }
      })
     setTrackarr(newUpdatedData);    
    }
    else if(musicPlayer.musicType ==="beat" && Boolean(beatsarr.length)) {
      let newUpdatedData=beatsarr.map((ele:any)=>{
        if(ele.id && ele.id === musicPlayer.musicId)
          {
          let newdata= [{...ele.fullBeatData?.[0],favoriteData:musicPlayer.musicLiked?{_id:"abcd"}:""}];
          return {...ele,fullBeatData:newdata}
        }
        else{
          return ele ;
        }
      })
 
     setBeatsarr(newUpdatedData);  
   
    }
      },[musicPlayer.musicLiked , musicPlayer.musicId])


  useEffect(() => {
    if (Array.isArray(libraryProject)) {
      setProjectarr(libraryProject.map((item: any) => (
        {
          collaborators: item?.collaborators,
          heading: item.project_name,
          subheading: item.description,
          image: item.art_work_image[0]?.src ? item.art_work_image[0]?.src : item.default_artwork,
          slugName: item.slug_name,
          isRerelease: item.is_rereleased_track,
          is_approved:item.is_approved,
          showLabel:item.is_released,
          is_approved_by_admin:item?.is_approved_by_admin
        }
      )))
    }
  }, [libraryProject])

  const [filteredProject, setFilteredProject] = useState<any>([]);
  const filterActive = () => {
    if(status !=="Active")
    {
      setStatus("Active");
      const flitered = libraryProject.filter((item: any) => !item?.is_released)
    setFilteredProject(flitered.map((item: any) => (
      {
        collaborators: item?.collaborators,
        heading: item.project_name,
        subheading: item.description,
        image: item.art_work_image[0]?.src ? item.art_work_image[0]?.src : item.default_artwork,
        slugName: item.slug_name,
        isRerelease: item.is_rereleased_track,
        is_approved:item.is_approved,
        showLabel:item.is_released
      }
    )))
  }
  else{
    setStatus("");
    setFilteredProject(libraryProject.map((item: any) => (
      {
        collaborators: item?.collaborators,
        heading: item.project_name,
        subheading: item.description,
        image: item.art_work_image[0]?.src ? item.art_work_image[0]?.src : item?.default_artwork,
        slugName: item.slug_name,
        isRerelease: item.is_rereleased_track,
        is_approved:item.is_approved,
        showLabel:item.is_released
      }
    )))
  }
  }
  const filterReleased = () => {
    if(status!=="Released")
    {
      setStatus("Released")
      const flitered = libraryProject.filter((item: any) => item?.is_released)
      setFilteredProject(flitered.map((item: any) => (
        {
          collaborators: item?.collaborators,
          heading: item.project_name,
          subheading: item.description,
          image: item.art_work_image[0]?.src ? item.art_work_image[0]?.src : item?.default_artwork,
        slugName: item.slug_name,
        isRerelease: item.is_rereleased_track,
        is_approved:item.is_approved,
        showLabel:item.is_released
      }
    )))
  }
  else{
    setStatus("")
    setFilteredProject(libraryProject.map((item: any) => (
      {
        collaborators: item?.collaborators,
        heading: item.project_name,
        subheading: item.description,
        image: item.art_work_image[0]?.src ? item.art_work_image[0]?.src : item?.default_artwork,
      slugName: item.slug_name,
      isRerelease: item.is_rereleased_track,
      is_approved:item.is_approved,
      showLabel:item.is_released
    }
  )))
  }
  }


  // useEffect(()=>{
  //   if (Array.isArray(libraryBeats)) {
  //       setBeatsarr(libraryBeats.map((item:any)=>(
  //     {
  //    heading:item.beat_name,
  //    subheading:item.description,
  //    image:item.art_work_image[0]?.src,
  //    beatName:item.beat_slug_name
  //   }
  // )))}
  // },[libraryBeats])

  const navigateTo = (value: string) => () => {
    navigate(value)
  }

  return (view({ navigateTo, trackarr, projectarr, beatsarr, libraryRerelease, filterActive, filteredProject, filterReleased, status }))
}