
import "../popup.scss";
import "./styles.scss";
import { CloseModalButton } from '../../logo-header/view';
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { useEffect, useRef, useState } from 'react';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';
import { DropworksMessageInput } from '../../../../../common/custom-field/dropworks-input-messagebox';
import { MeatballsMenuDropdown } from '../../../../../common/icons';
import { Link } from 'react-router-dom';
import { BackArrowIcon } from '../../../../../common/all-icons';
import MessageRecieved from '../../../../content/messages-chat/messageRecieved';
import NewMessageSender from '../../../../content/messages-chat/messageSend';
import loaderSvg from "../../../../../assets/steps/tube-spinner.svg";
import { sendMessage } from '../../../utils/socketServices';
import { groupChatHistroy } from "../../../utils/apiServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { upload } from "@testing-library/user-event/dist/upload";
import { ErrorImg } from "../../../utils/localStorage";

interface MessageData {
  chat_type: number;
  message_to?: any;
  message: string;
  group_id?: string;
}

const view = () => {

  const [dropdown, setdropDown] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const chatHistoryRef = useRef<HTMLDivElement | null>(null);
  const [errorMessage,setErrorMessage]=useState<string>("")
  const {trackId,uploadnewbeatImg,trackDetails}=useSelector((state:RootState)=>state.upload)
  const [groupsChatHistory, setGroupsChatHistory] = useState<any[]>([]);
  
  useEffect(() => {
    scrollToBottom();
  }, [groupsChatHistory,trackId,trackDetails]);
-
  useEffect(()=>{
    const fetchGroupChatHistory = async () => {
        try {
          const result = await groupChatHistroy(trackId);
          
          if (result) { 
            
            // setGroupsChatHistory(
              let data=   result?.groupsChatHistroy?.data?.map(
                (messageData: any) =>
                  ({
                    // actionType: messageData.actionType === 0 ? 1 : 0,
                    actionType: messageData.actionType === 0 ? 1: messageData.actionType === 1? 0: 3, 
                    message: messageData.message,
                    time: new Date(messageData.chat_datetime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
                  id: trackId,
                  imgUrl: messageData?.profile_image?.[0]?.src,
                  is_attached:messageData.is_attached,
                  is_attached_file:messageData.is_attached_file,
                  read_seen: messageData.read_seen,
                  sender_name: messageData.sender_name,
                  delivered: messageData.delivered,
                  is_delete:false
                }))
              setGroupsChatHistory(data);
              // )
            }
             }
         catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    trackId && fetchGroupChatHistory();
},[trackId])


  const handleDownload = async (
    e: any,
    item: any
   
  ) => {
    e.preventDefault(); // Prevent default link behavior.
  
    const fileUrl = `${process.env.REACT_APP_IMGURL}/${item.is_attached_file?.[0]?.src}`;
    const fileName = item.is_attached_file?.[0]?.src || "download";
    try {
      const response = await fetch(fileUrl);
  
      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }
  
      const contentLength = response.headers.get("content-length");
      if (!contentLength) {
        throw new Error("Unable to determine file size.");
      }
  
      const total = parseInt(contentLength, 10); 
      let loaded = 0;
  
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Unable to read file stream.");
      }
  
      const chunks: Uint8Array[] = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        if (value) {
          chunks.push(value);
          loaded += value.length;
  
          // Calculate and report progress
          const progress = Math.round((loaded / total) * 100);
        }
      }
  
      // Combine chunks into a single Blob
      const blob = new Blob(chunks);
      const blobUrl = URL.createObjectURL(blob);
  
      // Create a temporary <a> element to trigger download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link); // Append to DOM to make it clickable
      link.click();
      document.body.removeChild(link); // Clean up after download
  
      // Release the blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  type FileExtension = 
  | 'png'
  | 'jpeg'
  | 'jpg'
  | 'anonymous'
  | string; // Add any other types as needed

type ExtensionMap = {
  [key in FileExtension]: string;
};

function getFileTypeImage(filePath?: string,filesrc?:string): string {
  const extension: FileExtension = filePath?.split('.')?.pop()?.toLowerCase() || '';
  if(!extension)
  {
    return 'no-image'
  }


  

  const extensionMap: ExtensionMap = {
    png: `${process.env.REACT_APP_IMGURL}/${filesrc}`,
    jpeg: `${process.env.REACT_APP_IMGURL}/${filesrc}`,
    jpg: `${process.env.REACT_APP_IMGURL}/${filesrc}`,
    gif:`${process.env.REACT_APP_IMGURL}/${filesrc}`,
    anonymous: loaderSvg,
  };
  return extensionMap[extension] || loaderSvg;
}


const handleDropdownToggle = (index: number) => {
  setActiveDropdown(activeDropdown === index ? null : index);
};

const deleteChat=(index:number)=>{

    let alldata=[...groupsChatHistory];
    alldata[index]={...alldata[index],is_delete:true};
    setGroupsChatHistory(alldata);

}


const todaysDate = () => {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, '0'); 
  const day = String(today.getDate()).padStart(2, '0');
  const year = today.getFullYear();
 
  return `${month}/${day}/${year}`;
};
const scrollToBottom = () => {
  setTimeout(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTo({
        top: chatHistoryRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, 500);
};
 
const currentTime = () => {
  const now = new Date();
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';
 
  hours = hours % 12 || 12;
 
  return `${String(hours).padStart(2, '0')}:${minutes} ${amPm}`;
};


  function truncateStringWithExtension(input: string, maxLength: number = 10): string {
    const dotIndex = input?.lastIndexOf('.');
  if(!dotIndex)
  {
    return input;
  }
    // If there's no dot or the string length is less than or equal to maxLength, return as-is
    if (dotIndex === -1 || input.length <= maxLength) {
      return input;
    }
  
    const base = input.substring(0, dotIndex);
    const extension = input.substring(dotIndex);
  
    // If the base part is longer than maxLength, truncate and add '...'
    if (base.length > maxLength) {
      return base.substring(0, maxLength) + '...' + extension;
    } else {
      return input; // Return full string if the base part is within maxLength
    }
  }

    const getFilteredChatHistory = () => {

        return groupsChatHistory
    
    };



    const onFileuploaded=(data:any)=>{
      if(data)
        {
          let messageData:any;
            messageData = {
              chat_type: 1,
              group_id: trackId,
              message,
              is_attached:1,
             is_attached_file:data,
            };
        
     
        sendMessage(messageData,trackId);

    setGroupsChatHistory(((prevItems:any) => {
    // Check if there is at least one item in the array
    if (prevItems.length > 0) {
      // Create a new array with the last object updated
      return [
        ...prevItems.slice(0, prevItems.length - 1), // Copy all items except the last one
        { ...prevItems[prevItems.length - 1], is_attached_file:data } // Update the last object
      ];
    }
    return prevItems; // Return the original array if empty
    }))
     
      }
    else{

    setGroupsChatHistory((prevChatHistory:any) => [
    ...prevChatHistory,
    {
      actionType: 0,
      message: "",
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      id: trackId,
      imgUrl: "",
      read_seen: '',
      sender_name:'',
      delivered:'',
      is_attached:1,
      is_attached_file:""
     },
    ]
    )
    }
    }


    const updateErrorMessage=(check:boolean,message?:string)=>{
    if(check)
    {
      setErrorMessage(message || 'File size exceeds the 20MB limit. Upload a smaller file.');
      setTimeout(()=>{
    setErrorMessage("")
      },2000)
    }
    }
   
    const handleSendMessage = async (message: string) => {
 
      if (message.trim()) {
        let messageData: MessageData;
   
          messageData = {
            chat_type: 1,
            group_id: trackId,
            message,
          };
        
        setGroupsChatHistory((prevHistory:any) => [
          ...prevHistory,
          {
            actionType: 0,
            message: messageData.message,
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: trackId,
             delivered: 0,
             is_delete:false
            
          },
        ])
      
        try {
          const result = await sendMessage(messageData,trackId);
          if (result) {
          
              setGroupsChatHistory((prevHistory: any) => {
                const updatedHistory = prevHistory?.map((ele: any) => ({
                  ...ele,
                  delivered: result > ele.delivered ? result : ele.delivered,
                }));
                return updatedHistory;
              });
            }
          
        } catch (error) {
          updateErrorMessage(true,"Network Error or Server Error")
        }
         setMessage("");
      }
   
      scrollToBottom();
    };


  
  return (
    <ModalWrapper modalId="MessageChatModal" onBackdropClick={()=>scrollToBottom()}>
    <div
    className="modal fade "
    id="MessageChatModal"
    aria-hidden="true"
    aria-labelledby="MessageChatModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width message-modal">
      <div className="modal-content">
   
        <div className="modal-header ps-0 pt-0 pe-0">
        {(
            <div className={`full-width-child newmessage-right ${dropdown ? "" :"mobile-d-none"} `}>
              <div className="newmessage-right-heading-content d-flex align-items-center  backdrop-box-filter">
                <span onClick={()=>setdropDown(false)}>
                <BackArrowIcon className="uni-mr-16 hide-on-web"/></span>
                {" "}
                <img
                  src={`${process.env.REACT_APP_IMGURL}/${uploadnewbeatImg || trackDetails?.trackData?.default_artwork}`}
                  className="img-profilepic-project-chatmodal"
                  onError={(e) => (e.currentTarget.src = ErrorImg)}
                  alt="image"
                />
                <p  className="newmessage-right-heading-content-heading"><Link to={"#"} className="uni-pl-16">
                  {trackDetails?.trackData?.project_name}
                </Link></p>
                
                  <MeatballsMenuDropdown pagename="message"
                  height="6px"
                  arr={["Mute"]}
                ></MeatballsMenuDropdown>
                 <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
              </div>
              <div className="newmessage-right-chatting newmessage-right-chatting-modal justify-content-end uni-pb-32">
                <div ref={chatHistoryRef} className="newmessage-chat-history flex-column">
                {getFilteredChatHistory()?.map((item:any, index:number) => (
                    <>
                  {(item.actionType === 1 && !item.is_delete) && (
                     <MessageRecieved
                     chattingTab={"Artist"}
                      item={item}
                      handleDownload={handleDownload}
                         deleteChat={deleteChat}
                       handleDropdownToggle={handleDropdownToggle}
                       activeDropdown={activeDropdown}
                       index={index}
                     truncateStringWithExtension={truncateStringWithExtension}
                     getFileTypeImage={getFileTypeImage}
                    loaderSvg={loaderSvg}
                    ErrorImg={ErrorImg}
                   />
                   )}
                    {(item.actionType === 0 && !item.is_delete) && (
                     <NewMessageSender
                      item={item}
                      index={0}
                      mainIndex={index}
                      handleDownload={handleDownload}
                      getFileTypeImage={getFileTypeImage}
                      deleteuserchat={deleteChat}
                      handleDropdownToggle={handleDropdownToggle}
                      activeDropdown={null}
                      loaderSvg={loaderSvg}
                       ErrorImg={ErrorImg}
                       onDelete={deleteChat}
                   />
                    )}
                      {item.actionType === 3 && (
                          <div className="">
                      <p className="text-center group-add uni-mb-8">
                      <span className="uni-p-8"> {item.message}</span>
                        </p>
                        </div>
                      )}
                    </>
                  ))}
                </div>
                <div className="newmessage-send-message uni-mb-16">
                <DropworksMessageInput
                    id="file-type-next"
                    userData={{name:"group_id",value:trackId}}
                    placeholder="Type Search"
                    message={handleSendMessage}
                    onFileSizeError={(e:boolean)=> updateErrorMessage(e)}
                    onFileUploaded={(data:any)=>onFileuploaded(data)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div> 
  </div>
  </ModalWrapper>
  )
}

export default view;