import { useEffect, useState } from "react";
import "./styles.scss";
import { InviteFriends } from "../../../common/invite-friends";
import Tabs from "../../../common/tabs";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const view = () => {
  const invite = useSelector((state: RootState) => state.upload.receviedInvite);
  const sentInvite = useSelector((state: RootState) => state.upload.sentInvite);
  const inviteTab = useSelector((state: RootState) => state.upload.inviteTab);
  const [recivedearr, setRecivedarr] = useState([]);
  const [sentarr, setSentarr] = useState([]);
  const [selectedArr, setSelectedArr] = useState<any>(recivedearr);
  const [status, setStatus] = useState("");
  const [changeTabValue, setChangeTableValue] = useState<number>(0);

  useEffect(() => {
    if (invite && invite) {
      const updatedArray = invite?.map((data: any) => ({
        img: data.sent_user_id?.profile_image?.[0]?.src || "",
        name: data.sent_user_id.firstname + " " + data.sent_user_id.lastname,
        subname:
          data?.sent_user_id?.username ||
          data?.sent_user_id?.display_name ||
          "",
        vocalist: data.role,
        percentage: data?.percentage_offered || "",
        active:
          data.acceept_status === "rejected"
            ? "Declined"
            : data.acceept_status === "pending"
            ? "Active"
            : data.acceept_status === "accepted"
            ? "Accepted"
            : "Expired",
        id:
          data.acceept_status === "rejected"
            ? "DeclinedInviteReceived"
            : data.acceept_status === "pending"
            ? "ActiveInviteReceived"
            : data.acceept_status === "accepted"
            ? "AcceptedInviteReceived"
            : "ExpiredInviteReceived",
      }));
      setSelectedArr(updatedArray);
      setRecivedarr(updatedArray);
    }
  }, [invite]);
  useEffect(() => {
    if (sentInvite && sentInvite) {
      const updatedArray = sentInvite.map((data: any, index: number) => ({
        img: data.receiver_user_id?.profile_image?.[0]?.src || "",
        name: data.receiver_name,
        subname:
          data.receiver_user_id.username || data.receiver_user_id.display_name,
        vocalist: data.role,
        percentage: data.percentage_offered,
        active:
          data.acceept_status === "rejected"
            ? "Declined"
            : data.acceept_status === "pending"
            ? "Active"
            : data.acceept_status === "accepted"
            ? "Accepted"
            : "Expired",
        id:
          data.acceept_status === "rejected"
            ? "DeclinedInviteSent"
            : data.acceept_status === "pending"
            ? "ActiveInviteSent"
            : data.acceept_status === "accepted"
            ? "AcceptedInviteSent"
            : "ExpiredInviteSent",
      }));
      setSentarr(updatedArray);
    }
  }, [sentInvite]);

  useEffect(() => {
    if (inviteTab == 1) {
      setTabValue(1);
      setChangeTableValue(1);
      setSelectedArr(sentarr);
    } else {
      setTabValue(0);
      setChangeTableValue(0);
      setSelectedArr(recivedearr);
    }
  }, [sentInvite, recivedearr]);

  const handleStatusClick = (statusType: string) => {
    if (status === statusType) {
      setSelectedArr(!tabsValue ? recivedearr : sentarr);
      setStatus("");
    } else {
      setStatus(statusType);
      let filteredArr: any;

      if (statusType === "Active") {
        filteredArr = (!tabsValue ? recivedearr : sentarr).filter(
          (item: any) => item.active === "Active"
        );
      } else if (statusType === "Expired") {
        filteredArr = (!tabsValue ? recivedearr : sentarr).filter((item: any) =>
          ["Expired", "Accepted", "Declined"].includes(item.active)
        );
      }
      setSelectedArr(filteredArr);
    }
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
    setStatus("");
    setSelectedArr(value ? sentarr : recivedearr);
    setChangeTableValue(value);
  };

  return (
    <div className="invite  container container-xl container-xxl min-height-cover">
      <Heading2 className="invite-heading uni-mb-32">Invites</Heading2>

      <Tabs
        tabsTitle={["Received", "Sent"]}
        id="2"
        className="uni-mb-40"
        changeTabValue={changeTabValue}
        checkTabState={checkTabState}
      />

      <div className="d-flex invite-acexp flex-wrap uni-mb-32">
        <button
          className={`invite-acexp-btn ${status === "Active" ? "active" : ""}`}
          onClick={() => handleStatusClick("Active")}
        >
          Active
        </button>
        <button
          className={`invite-acexp-btn ${status === "Expired" ? "active" : ""}`}
          onClick={() => handleStatusClick("Expired")}
        >
          Expired
        </button>
      </div>
      <div className="invite-usericon">
        <InviteFriends arr={selectedArr} />
      </div>
    </div>
  );
};
