import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useSelector } from "react-redux";



export const BlockedUser1:FC<any>=()=>{

    const users = useSelector((state: any) => state.user.blockedUser);
    const [blockedUsers, setBlockedUsers] = useState<any>([]);

    useEffect(()=>{
        setBlockedUsers(users.map((ele:any)=>({id:ele.block_user_Id._id,name:ele.block_user_Id.firstname,blocked:true})));
    },[users])

    const openModal=()=>{
        setBlockedUsers([])
    }

    return view(blockedUsers,setBlockedUsers,openModal);
}
export default BlockedUser1