import { FC, useEffect, useState } from "react";
import view from "./view";




export const TrackDetailsPopUp: FC<any> = () => {


   
    return view();
}
export default TrackDetailsPopUp


