import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import { ButtonComponent } from "../../logo-header/view";
import { FC } from "react";
import { modal3Type } from "../popup-modal";
import Loader from "../../loader/view";
import { SelectArrowDropdownImage } from "../../../../../common/selection-dropdown-image";
import CountriesArray from "../../../../../i18n/countryArrayForSelection";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";

const view: FC<modal3Type> = ({
  phNumber,
  updateNumber,
  OpenPopup3,
  message,
  loading = false,
  modal3,
  numberValidation,
  changeCountry,
  closeModalRef
}) => {
  return (
    <div
      className="modal fade"
      id="SecureYourAccount"
      aria-hidden="true"
      aria-labelledby="SecureYourAccount"
      tabIndex={-1}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <CloseModalButton ref={closeModalRef} className="d-none"></CloseModalButton>
          <div className="modal-cross-icon">
            <ButtonComponent id="acc-secured">
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </ButtonComponent>
          </div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Protect your account</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mt-16 ctm-txt">
              Add a valid cell phone number
            </TextfieldLG>
            <TextfieldXSM className="ctm-txt2 uni-mb-56 mbl-mb-8">
              (Optional)
            </TextfieldXSM>
            <p className=" d-flex align-items-center">
              <SelectArrowDropdownImage
                options={CountriesArray}
                placeHolderValue="Select a country"
                className="mbl-mt-8"
                onChangeValue={changeCountry}
                defaultValueCountry={{ value: "Australia", label: "Australia", image: "https://flagpedia.net/data/flags/h80/au.png" }}
                isSearch={true}
              ></SelectArrowDropdownImage>

              <DropworksInput
                className="my-0 mbl-mt-0"
                placeholder="Phone number"
                maxLength={numberValidation.maxPhoneLength}
                value={phNumber || ""}
                onInput={(e: any) => {
                  let check = e.target.value.replace(/[^0-9]/g, "");
                  if (check.charAt(0) === "0") {
                    check = check.slice(1); // Remove the first character if it's '0'
                  }
                  updateNumber(check);
                  // Remove non-numeric characters
                }}
              />
            </p>
          </div>
          {phNumber &&
            (String(phNumber).length <
              Number(numberValidation.minPhoneLength) ||
              String(phNumber).length >
                Number(numberValidation.maxPhoneLength)) && (
              <p className="error-red">
                Phone number should have a minimum length of{" "}
                {numberValidation.minPhoneLength}{" "}
                {/* {Number(numberValidation.maxPhoneLength) > Number(numberValidation.minPhoneLength)
        ? `  */}
                and a maximum length of
                {/* $ */} {numberValidation.maxPhoneLength}.
                {/*  ` : ""
                 */}
              </p>
            )}
          <div className="uni-mt-40 modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            {loading ? (
              <Loader />
            ) : (
              <DropworksSmallButton
                disabled={
                  !phNumber ||
                  String(phNumber)?.length < numberValidation.minPhoneLength ||
                  String(phNumber)?.length > numberValidation.maxPhoneLength
                }
                className={
                  !phNumber ||
                  String(phNumber)?.length < numberValidation.minPhoneLength ||
                  String(phNumber)?.length > numberValidation.maxPhoneLength
                    ? "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"
                    : "btn-wid  modal-btns m-0"
                }
                onClick={OpenPopup3}
              >
                Next
              </DropworksSmallButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
