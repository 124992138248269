import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import {
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import FailedIconGray from "../../../../../assets/steps/Failed-icon-gray1.svg";
import confirmedIconGray from "../../../../../assets/steps/confirmed-icon-gray.svg";
import { HrLineMD } from "../../../../../common/hr-line";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  clearWalletDetails,
  getWalletDetails,
  getWalletDropStocks,
  getWalletTransactions,
} from "../../../../../redux/reducer/walletSlice";
import { AppDispatch } from "../../../../../redux/store";
import { formatNumberWithCommas } from "../../../utils/localStorage";

export const view: FC<WalletPin> = ({
  errors,
  loading,
  closeModalRef,
  openModal,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { amount, receiverAddress, status, fiatSymbol, fiatPrice } =
    useSelector((state: RootState) => state.wallet.walletData);
  // const fetchWalletDetails = () => {
  //   dispatch(getWalletDetails());
  //   dispatch(getWalletDropStocks());
  //   dispatch(getWalletTransactions());
  // };

  return (
    <ModalWrapper modalId="WalletTransaction" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="WalletTransaction"
        aria-hidden="true"
        aria-labelledby="WalletTransaction"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header d-flex justify-content-center align-items-center">
              <div>
                {status === "success" && (
                  <div className="uni-mb-56">
                    <img src={confirmedIconGray} />
                  </div>
                )}
                {status === "waiting" && (
                  <div className="uni-mb-56">
                    <Loader className="" isWhiteLoader={true} />
                  </div>
                )}

                {status === "failed" && (
                  <div className="uni-mb-56">
                    <img src={FailedIconGray} />
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body uni-mb-56">
              {status === "success" && (
                <Heading3 className="modal-title c-text uni-mb-56 uni-fw-700 ">
                  Transaction Confirmed
                </Heading3>
              )}
              {status === "waiting" && (
                <Heading3 className="modal-title c-text uni-mb-56 uni-fw-700">
                  Transaction Processing
                </Heading3>
              )}

              {status === "failed" && (
                <Heading3 className="modal-title c-text uni-mb-56 uni-fw-700">
                  Transaction Failed
                </Heading3>
              )}

              {status === "success" && (
                <TextfieldXSM className="gray2 uni-mb-56">
                  Funds should arrive at the destination account in the next few
                  minutes depending on network congestion.
                </TextfieldXSM>
              )}

              {status === "failed" && (
                <TextfieldXSM className="gray2 uni-mb-56">
                  Unable to perform the transaction due to network
                  congestion.Try again later.
                </TextfieldXSM>
              )}

              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between w-100 uni-mb-16">
                  <TextfieldSM className="gray3 m-0">Address</TextfieldSM>
                  <TextfieldSM className="m-0 text-white">
                    {receiverAddress}
                  </TextfieldSM>
                </div>

                <div className="d-flex justify-content-between w-100 uni-mt-16 uni-mb-16">
                  <TextfieldSM className="gray3  m-0">Network</TextfieldSM>
                  <TextfieldSM className="m-0 text-white">
                    Polygon POS
                  </TextfieldSM>
                </div>

                <div className="d-flex justify-content-between w-100 uni-mt-16 uni-mb-16">
                  <TextfieldSM className="gray3  m-0">Amount</TextfieldSM>
                  <TextfieldSM className="m-0 text-white">{formatNumberWithCommas(Number(amount))}</TextfieldSM>
                </div>

                <div className="d-flex justify-content-between w-100 uni-mt-16 uni-mb-32">
                  <TextfieldSM className="gray3  m-0">Network Fee</TextfieldSM>
                  <TextfieldSM className="m-0 text-white">
                    {" "}
                    {fiatPrice} {fiatSymbol}
                  </TextfieldSM>
                </div>
                <HrLineMD className="gray2" />
              </div>
            </div>
            <div className="modal-footer social-mobile-modal-footer   ps-0 pt-0 pb-0 uni-pr-72">
              {loading ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  <CloseModalButton>
                    <DropworksSmallButton
                      className="btn-wid m-0 my-0 social-link-modal-btns"
                      // onClick={fetchWalletDetails}
                    >
                      Confirm
                    </DropworksSmallButton>
                  </CloseModalButton>
                </>
              )}
            </div>

            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
