import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { AiOutlineRight } from "react-icons/ai";
import { CloseModalButton } from "../../logo-header/view";
import {
  Heading3,
  Heading4,
} from "../../../../../common/custom-field/dropstock-heading";

const view = () => {
  return (
    <div
      className="modal fade"
      id="ThankYouModal"
      aria-hidden="true"
      aria-labelledby="ThankYouModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
            <Heading4 className="modal-title-fs-32">
              Thank you for your report!
            </Heading4>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 ">
            <p className="d-flex justify-content-between uni-p-16 popup-space-mobile uni-mb-56">
              <TextfieldSM className="ctm-txt2 font-col-wh mx-0 my-0">
                Learn more about our{" "}
                <span className="change-color">community guidelines</span>
              </TextfieldSM>

              <AiOutlineRight className="arrow-icon" />
            </p>
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton className="buttoncomponent-mobile-modle">
              <DropworksSmallButton className="btn-wid-success social-link-modal-btns m-0">
                Confirm
              </DropworksSmallButton>
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
