import { FC, useEffect, useRef, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";
import { registerThirdPopup } from "../../../utils/apiServices";
import { RootState } from "../../../../../redux/store";
import { setMobile, setMobileCountryCode, setModal3, setStartTimer } from "../../../../../redux/reducer/modal";
import Countries from "../../../../../i18n/country";
import { openPopup } from "../../logo-header/view";

export const SecureYourAccount: FC<any> = () => {
  const [phNumber, setPhNumber] = useState<number>();
  const [message, setMessage] = useState<string>();
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  
  const updateNumber = (value: number) => {
    setPhNumber(value);
  };
  const { modal3 } = useSelector((state: RootState) => state.modal);

  const countryData = Countries;

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const OpenPopup3 = async () => {
    setLoading(true);
    const data = { phone: phNumber, countryCode: numberValidation.phonePrefix }
    const response: any = await registerThirdPopup(data);
    if (response.success) {
      phNumber && dispatch(setMobile(phNumber));
      if (closeModalRef.current) {
        closeModalRef.current.click();
      }
      dispatch(setMobileCountryCode(numberValidation.phonePrefix));
      setLoading(false);
      dispatch(setStartTimer(true));
      openPopup("Modal4",dispatch)

    } else {
      setLoading(false);
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

  const [numberValidation, setNumberValidation] = useState({
      name: "Australia",
      phonePrefix: "+61",
      minPhoneLength: 9,
      maxPhoneLength: 9,
      flag: "🇦🇺",
      flagUrl: "https://flagpedia.net/data/flags/h80/au.png"
    })
  const changeCountry = (data: any) => {
    let countryDeails = countryData.filter((ele: any) => ele.name == data);
    setNumberValidation(countryDeails[0]);
  }
  return view({ phNumber, modal3, updateNumber, loading, OpenPopup3, message, numberValidation, changeCountry,closeModalRef  });
};
export default SecureYourAccount;
