import { FC, useRef, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { UserLable } from "../user-labels";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/effect-fade";
import React from "react";
import { TextfieldMD } from "../custom-field/dropstock-text";

export const view: FC<any> = ({
  navigateTo,
  image,
  arr,
  className,
  className2,
  page,
  showSwiper = false,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const prevButtonRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);

  const submitData = async (data: any, isrerelease: any) => {
    if (page == "library") {
      if (isrerelease) {
        navigate(`/track-project-rerelease/${data}`);
      } else {
        navigate(`/track-project-overview/${data}`);
      }
    } else {
      navigate(`/project-page/${data}`);
    }
  };

  const swiperRef = useRef<any>(null);

  return (
    <>
       {showSwiper && arr.length > 0 && (
        <>
          <div className="swiper-button-prev" ref={prevButtonRef}></div>
          <div className="swiper-button-next" ref={nextButtonRef}></div>
        </>
      )}
      <div
        className={`row ${className}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!showSwiper ? (
          arr.length > 0 ? (
            <>
              {arr.map((data: any, index: any) => (
                <div
                  className={`col-6 col-sm-6 col-md-3 col-lg-2 project-icon ${className2}`}
                  onClick={() => submitData(data.slugName, data.isRerelease)}
                >
                  <div className="project-icon-box">
                    {data.image ? (
                      <img
                        className="project-icon-box"
                        src={`${process.env.REACT_APP_IMGURL}/${data.image}`}
                      />
                    ) : (
                      ""
                    )}
                    {data.showLabel && !Boolean(data.is_approved) && (
                      <UserLable className="project-icon-label label-margin mobile-left-0">
                        {data?.is_approved_by_admin==1?"Approved":"Under Review"}
                      
                      </UserLable>
                    )}
                  </div>
                  <div className="project-icon-heading">
                    {data.heading} &nbsp;
                  </div>
                  <div className="project-icon-subheading link-btm">
                    {data?.collaborators && data?.collaborators?.length &&
                          data?.collaborators.map((data: any, index: number, arr:any) => (
                            <React.Fragment key={data.slug_name}>
                              <TextfieldMD
                                className="underline-opening mb-0 mt-0 m-0"
                                onClick={navigateTo(
                                  `/user-profile/${data.slug_name}`
                                )}
                              >
                                {data.receiver_name}
                              </TextfieldMD>
                              {index < arr?.length - 1 && ", "}
                            </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span className="no-data-found">
            No projects available</span>
          )
        ) : null}
        {showSwiper ? (
          arr.length > 0 ? (
            <Swiper
              modules={[Autoplay, Navigation]}
              autoplay={{ delay: 10000, disableOnInteraction: false }}
              spaceBetween={0}
              navigation={{
                prevEl: prevButtonRef.current,
                nextEl: nextButtonRef.current,
              }}
              onBeforeInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevButtonRef.current;
                swiper.params.navigation.nextEl = nextButtonRef.current;
              }}
              slidesPerView={2}
              effect="fade"
              speed={2000}
              loop={true}
              breakpoints={{
                640: { slidesPerView: 2 },
                768: { slidesPerView: 5 },
                1024: { slidesPerView: 6 },
                1440: { slidesPerView: 6 },
              }}
              onSwiper={(swiper: any) => {
                swiperRef.current = swiper;
              }}
              onAutoplayStart={(swiper: any) => console.log("Autoplay started")}
              onAutoplayStop={(swiper: any) => console.log("Autoplay stopped")}
              className="swiper-container"
            >
              {arr.map((data: any, index: any) => (
                <SwiperSlide
                  key={index}
                  onMouseEnter={() => swiperRef.current?.autoplay?.stop()}
                  onMouseLeave={() => swiperRef.current?.autoplay?.start()}
                  className={`col-6 col-sm-6 col-md-3 col-lg-2 project-icon ${className2}`}
                  onClick={() => submitData(data.slugName, data.isRerelease)}
                >
                  <div className="project-icon-box">
                    {data.image ? (
                      <img
                        className="project-icon-box"
                        src={`${process.env.REACT_APP_IMGURL}/${data.image}`}
                        alt={data.heading}
                      />
                    ) : (
                      ""
                    )}
                    {data.showLabel && !Boolean(data.is_approved) && (
                      <UserLable className="project-icon-label  label-margin mobile-left-0">
                        {data?.is_approved_by_admin==1?"Approved ":"Under Review"}
                      </UserLable>
                    )}
                  </div>
                  <div className="project-icon-heading">
                    {data.heading} &nbsp;
                  </div>
                  <div className="project-icon-subheading link-btm">
                    {data?.collaborators && data?.collaborators?.length &&
                          data?.collaborators.map((data: any, index: number,arr:any) => (
                            <React.Fragment key={data.slug_name}>
                              <TextfieldMD
                                className="underline-opening mb-0 mt-0 m-0"
                                onClick={navigateTo(
                                  `/user-profile/${data.slug_name}`
                                )}
                              >
                                {data.receiver_name}
                              </TextfieldMD>
                              {(index < (arr?.length - 1)) && ", "}
                            </React.Fragment>
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <span className="no-data-found">
            No projects available</span>
          )
        ) : null}
      </div>
    </>
  );
};
