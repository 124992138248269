import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { UserIcon } from "../../../common/user-icon";
import defaultImage from "../../../assets/steps/Group 18414.svg";
import { TrackIcon } from "../../../common/track-icon";
import { BeatesIcon } from "../../../common/beats-icon";
import { ProjectIcon } from "../../../common/project-icon";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import backBtn from "../../../assets/img/icons/backBtn.svg";
import { viewType } from "./modal";
import { UserLable } from "../../../common/user-labels";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../common/custom-field/dropworks-button";
import {
  ArrowDropdownGray4,
  MeatballsMenuDropdown,
} from "../../../common/icons";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import { BlueTik } from "../../../common/all-icons";
import { TextfieldXSM } from "../../../common/custom-field/dropstock-text";
import { MoreMobile } from "../../../common/moreMobile";
import { useNavigate } from "react-router-dom";
import { setInviteModalUserID } from "../../../redux/reducer/uploadSlice";
import Tabs from "../../../common/tabs";
import TabsComponent from "../../../common/tabs-component/view";
import { setuserListData } from "../../../redux/reducer/messageChatSlice";

export const view: FC<viewType> = ({
  navigateTo,
  userData,
  beatsarr,
  recentReleasesarr,
  trackarr,
  UserId,
  isFollowing,
  isHovered,
  setIsHovered,
  handleClick,
  dispatch,
}) => {
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    if (isFollowing) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [linksArray, setLinkAyyay] = useState<any>([]);
  useEffect(() => {
    if (userData?.social_links?.length > 0) {
      setLinkAyyay(
        userData?.social_links.map((data: any) => ({
          name: data.name,
          link: data.link,
        }))
      );
    }
  }, [userData]);

  const [button, setButton] = useState("user-profile");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  return (
    <>
      <div className="user-profile container container-xxl min-height-cover">
        <div
          className={`user-profile-body ${
            button === "tracks" || button === "beats"
              ? "user-profile-body-h100"
              : ""
          }`}
        >
          <div>
            <img
              src={
                `${process.env.REACT_APP_IMGURL}/${userData?.profile_image?.[0]?.src}`
              }
              onError={(e: any) => (e.target.src = defaultImage)}
              className="user-profile-image"
              alt="user-image"
            />
          </div>
          <div className="user-profile-content">
            <div className="w-100">
              <div className="user-profile-details">
                <Heading3 className="uni-mr-8 mb-0">
                  {userData?.firstname} {userData?.lastname}
                </Heading3>
                {Boolean(userData?.is_bluetick) && <BlueTik />}
              </div>
              {userData?.username && (
                <div className="user-profile-id">@{userData?.username}</div>
              )}
            </div>
            {button === "user-profile" ? (
              <>
                <div className="d-flex flex-wrap gap-3 mt-2 all-lables">
                  {userData?.role?.map((role: any) => (
                    <UserLable className="user-label-style user-label-profile">
                      {role}
                    </UserLable>
                  ))}
                  {userData?.DW_preferences?.length > 0
                    ? userData?.DW_preferences.map((DWPreferences: any) => (
                        <UserLable className="user-label-style user-label-profile">
                          {DWPreferences}
                        </UserLable>
                      ))
                    : ""}
                </div>

                
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {button === "user-profile" ? (
              <>
                <div className="d-flex flex-wrap gap-3 mt-2 all-lables-mobile">
                  {userData?.role?.map((role: any) => (
                    <UserLable className="user-label-style user-label-profile">
                      {role}
                    </UserLable>
                  ))}
                  {userData?.DW_preferences?.length > 0
                    ? userData?.DW_preferences.map((DWPreferences: any) => (
                        <UserLable className="user-label-style user-label-profile">
                          {DWPreferences}
                        </UserLable>
                      ))
                    : ""}
                </div>

                
              </>
            ) : (
              ""
            )}

        {button === "user-profile" ? (
          <>
            <div className="d-flex gap-3  uni-mt-32 user-profile-bottons mobile-mt-0">
              <DropworksDisableButton
                className={`mobile-order follow-button user-profiles-follow-buttons ${
                  isFollowing ? "following" : "follow"
                } ${isHovered && isFollowing ? "hover" : ""}`}
                onClick={() => handleClick(userData?._id)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {" "}
                {isFollowing
                  ? isHovered
                    ? "Unfollow"
                    : "Following"
                  : "Follow"}
              </DropworksDisableButton>
              {/* please add dropdown */}
              {linksArray?.length > 0 ? (
                <DropworksSmallButton
                  className="user-profiles-select-buttons uni-bg-disable position-relative"
                  data-bs-toggle="dropdown"
                >
                  <TextfieldXSM className="m-0">Socials</TextfieldXSM>
                  <ArrowDropdownGray4
                    pagename="user-profile"
                    className="px-0 "
                    arr={linksArray}
                  />
                </DropworksSmallButton>
              ) : (
                ""
              )}
              {userData?.receive_messages_from_anyone ? (
                <DropworksSmallButton
                  className="user-profiles-Message-buttons uni-bg-disable mobile-order"
                  onClick={() => {
                    navigate("/message-chat"),
                      dispatch(
                        setuserListData({
                          userName: userData.display_name,
                          userImg: userData.profile_image?.[0]?.src,
                          alluserListId: userData._id,
                          tabsValue: 0,
                          chattingTab: "user",
                          username:userData.username
                        })
                      );
                  }}
                >
                  Message
                </DropworksSmallButton>
              ) : (
                ""
              )}
              {/* {linksArray?.length>0?
            <DropworksSmallButton className="user-profiles-select-buttons uni-bg-disable mobile-arrow-track"    data-bs-toggle="dropdown">
            <TextfieldXSM className="m-0">Socials</TextfieldXSM>
            <ArrowDropdownGray4 pagename="user-profile"
              className="px-0 "
               arr={linksArray}
            />
          </DropworksSmallButton>
          :""} */}

              {/* <span className="right-margin">  More</span> */}
              <MoreMobile
                className="px-0 mobile-p-0"
                arr={["Block", "Report"]}
              />

              {userData?.receive_offer_from_anyone ? (
                <ButtonComponent
                  id="InviteModal"
                  className="invite-disabled-btn-mbl"
                >
                  {" "}
                  <DropworksSmallButton
                    onClick={() =>
                      dispatch(setInviteModalUserID(userData?._id))
                    }
                    className="invite-btn-w user-profiles-invite-buttons  invite-btn-disable-mbl"
                  >
                    Invite
                  </DropworksSmallButton>
                </ButtonComponent>
              ) : (
                ""
              )}
              <span className="convert-more d-flex align-items-center">
                <MeatballsMenuDropdown
                  arr={["Block", "Report"]}
                  height={"9px"}
                ></MeatballsMenuDropdown>
              </span>
            </div>
          </>
        ) : (
          ""
        )}
        {userData.public_profile ? (
          <>
            <div className="user-profile-button flex-wrap mb-4">
              {button === "tracks" || button === "beats" ? (
                <>
                  <div className="w-100 d-flex">
                    <button
                      className={`user-profile-button-backBtn`}
                      onClick={() => handleButtonClick("user-profile")}
                    >
                      <img
                        className="user-profile-button-image"
                        src={backBtn}
                        alt="backBtn"
                      />
                    </button>
                    <Tabs
                      className="uni-mb-56"
                      tabsTitle={
                        userData.display_releases && userData.display_beats
                          ? ["Tracks", "Beats"]
                          : userData.display_releases
                          ? ["Tracks"]
                          : userData.display_beats
                          ? ["Beats"]
                          : []
                      }
                      id="20"
                      checkTabState={checkTabState}
                    />
                  </div>
                  <TabsComponent
                    tabsValue={tabsValue}
                    components={
                      userData.display_releases && userData.display_beats
                        ? [
                            <>
                              <div className="library-usericon">
                                <TrackIcon
                                  arr={trackarr}
                                  className="all-icon-mobile uni-pl-24 full-width-child"
                                  className2="track-icon-mobile-view"
                                />
                              </div>
                            </>,
                            <>
                              <div className="library-usericon">
                                <BeatesIcon
                                  arr={beatsarr}
                                  className="all-icon-mobile uni-pl-24 full-width-child"
                                  className2="track-icon-mobile-view"
                                />
                              </div>
                            </>,
                          ]
                        : userData.display_releases
                        ? [
                            <>
                              <div className="library-usericon">
                                <TrackIcon
                                  arr={trackarr}
                                  className="all-icon-mobile uni-pl-24 full-width-child"
                                  className2="track-icon-mobile-view"
                                />
                              </div>
                            </>,
                          ]
                        : userData.display_beats
                        ? [
                            <>
                              <div className="library-usericon">
                                <BeatesIcon
                                  arr={beatsarr}
                                  className="all-icon-mobile uni-pl-24 full-width-child"
                                  className2="track-icon-mobile-view"
                                />
                              </div>
                            </>,
                          ]
                        : []
                    }
                  />
                </>
              ) : (
                ""
              )}
            </div>
            {userData?.biography && button === "user-profile" ? (
              <>
                <div className="user-profile-subheading uni-mt-56 mobile-mt-16 ">
                  Biography
                </div>
                <div className="user-profile-para uni-mb-56 mobile-mb-16 bio-para-text">
                  {userData?.biography}{" "}
                </div>
              </>
            ) : null}
            {button === "user-profile" ? (
              <>
                {userData.display_releases ? (
                  <>
                    <div
                      className="user-profile-subheading-link"
                      onClick={() => handleButtonClick("tracks")}
                    >
                      Top Tracks
                    </div>
                    <div className="user-profile-usericon">
                      <TrackIcon
                        arr={trackarr}
                        className="all-icon-mobile uni-pl-24 full-width-child"
                        className2="track-icon-mobile-view"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}
            {button === "user-profile" ? (
              <>
                {userData.display_releases ? (
                  <>
                    <div
                      className="user-profile-subheading-link"
                      onClick={() => handleButtonClick("tracks")}
                    >
                      Recent Releases
                    </div>
                    <div className="user-profile-usericon">
                      <ProjectIcon
                        arr={recentReleasesarr}
                        className="all-icon-mobile uni-pl-24 full-width-child"
                        className2="track-icon-mobile-view"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}

            {button === "user-profile" ? (
              <>
                {userData.display_beats ? (
                  <>
                    <div
                      className="user-profile-subheading-link"
                      onClick={() => handleButtonClick("beats")}
                    >
                      Beats
                    </div>
                    <div className="user-profile-usericon">
                      <BeatesIcon
                        arr={beatsarr}
                        className="all-icon-mobile uni-pl-24 full-width-child"
                        className2="beats-icon-mobile-view"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            {userData ? (
              <div className="user-profile-text">
                <p className="private-profile-text">
                  Oops! This profile is private
                </p>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </>
  );
};
