import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { SelectArrowDropdown } from '../../../../../common/selection-dropdown';
import { CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

export const view = ({ securityQuestiones, handleSubmit, selectedValues, setSelectedValues,openModal }: any) => {

  let options = securityQuestiones?.map((item: any) => ({
    value: item._id,
    label: item.question
  }))

  return (
    <ModalWrapper modalId="SecurityQuestion" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="SecurityQuestion"
      aria-hidden="true"
      aria-labelledby="SecurityQuestion"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
          <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16' /></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Security Questions</Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0">
            <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">Protect your account further</TextfieldLG>
            <TextfieldXSM className='uni-mb-56 ds-ctm-txt'>Never share the answers with anyone</TextfieldXSM>
            <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options.filter((ele: any) => ele.value !== selectedValues[1]?.questionId && ele.value !== selectedValues[2]?.questionId)}
                placeHolderValue={selectedValues[0]?.questionId.length>0?selectedValues[0]?.questionId : "Security Question"}
                value={selectedValues[0]?.questionId}
                onChangeValue={(e: any) => setSelectedValues((prev: any) => {
                  const newSelectedValues = [...prev];
                  newSelectedValues[0] = { ...newSelectedValues[0], questionId: e };
                  return newSelectedValues;
                })}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
            <DropworksInput placeholder='Answer' className='mt-0-mbl uni-mb-16'
              value={selectedValues[0]?.answer}
              onChange={(e: any) => 
                setSelectedValues((prev: any) => {
                const newSelectedValues = [...prev];
                newSelectedValues[0] = { ...newSelectedValues[0], answer: e.target.value };
                return newSelectedValues;
              })}></DropworksInput>
            <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options.filter((ele: any) => ele.value !== selectedValues[0]?.questionId && ele.value !== selectedValues[2]?.questionId)}
                placeHolderValue={selectedValues[2]?.questionId.length>0?selectedValues[1]?.questionId : "Security Question"}
                onChangeValue={(e: any) => setSelectedValues((prev: any) => {
                  const newSelectedValues = [...prev];
                  newSelectedValues[1] = { ...newSelectedValues[1], questionId: e };
                  return newSelectedValues;
                })}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
            <DropworksInput placeholder='Answer' className='mt-0-mbl uni-mb-16' 
              value={selectedValues[1]?.answer}
              onChange={(e: any) => 
                setSelectedValues((prev: any) => {
                const newSelectedValues = [...prev];
                newSelectedValues[1] = { ...newSelectedValues[1], answer: e.target.value };
                return newSelectedValues;
              })}></DropworksInput>
            <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options.filter((ele: any) => ele.value !== selectedValues[0]?.questionId && ele.value !== selectedValues[1]?.questionId)}
                placeHolderValue={selectedValues[2]?.questionId.length>0?selectedValues[2]?.questionId : "Security Question"}
                onChangeValue={(e: any) => setSelectedValues((prev: any) => {
                  const newSelectedValues = [...prev];
                  newSelectedValues[2] = { ...newSelectedValues[2], questionId: e };
                  return newSelectedValues;
                })}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
            <DropworksInput placeholder='Answer' className='uni-mb-56 mt-0-mbl' 
              value={selectedValues[2]?.answer}
              onChange={(e: any) => 
                setSelectedValues((prev: any) => {
                const newSelectedValues = [...prev];
                newSelectedValues[2] = { ...newSelectedValues[2], answer: e.target.value };
                return newSelectedValues;
              })}></DropworksInput>
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">

            <DropworksSmallButton 
            disabled={selectedValues.some((item: any) => item?.answer.trim().length === 0) || selectedValues.some((item: any) => item?.questionId.trim().length === 0)} 
            className={ selectedValues.some((item: any) => item?.answer.trim().length === 0) || selectedValues.some((item: any) => item?.questionId.trim().length === 0) 
              ? 
              "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"
              :
              "btn-wid social-link-modal-btns m-0"} onClick={() => handleSubmit()}>Save</DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  )
}


