import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import { ButtonComponent } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useState } from "react";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = (
  reportedProject: any,
  projectreportReason: string,
  setProjectReportReason: (reason: string) => void
) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const handleReasonSelect = (reason: string) => () => {
    setProjectReportReason(reason);
    setIsNextDisabled(false);
    if (reason === "Something else") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
      setCustomReason("");
    }
  };
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomReason(e.target.value);
    setIsNextDisabled(e.target.value.trim() === "");
  };
  const openModal = () => {
    setProjectReportReason("");
    setCustomReason("");
    setShowTextarea(false);
    setIsNextDisabled(true);
  };
  const handleNextClick = () => {
    if (showTextarea && customReason === "") {
      return;
    }
    const reasonToSubmit = showTextarea ? customReason : projectreportReason;
    reportedProject(reasonToSubmit);
  };
  return (
    <ModalWrapper modalId="SelectPinTypeModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="SelectPinTypeModal"
        aria-hidden="true"
        aria-labelledby="SelectPinTypeModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72 ">
              <Heading3 className="modal-title-fs-32">Change Pin</Heading3>
            </div>
            <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
              <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">
                Choose one of the following options to change your PIN
              </TextfieldLG>
              <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                Remember, never share your PIN with anyone
              </TextfieldXSM>
              <p
                className="d-flex justify-content-between align-items-center uni-p-16 p-lh uni-mb-8  popup-space-mobile"
                onClick={handleReasonSelect("Old Pin")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Old Pin
                </TextfieldSM>

                <DropworksRadioCheckbox
                  checked={"Old Pin" === projectreportReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 align-items-center  popup-space-mobile"
                onClick={handleReasonSelect("Phrase")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Phrase
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Phrase" === projectreportReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 align-items-center popup-space-mobile"
                onClick={handleReasonSelect("Private Key")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Private Key
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Private Key" === projectreportReason}
                />
              </p>
              {showTextarea && (
                <div className="uni-mt-16">
                  <textarea
                    className="form-control custom-textarea"
                    value={customReason}
                    onChange={handleTextareaChange}
                    placeholder="Specify your reason"
                    rows={3}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer ps-0 mbl-modal-footer pt-0 pb-0">
              <CloseModalButton  className="mbl-anchor-full-btn">
                <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">
                  Cancel
                </DropworksSmallButton>
              </CloseModalButton>
                <>
                  <ButtonComponent id={projectreportReason ==="Phrase"?"VerifyPhrase":projectreportReason === "Old Pin"?"VerifyPin": "VerifyPinPrivateKey"} className="mbl-anchor-full-btn">
                    <DropworksSmallButton
                      className="btn-wid mbl-full-width-btn m-0"
                      onClick={handleNextClick}
                      disabled={
                        customReason.length === 0 ?isNextDisabled  : true
                      }
                    >
                      Next
                    </DropworksSmallButton>
                  </ButtonComponent>
                </>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
