import {
  TextfieldLG,
  TextfieldSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { ButtonComponent } from "../../logo-header/view";
import { Heading3, Heading4 } from "../../../../../common/custom-field/dropstock-heading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useState } from "react";
import { setbeatModalId } from "../../../../../redux/reducer/reportSlice";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { ErrorImg } from "../../../utils/localStorage";

const view = () => {
  const beatData = useSelector((state: RootState) => state.report);

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const openModal = () => {
    setSelectedValue("");
  };
  const handleRadioChange = (id: string) => {
    setSelectedValue(id);
    setIsNextDisabled(false);
  };

  const beatsData =
    beatData?.data?.data?.map((item: any) => ({
      _id: item._id,
      beat_name: item.beat_name,
      description: item.description,
      profile_image: item?.art_work_image?.[0]?.src || "",
      default_artwork: item?.default_artwork,
    })) || [];

  return (
    <ModalWrapper modalId="SelectBeat" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="SelectBeat"
        aria-hidden="true"
        aria-labelledby="SelectBeat"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content modal-width-inviteModal">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" alt="Close" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-40 ps-0 pt-0 pb-0 uni-pr-72">
              <Heading4 className="modal-title">
                Select the beat you want to report
              </Heading4>
            </div>
            <div className="modal-body uni-mb-56 ps-0 pt-0 pb-0">
              <div className="content-height select-beat-content">
                {beatsData.length > 0 ? (
                  beatsData.map((item: any, index: any) => (
                    <div
                      key={index}
                      onClick={() => handleRadioChange(item._id)}
                      style={{ cursor: "pointer" }}
                      className="d-flex uni-p-16 content justify-content-between popup-space-mobile align-items-center"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <div>
                          {/* <img
                            className="select-box"
                            src={`${process.env.REACT_APP_IMGURL}/${item.profile_image}`}
                            alt="Profile"
                          /> */}
                          {/* <img
                            className="select-box"
                            src={`${process.env.REACT_APP_IMGURL}/${item.profile_image}`}
                            alt=""
                            onError={(e) => {
                              (
                                e.target as HTMLImageElement
                              ).src = `${process.env.REACT_APP_IMGURL}/${item.default_artwork}`;
                            }}
                          /> */}
                          <img
                            className="select-box"
                            src={`${process.env.REACT_APP_IMGURL}/${item.profile_image}`}
                            alt=""
                            onError={(e) => {
                              const imgElement = e.target as HTMLImageElement;

                              imgElement.onerror = () => {
                                imgElement.src = `${ErrorImg}`;
                              };
                              imgElement.src = `${process.env.REACT_APP_IMGURL}/${item.default_artwork}`;
                            }}
                          />
                        </div>
                        <div>
                          <TextfieldLG className="my-0 p-0 select-title">
                            {item.beat_name}
                          </TextfieldLG>
                          <TextfieldSM className="my-0 select-subtitle p-0">
                            {item.description}
                          </TextfieldSM>
                        </div>
                      </div>
                      <div>
                        <DropworksRadioCheckbox
                          checked={selectedValue === item._id}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-beats-message">
                    <TextfieldLG>No beats available</TextfieldLG>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer ps-0 mbl-modal-footer pt-0 pb-0">
              <ButtonComponent
                id="ReportModal1"
                className="mbl-anchor-full-btn"
              >
                <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 mbl-full-width-btn uni-mr-16">
                  Back
                </DropworksSmallButton>
              </ButtonComponent>
              <ButtonComponent
                id="BeatReportModal"
                className="mbl-anchor-full-btn"
              >
                <DropworksSmallButton
                  className="btn-wid mbl-full-width-btn m-0"
                  onClick={() => {
                    if (selectedValue) {
                      dispatch(setbeatModalId(selectedValue));
                    }
                  }}
                  disabled={isNextDisabled}
                >
                  Next
                </DropworksSmallButton>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
