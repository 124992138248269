import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { updateFevourite } from "../../app/shared/utils/apiServices";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLiked } from "../../redux/reducer/beatSlice";
import { RootState } from "../../redux/store";

export const view: FC<any> = ({liked, onClickHeart,isAudioBar=false,className}) => {

  return (
    <>

        <div
        className={`like_heart ${liked ? "_active" : ""} ${className}`}
        onClick={(e)=>onClickHeart(e)}
      >
        <svg
          width={isAudioBar?"24":"36"}
          height={isAudioBar?"24":"36"}
          viewBox="-2 0 70 53"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.7037 51.5037L55.001 27.9825C58.0221 24.8962 59.7086 20.746 59.6968 16.4272C59.6849 12.1084 57.9756 7.96748 54.9376 4.89786C53.4464 3.37676 51.6676 2.16738 49.7048 1.34006C47.7419 0.512744 45.6341 0.0840104 43.504 0.0788024C41.3739 0.0735943 39.264 0.492015 37.2971 1.30973C35.3302 2.12743 33.5456 3.32811 32.047 4.84189L29.8232 7.09178L27.5658 4.81577L27.1927 4.44639C24.1521 1.5398 20.0915 -0.0563994 15.8855 0.00152306C11.6795 0.0594455 7.6645 1.76685 4.70505 4.75608C-1.59314 11.1177 -1.56329 21.4567 4.76475 27.8482L28.1852 51.5037C29.1553 52.485 30.7336 52.485 31.7037 51.5037Z"
            stroke-width={isAudioBar?"3":"4"}
          />
        </svg>
      </div>
    </>
  );
};
