import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";

import "./styles.scss";
import { DropworksSwitch } from "../../../../../common/custom-field/dropworks-switch";
import iconImg from "../../../../../assets/steps/uploadicon.svg";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useDispatch, useSelector } from "react-redux";
import { CropperTool } from "../../../../../common/cropper-tool/view";
import { useState } from "react";
import { setUploadSettingImg, setUploadSettingImgAPI, setUploadSettingImgBanner, setUploadSettingImgBannerAPI, setUploadSettingImgProfile, setUploadSettingImgProfileAPI } from "../../../../../redux/reducer/uploadSlice";
import axios from "axios";
import { getUserData, updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = () => {
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [isCropperOpen, setCropperOpen] = useState(false);
  const [onUpload, setOnupload] = useState(false);
  const dispatch =useDispatch();
  const handleImageCrop = (croppedImage: any) => {
    setCroppedImage(croppedImage);
  };
  const submitData = async(data: { profile_image: any })=>{

    let response: any = await updateUser(data);
    if (response) {
        const result = await getUserData(); // Call your API function
        if (result.success && result.userData) { // Adjust based on your API response
            dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
        }
      } else {
        //console.log("failLLLLLLLLL");
      }
}
  const saveCall = async () => {
    dispatch(setUploadSettingImgProfile(croppedImage));
    try {
      const response = await fetch(croppedImage);
      const blob = await response.blob();

      const fileName = `${Date.now()}.jpg`;

      const file = new File([blob], fileName, { type: blob.type });

      const formData = new FormData();

      formData.append("uploadingFile", file);

      try {
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_URL}/upload-file`,
          formData
        );
        if (uploadResponse.data.success) {
          submitData({profile_image:uploadResponse.data.file_data});
          dispatch(setUploadSettingImgProfileAPI(uploadResponse.data.file_data));
       
        } else {
          console.error(
            "Else Error in response of uploading image",
            uploadResponse.data.message
          );
        }
      } catch (error) {
        console.error("Catch Error in response of uploading image", error);
      }
    } catch (error) {
      console.error("Error processing the cropped image:", error);
    }
    setOnupload(false);
    setCroppedImage(null);
  };
  const userData = useSelector((state: any) => state.user);
  const openModal=()=>{
    setCroppedImage(null), setOnupload(false)
  }
  return (
    <ModalWrapper modalId="UploadBanner" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="UploadBanner"
      aria-hidden="true"
      aria-labelledby="UploadBanner"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-mid-popup ">
        <div className="modal-content">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">
              Upload a Profile Picture
            </Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh  modal-second-head-font">
            Use a 1:1 high resolution picture
            </TextfieldLG>
            <TextfieldXSM className='uni-mb-56 ds-ctm-txt  modal-second-head-font'>(Note that the image must be at least 1500px diemension.)</TextfieldXSM>

            {/* <ButtonComponent id="ProfilePictureModal" > */}
            <div className="uploadBanner_uploadbox mx-0 my-0 position-relative">
              {onUpload ? (
                <>
                  {croppedImage == null ? (
                    <CropperTool
                      onClose={() => setCropperOpen(false)}
                      onImageCrop={handleImageCrop}
                      iconImg={iconImg}
                      uploadText="Upload"
                      id="xyz"
                    />
                  ) : (
                    <img
                      src={croppedImage}
                      className="cursor-pointer w-100 h-100"
                      style={{borderRadius:"10px"}}
                    />
                  )}
                </>
              ) : (
                <img
                  src={userData?.profileImg}
                  className=" w-100 h-100"
                  style={{borderRadius:"10px"}}
                />
              )}

              {/* <TextfieldSM className='text-content uploadBanner-upload-text mx-0 my-0'>Upload</TextfieldSM> */}
            </div>
            {/* </ButtonComponent> */}
          </div>
          <div className="modal-footer  mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            {!onUpload ? (
              <DropworksSmallButton
                className="btn-wid m-0 modal-btns"
                onClick={() => setOnupload(true)}
              >
                Upload
              </DropworksSmallButton>
            ) : (
              <>
                <CloseModalButton>
                  <DropworksSmallButton
                    className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns"
                    onClick={() => {setCroppedImage(null), setOnupload(false)}}
                  >
                    Cancel
                  </DropworksSmallButton>
                </CloseModalButton>

                {croppedImage == null ? (
                  <DropworksSmallButton
                    className="btn-wid m-0 disabled-btn social-link-modal-btns"
                    disabled
                  >
                    Save
                  </DropworksSmallButton>
                ) : (
                  <>
                    <CloseModalButton>
                      <DropworksSmallButton className="btn-wid m-0 social-link-modal-btns" onClick={saveCall}>
                        Save
                      </DropworksSmallButton>
                    </CloseModalButton>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  );
};

export default view;
