import { FC } from "react";
import "./styles.scss";
import { TextfieldLG } from "../../../common/custom-field/dropstock-text";
import { viewType } from "./modal";
import {
  Heading4,
  Heading6,
} from "../../../common/custom-field/dropstock-heading";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setSelectedPortfolio } from "../../../redux/reducer/uploadSlice";

export const view: FC<viewType> = ({ navigateTo }) => {
  const { portfolioDetails } = useSelector((state: RootState) => state.upload);
  const dispatch = useDispatch();
  const ErrorImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxqCQMRexiN9Iu6yVJQt3py5bPb13DRIqCXA&s";
  return (
    <div className="new-wallet container container-xxl min-height-cover mt-0">
      <div>
        <div className="overview">
          <div className="dropstocks-personal-portfolio">
            <div className="table-view">
              <div className="table-bg-parent-personal">
                <Heading4 className="dropstocks-personal-portfolio-head uni-mb-40">
                  Personal Portfolio
                </Heading4>
                <div className="table-wrapper-personal-portfolio">
                  <table className="table custom-table-personal-portfolio">
                    <thead className="text-dark align-items-center justify-content-between">
                      <tr className="table-row-dropstock uni-mb-16">
                        <th scope="col" className="track-column">
                          Track
                        </th>
                        <th scope="col">Owned</th>
                        <th scope="col text-center">DWC</th>
                        <th scope="col text-end">DWS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {portfolioDetails && portfolioDetails?.length > 0 ? (
                        portfolioDetails?.map((data: any, index: any) => {
                          return (
                            <tr className="text-light d-flex personal-portfolio-tr justify-content-between align-items-center uni-mt-16">
                              <td className="date-time track-column">
                                <div className="d-flex gap-3 align-items-center portfolio-mobile-space">
                                  <img
                                    src={
                                      data.art_work_image &&
                                      data.art_work_image.length > 0
                                        ? `${process.env.REACT_APP_IMGURL}/${data.art_work_image[0]?.src}`
                                        : ""
                                    }
                                    onError={(e) =>
                                      (e.currentTarget.src = ErrorImg)
                                    }
                                    className="personal-portfolio-select-box"
                                    // onClick={() =>
                                    //   navigateTo(
                                    //     `/track-page/${data?.slug_name}`
                                    //   )
                                    // }
                                  />

                                  {/* <span className="personal-portfolio-select-box"></span> */}
                                  <div className="d-flex">
                                    <Heading6
                                      className="my-0 p-0 personal-portfolio-mobile uni-fw-600"
                                      // onClick={navigateTo(
                                      //   `/track-page/${data?.slug_name}`
                                      // )}
                                    >
                                      {data?.track_name || "track name"}
                                    </Heading6>
                                  </div>
                                </div>
                              </td>
                              <td>{data?.dropStockCount}</td>

                              <td className="personal-portfolio-submit">
                                {data?.dwc ? (
                                  <ButtonComponent id="DropStocksSelectsubmit3">
                                    <span
                                      onClick={() =>
                                        dispatch(setSelectedPortfolio(data))
                                      }
                                    >
                                      Submit
                                    </span>
                                  </ButtonComponent>
                                ) : (
                                  "-"
                                )}{" "}
                              </td>
                              <td>
                                {data?.dws ? (
                                  <ButtonComponent id="DropStocksSelectsubmit3">
                                    Submit
                                  </ButtonComponent>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TextfieldLG className="uni-mt-8 text-center">
                          No portfolio available
                        </TextfieldLG>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
