import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletUsdcWithdraw } from "./modal";
import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { HrLineMD } from "../../../../../common/hr-line";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  gasFeesCalculate,
  isAddressValidate,
  setWalletDetails,
} from "../../../../../redux/reducer/walletSlice";

export const view = () => {
  const {
    fiatSymbol,
    fiatPrice,
    cryptoSymbol,
    cryptoPrice,
    netAmount,
  } = useSelector((state: RootState) => state.wallet.walletData);
  const { walletDetails, walletDropStocks } = useSelector(
    (state: RootState) => state.wallet
  );
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [netFeeDetails, setNetFeeDetails] = useState({
    fiatPrice1: "0.00",
    fiatSymbol1: "USDC",
    netAmount1: "0.00",
  });
  const [receiverAddress1, setReceiverAddress1] = useState("");
  const [amount1, setAmount1] = useState("");
  const closeModalRef = useRef<HTMLAnchorElement>(null);

  const { trackNFTId, pin, withdraw } = useSelector(
    (state: RootState) => state.wallet.walletData
  );

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const IsValid = async (receiverAddress: any) => {
    if (receiverAddress) {
      const payload = {
        receiverAddress,
      };
      dispatch(isAddressValidate(payload)).then((response: any) => {
        if (response.payload.success) {
          setReceiverAddress1(receiverAddress);
          console.log(Boolean(allInsertValue.amount), "value of insert amount");
          Boolean(allInsertValue.amount) &&
            getNetworkFees(allInsertValue.amount);
          setAllErrors((prev) => ({ ...prev, address: 3, addressMessage: "" }));
        } else {
          if (receiverAddress) {
            setAllErrors((prev) => ({
              ...prev,
              address: 2,
              addressMessage: response.payload.message,
            }));
          }
        }
      });
    }
  };

  const getNetworkFees = async (amount: any) => {
    setAmount1(amount);
    if (amount && receiverAddress1) {
      setLoading(true);
      const payload = {
        type: withdraw?.toLowerCase() || "",
        pin: pin || "",
        // trackNFTId: trackNFTId || "",
        amount,
        receiverAddress: receiverAddress1,
      };

      dispatch(gasFeesCalculate(payload)).then((response: any) => {
        if (response.payload.success) {
          if (response.payload.data) {
            const {
              fiatPrice,
              fiatSymbol,
              cryptoPrice,
              cryptoSymbol,
              netAmount,
            } = response.payload.data;
            dispatch(
              setWalletDetails({
                receiverAddress: receiverAddress1,
                amount: amount,
                fiatPrice: fiatPrice,
                fiatSymbol: fiatSymbol,
                cryptoPrice: cryptoPrice,
                cryptoSymbol: cryptoSymbol,
                type: "usdc",
                netAmount: netAmount,
              })
            );
            setNetFeeDetails({
              fiatPrice1: fiatPrice,
              fiatSymbol1: fiatSymbol,
              netAmount1: netAmount,
            });
            setIsAddressValid(false);
            setLoading(false);
            setAllErrors((prev) => ({ ...prev, amount: 3, amountMessage: "" }));
          }
        } else {
          setLoading(false);
          setIsAddressValid(true);
          if (amount) {
            setAllErrors((prev) => ({
              ...prev,
              amount: 2,
              amountMessage: response.payload.message,
            }));
          }
        }
      });
    }
  };
  // 0x894ce68cC77DC3e6ee4029091916687A4e69FE7f
  const openModal = () => {
    setAllErrors({
      address: 1,
      amount: 1,
      submit: 1,
      addressMessage: "",
      amountMessage: "",
    });
    setAllInsertvValue({
      address: "",
      amount: "",
    });
    setLoading(false);
  };

  const handleBlurIsValid = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value) {
      setAllErrors((prev) => ({ ...prev, address: 3 }));
      IsValid(value);
    } else {
      setAllErrors((prev) => ({
        ...prev,
        address: 2,
        addressMessage: "Enter address",
      }));
    }
  };

  const handleBlurGetNetworkFees = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.trim();
    if (value) {
      getNetworkFees(value);
      setAllErrors((prev) => ({ ...prev, amount: 3 }));
    }
  };
  const [allInsertValue, setAllInsertvValue] = useState({
    address: "",
    amount: "",
  });
  const [allErrors, setAllErrors] = useState({
    address: 1,
    amount: 1,
    submit: 1,
    addressMessage: "",
    amountMessage: "",
  });
  // console.log(allErrors, "all errorsssssssssssssssssssssssssss");
  return (
    <ModalWrapper modalId="WalletUsdcWithdrawModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="WalletUsdcWithdrawModal"
        aria-hidden="true"
        aria-labelledby="WalletUsdcWithdrawModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72">
              <Heading3 className="modal-title">USDC Withdraw</Heading3>
            </div>
            <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56 text-white">
              <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh uni-fw-700">
                Account withdraw of USD Coin
              </TextfieldLG>
              <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                Input the details below to perform the withdraw safely. Make
                sure your target wallet supports the specified network,
                otherwise you may not receive the funds.
              </TextfieldXSM>
              <div className="custom-pass-space">
                <TextfieldLG className="gray4 uni-fw-700">
                  Account Address
                </TextfieldLG>
                <DropworksInput
                  placeholder="Address"
                  disabled={loading}
                  value={allInsertValue.address}
                  onChange={(e) => {
                    setIsAddressValid(true);
                    setAllInsertvValue((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }));
                    if (e.target.value.length > 0) {
                      setAllErrors((prev) => ({
                        ...prev,
                        address: 3,
                        addressMessage: "",
                      }));
                    } else {
                      setAllErrors((prev) => ({
                        ...prev,
                        address: 2,
                        addressMessage: "Enter address",
                      }));
                    }
                  }}
                  className={`uni-mt-16 uni-mb-16 form-control login-cstm-pass   ${
                    allErrors.address === 1
                      ? ""
                      : allErrors.address === 2
                      ? "is-invalid b-red-2 mb-0"
                      : "is-valid  b-green-2"
                  }`}
                  onBlur={handleBlurIsValid}
                />
                {allErrors.address === 2 && (
                  <p className="invalid-feedback error-red uni-mb-16">
                    {allErrors.addressMessage}{" "}
                  </p>
                )}
                <TextfieldLG className="gray4 uni-fw-700">Network</TextfieldLG>
                <DropworksInput
                  disabled={true}
                  value={"Polygon POS"}
                  className={`uni-mt-16 uni-mb-16  login-cstm-pass`}
                />
                <TextfieldXSM className="ds-ctm-txt gray4 b-line r-text m-0 uni-mb-16">
                  <a
                    target="_blank"
                    className="gray4"
                    rel="noopener noreferrer"
                    href={process.env.REACT_APP_POLYGON_URL}
                  >
                    Contract Address
                  </a>
                </TextfieldXSM>
                <TextfieldLG className="gray4 uni-fw-700">Amount</TextfieldLG>
                <DropworksInput
                  placeholder="Amount"
                  disabled={loading}
                  value={allInsertValue.amount}
                  onChange={(e) => {
                    setAllInsertvValue((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }));
                    if (e.target.value.length > 0) {
                      setAllErrors((prev) => ({
                        ...prev,
                        amount: 3,
                        amountMessage: "",
                      }));
                    } else {
                      setIsAddressValid(true);
                      setAllErrors((prev) => ({
                        ...prev,
                        amount: 1,
                        amountMessage: "",
                      }));
                    }
                  }}
                  className={`uni-mt-16 uni-mb-16 form-control   login-cstm-pass   ${
                    allErrors.amount === 1
                      ? ""
                      : allErrors.amount === 2
                      ? "is-invalid b-red-2 mb-0"
                      : "is-valid  b-green-2"
                  }`}
                  autoComplete="off"
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const maxy = walletDropStocks?.usdc?.balance || "" ? parseFloat(walletDropStocks?.usdc?.balance || "") : 0; // Use 0 as the default if  is undefined
                    let value = e.target.value;
                    value = value.replace(/[^0-9.]/g, "");
                    const parts = value.split(".");
                    if (parts.length > 2) {
                      value = parts[0] + "." + parts[1];
                    }

                    if (value && parseFloat(value) > maxy) {
                      e.target.value = e.target.value.slice(0, -1); // Remove the last entered character
                      return;
                    }

                    e.target.value = value; // Update the input's value
                  }}
                  onBlur={handleBlurGetNetworkFees}
                />
                {allErrors.amount === 2 && (
                  <p className="invalid-feedback error-red uni-mb-0">
                    {allErrors.amountMessage}
                  </p>
                )}
                <TextfieldXSM className="ds-ctm-txt gray4 r-text m-0 uni-mb-16">
                  Available: {walletDropStocks?.usdc?.balance || ""}{" "}{walletDropStocks?.usdc?.symbol || ""}
                </TextfieldXSM>
                <HrLineMD className="uni-mb-32 uni-mt-32" />
                <TextfieldLG className="gray4 uni-mb-16 uni-fw-700">
                  Receive amount
                </TextfieldLG>
                <Heading3 className="modal-title uni-mb-16">
                  {netFeeDetails.netAmount1}
                </Heading3>
                <TextfieldXSM className="uni-mb-56 ds-ctm-txt gray4 ">
                  Network Fee: {netFeeDetails.fiatPrice1}{" "}
                  {netFeeDetails.fiatSymbol1}
                </TextfieldXSM>
              </div>
            </div>
            <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
              {loading ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  <ButtonComponent
                    id="WalletPinModal"
                   className="mbl-anchor-full-btn"
                  >
                    <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">
                      Back
                    </DropworksSmallButton>
                  </ButtonComponent>{" "}
                  <ButtonComponent id="WalletNetworkConfirmation" className="mbl-anchor-full-btn">
                    <DropworksSmallButton
                      disabled={
                        isAddressValid ||
                        allErrors.address === 2 ||
                        allErrors.amount === 1
                      }
                      className="btn-wid m-0 my-0 mbl-full-width-btn"
                    >
                      Next
                    </DropworksSmallButton>
                  </ButtonComponent>
                </>
              )}
            </div>

            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
