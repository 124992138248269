import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { FaDownload, FaExternalLinkAlt, FaRegClock } from "react-icons/fa";
import downloadIcon from '../../../../assets/img/Message_Download_Icon.png';
import textImage from  '../../../../assets/img/Message_TXT_icon.png';
import CircularProgressBar from "../../../content/messages-chat/downloadProgressbar";
import { PiCheckBold, PiChecksBold } from "react-icons/pi";
import doubletick2 from '../../../../assets/steps/Double-tick2.svg';
import { ArrowDropdown } from "../../../../common/icons";
export const view: FC<any> = ({ fileName, fileType, fileSize, messageTime, pageCount,src,is_recieved=false,seen_unseen=1, sender_name }) => {


  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  

  const getFileIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case "pdf": return "https://cdn-icons-png.flaticon.com/512/337/337946.png"; // PDF icon
      case "docx":return "https://cdn-icons-png.flaticon.com/512/281/281760.png"; // DOCX icon
      case "csv":return "https://cdn-icons-png.flaticon.com/512/8242/8242984.png"; // DOCX icon
      case "zip":return "https://cdn-icons-png.flaticon.com/512/337/337960.png"; // DOCX icon
      case "ppt":return "https://cdn-icons-png.flaticon.com/512/337/337949.png"; // DOCX icon
      case "txt": return textImage;
      case "xls": return "https://cdn-icons-png.flaticon.com/512/732/732220.png";
      case "xlsx":return "https://cdn-icons-png.flaticon.com/512/732/732220.png";
      case "excel":return "https://cdn-icons-png.flaticon.com/512/732/732220.png"; // Excel icon
      default: return "https://cdn-icons-png.flaticon.com/512/565/565547.png";
    }
  };
  const handleDropdownToggle = (index: number) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const [isDownloaded,setIsDownloaded]=useState(false);
  const [progressValue,setProgressValue]=useState<number>(0);
  const handleDownload = async ()=> {
    const fileUrl = `${process.env.REACT_APP_IMGURL}/${src}`;
    const fileName = src || "download";
    try {
      const response = await fetch(fileUrl);
 
      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }
 
      const contentLength = response.headers.get("content-length");
      if (!contentLength) {
        throw new Error("Unable to determine file size.");
      }
 
      const total = parseInt(contentLength, 10); // Total file size in bytes
      let loaded = 0;
 
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Unable to read file stream.");
      }
 
      const chunks: Uint8Array[] = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
 
        if (value) {
          chunks.push(value);
          loaded += value.length;
          const progress = Math.round((loaded / total) * 100);
          if(progress===100)
          {
            setIsDownloaded(true);
          }
          setProgressValue(progress)
        }
      }
 
      const blob = new Blob(chunks);
      const blobUrl = URL.createObjectURL(blob);
 
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
 
  const truncateFileName = (fileName: string, maxLength: number = 12): string => {
 
    if (fileName.length > maxLength) {
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      const baseName = fileName.substring(0, maxLength - 3);
      return `${baseName}...${extension}`;
    }
    return fileName;
  };
  const renderDeliveryStatus = () => {
    switch (seen_unseen) {
      case 3:
        return <img src={doubletick2} className="dobule-tick" alt="Delivered" />;
      case 2:
        return <PiChecksBold className="uni-ml-8 " />;
      case 1:
        return <PiCheckBold className="uni-ml-8" />;
      default:
        return <FaRegClock
         className="uni-ml-8" />;
    }
  };
    return (
      <>
      <p className="sender-name">{sender_name}</p>
        <div className="chat-container">
         {/* <ArrowDropdown
         pagename="call-funtion"
         className="px-0 mobile-p-0 arrowdropdown-message-file"
         arr={[
         "Delete"
          ]}
          /> */}
        
        <div className="message-bubble" onClick={handleDownload}>
          
          <div className="file-info">
            <div className="file-icon">
            <img
              src={getFileIcon(fileType)}
              alt={`${fileType} Icon`}
              className="icon-image"
            />
            </div>
            <div className="file-details">
              <div className="file-name">{truncateFileName(fileName,16)}</div>
              <div className="file-meta">
                • {fileType}
                 {/* {fileSize} */}
              </div>
            </div>
            <div className="download-icon">
              {!isDownloaded && <CircularProgressBar progress={progressValue}/>}
            </div>
          </div>
          <div className="message-time">{!is_recieved &&messageTime}{!is_recieved && renderDeliveryStatus()}</div>
        </div>
      </div>
      </>
    );
};
