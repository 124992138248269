import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";

import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import { useDispatch } from "react-redux";
import { getWalletDetails, getWalletDropStocks, getWalletTransactions, setWalletDetails } from "../../../../redux/reducer/walletSlice";
import { AppDispatch } from "../../../../redux/store";

export const NewWallet = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const navigateTo = (value: string) => () => {
    navigate(value)

  }
  const fetchWalletTransactions = async (type: string) => {
   
    try {
      const payload = {
        // page:"",
        // limit:"",
        sourceType: type
      }
      console.log(type,"type",payload,"payload");
      dispatch(getWalletTransactions(payload));
    } catch (error) {
      console.error("Error fetching wallet transactions:", error);
    }
  };

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        dispatch(getWalletDropStocks());
        dispatch(getWalletTransactions());
        dispatch(getWalletDetails());
      } catch (error) {

        console.error("Error fetching wallet data:", error);
      }
    };
    fetchWalletData();
  }, []);

  const handleSourceTypeChange = (type: string) => {
    fetchWalletTransactions(type);
  };


  return (view({ navigateTo, handleSourceTypeChange }))
}