import { TextfieldLG } from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import { ButtonComponent } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import Loader from "../../loader/view";
import "./styles.scss";
import { SelectArrowDropdownImage } from "../../../../../common/selection-dropdown-image";
import CountriesArray from "../../../../../i18n/countryArrayForSelection";

const view = ({
  shownext,
  submitData,
  handleChange,
  phoneNumber,
  message,
  numberValidation,
  loading = false,
  saveValue,
  changeCountry,
}: {
  shownext: boolean;
  numberValidation: any;
  submitData: () => void;
  handleChange: (num: string) => void;
  phoneNumber: string;
  message: string;
  saveValue:any
  loading: boolean;
  changeCountry: (data: any) => void;
}) => {
  return (
    <div
      className="modal fade"
      id="MobilePhoneModalB"
      aria-hidden="true"
      aria-labelledby="MobilePhoneModalB"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Change Phone Number</Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mt-16 uni-mb-72 ctm-txt">
              Add a valid phone number
            </TextfieldLG>
            <p className=" d-flex">
              <SelectArrowDropdownImage
                options={CountriesArray}
                placeHolderValue="Select a country"
                onChangeValue={changeCountry}
                defaultValueCountry={saveValue}
                isSearch={true}
              />
              <DropworksInput
                className="mb-0 phone-number-input"
                placeholder="Phone number"
                value={phoneNumber}
                maxLength={Number(numberValidation.maxPhoneLength)}
                onInput={(e: any) => {
                  let check = e.target.value.replace(/[^0-9]/g, "");
                  if (check.charAt(0) === "0") {
                    check = check.slice(1); // Remove the first character if it's '0'
                  }
                  handleChange(check);
                }}
              />
            </p>
            {message && <p className="text-danger">{message}</p>}
            {phoneNumber &&
              (phoneNumber.length < Number(numberValidation.minPhoneLength) ||
                phoneNumber.length >
                  Number(numberValidation.maxPhoneLength)) && (
                <p className="text-danger">
                  Phone number should have a minimum length of{" "}
                  {numberValidation.minPhoneLength}{" "}
                  {/* {Number(numberValidation.maxPhoneLength) > Number(numberValidation.minPhoneLength)
        ? `  */}
                  and a maximum length of
                  {/* $ */} {numberValidation.maxPhoneLength}.
                  {/*  ` : ""
                   */}
                </p>
              )}
          </div>
          <div className="uni-mt-40 modal-footer ps-0 social-mobile-modal-footer  pt-0 pb-0 uni-pr-72">
            {loading ? (
              <Loader />
            ) : (
              <>
                <ButtonComponent id="MobilePhoneModalA" className="full-wid">
                  <DropworksSmallButton className="btn-wid social-link-modal-btns btn-bg-gray3 my-0 ml-0 uni-mr-16">
                    Back
                  </DropworksSmallButton>
                </ButtonComponent>
                <DropworksSmallButton
                  disabled={
                    !phoneNumber ||
                    phoneNumber?.length < numberValidation.minPhoneLength ||
                    phoneNumber?.length > numberValidation.maxPhoneLength
                  }
                  className={
                    !phoneNumber ||
                    phoneNumber?.length < numberValidation.minPhoneLength ||
                    phoneNumber?.length > numberValidation.maxPhoneLength
                      ? "btn-wid social-link-modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"
                      : "btn-wid social-link-modal-btns m-0"
                  }
                  onClick={submitData}
                >
                  Next
                </DropworksSmallButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
