import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { HrLineMD } from "../../../../../common/hr-line";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksInputMarketplace } from "../../../../../common/custom-field/dropworks-input-marketplace";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import {
  clearWalletDetails,
  getWalletDetails,
  getWalletDropStocks,
  setWalletDetails,
} from "../../../../../redux/reducer/walletSlice";

export const view: FC<WalletPin> = ({
  errors,
  isSubmitted,
  loading,
  register,
  reset,
  onSubmit,
  isAddressValid,
  handleSubmit,
  closeModalRef,
  openModal,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { fiatSymbol, fiatPrice, cryptoSymbol, cryptoPrice } =
    useSelector((state: RootState) => state.wallet.walletData);
  const { walletDetails, walletDropStocks } = useSelector(
    (state: RootState) => state.wallet
  );

  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  // const [isAddressValid, setIsAddressValid] = useState(true);
  const [allInsertValue, setAllInsertvValue] = useState({
    amount: "",
  });
  const [allErrors, setAllErrors] = useState({
    amount: 1,
    submit: 1,
    amountMessage: "",
  });

  const [checkedValue, setCheckedValue] = useState("");
  const handleBlurGetNetworkFees = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.trim();
    if (value) {
      setAllErrors((prev) => ({ ...prev, amount: 3 }));
    }
  };
  const handleOnClick = () => {
    dispatch(
      setWalletDetails({
        amount: allInsertValue.amount,
        type: "dropstock",
        trackNFTId: selectedRowData?.nftId || "",
        trackTitle: selectedRowData?.project_name || "",
        trackDescription: selectedRowData?.project_name || "",
      })
    );
  };

  const openModal1 = () => {
    reset();
    setAllErrors({
      amount: 1,
      submit: 1,
      amountMessage: "",
    });
    setSelectedRowData(null);
    // dispatch(clearWalletDetails())
  };

  useEffect(() => {
    dispatch(getWalletDetails());
    dispatch(getWalletDropStocks());
  }, []);
  return (
    <ModalWrapper
      modalId="WalletDropstockWithdrawModal"
      onBackdropClick={openModal1}
    >
      <div
        className="modal fade"
        id="WalletDropstockWithdrawModal"
        aria-hidden="true"
        aria-labelledby="WalletDropstockWithdrawModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-16">
              <Heading3 className="modal-title">DropStocks Withdraw</Heading3>
            </div>

            <form>
              <div className="modal-body uni-mb-56">
                <TextfieldLG className="uni-mb-56 ctm-txt ctm-md-lh uni-mbt-16 uni-fw-700 gray3">
                  Select the DropStocks you want to withdraw
                </TextfieldLG>

                <div className="dropstocks-assets">
                  <div className="table-view">
                    <div className="table-wrapper">
                      <table className="table custom-table-2  mb-0">
                        <thead className="text-dark d-flex flex-column border-0 mobile-table-thead">
                          {selectedRowData && (
                            <div>
                              <tr className="d-flex align-items-center justify-content-between table-row-dropstock border-0 p-0 scroll-pr-20">
                                <td className="date-time-2">
                                  <div className="d-flex gap-3 align-items-center bottom-table">
                                    <span className="select-box wallet-select-box">
                                      <>
                                        {selectedRowData?.art_work_image[0]
                                          .src && (
                                          <img
                                            className="select-box bg-custom-trans"
                                            src={`${
                                              process.env.REACT_APP_IMGURL
                                            }/${
                                              selectedRowData?.art_work_image[0]
                                                .src || ""
                                            }`}
                                            alt=""
                                          />
                                        )}
                                      </>
                                    </span>
                                    <div className="d-flex cursor-pointer">
                                      <TextfieldLG className="my-0  p-0 select-title">
                                        {selectedRowData?.project_name || ""}
                                      </TextfieldLG>
                                    </div>
                                  </div>
                                </td>
                                <td className="quantity">
                                  <span className="table-data">
                                    <DropworksRadioCheckbox checked={true} />
                                  </span>
                                </td>
                              </tr>
                            </div>
                          )}

                          <div className="custom_pass_container_marketplace deposit-amount-input border-0 scroll-pr-20">
                            <DropworksInput
                              placeholder="Amount"
                              disabled={loading}
                              value={allInsertValue.amount}
                              onChange={(e) => {
                                setAllInsertvValue((prev) => ({
                                  ...prev,
                                  amount: e.target.value,
                                }));
                                if (e.target.value.length > 0) {
                                  setAllErrors((prev) => ({
                                    ...prev,
                                    amount: 3,
                                    amountMessage: "",
                                  }));
                                } else {
                                  // setIsAddressValid(true);
                                  setAllErrors((prev) => ({
                                    ...prev,
                                    amount: 1,
                                    amountMessage: "",
                                  }));
                                }
                              }}
                              className={`uni-mt-16 uni-mb-16 form-control   login-cstm-pass  scroll-pl-20 ${
                                allErrors.amount === 1
                                  ? ""
                                  : allErrors.amount === 2
                                  ? "is-invalid b-red-2 mb-0"
                                  : "is-valid  b-green-2"
                              }`}
                              autoComplete="off"
                              onInput={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const maxy = selectedRowData?.dropstockCount
                                  ? parseFloat(selectedRowData?.dropstockCount)
                                  : -1; // Use 0 as the default if selectedRowData?.dropstockCount is undefined
                                let value = e.target.value;
                                value = value.replace(/[^0-9.]/g, "");
                                const parts = value.split(".");
                                if (parts.length > 2) {
                                  value = parts[0] + "." + parts[1];
                                }

                                if (value && parseFloat(value) > maxy) {
                                  e.target.value = e.target.value.slice(0, -1); // Remove the last entered character
                                  return;
                                }

                                e.target.value = value; // Update the input's value
                              }}
                              onBlur={handleBlurGetNetworkFees}
                            />
                          </div>

                          <div
                            className={`ds-ctm-txt gray4 m-0 d-flex scroll-pr-20 ${
                              allErrors.amount === 2 ? "uni-mb-16" : "uni-mb-16"
                            }`}
                          >
                            <p className={`invalid-feedback error-red p-0 `}>
                              {allErrors.amount === 2 &&
                                allErrors.amountMessage}
                            </p>

                            <TextfieldXSM
                              className={`ds-ctm-txt gray4 r-text m-0 white-space-n p-0`}
                            >
                              Available:{" "}
                              {selectedRowData?.dropstockCount || "0.00"}{" "}
                              DropStocks
                            </TextfieldXSM>
                          </div>
                        </thead>
                        <tbody
                          className="uni-mt-8 p-0 scroll-pr-20"
                          style={{ maxHeight: "336px" }}
                        >
                          {walletDetails && walletDetails.length > 0 ? (
                            walletDetails.map((wallet: any, index: number) => (
                              <tr
                                key={index}
                                className="d-flex align-items-center justify-content-between table-row-dropstock p-0"
                                onClick={() => {
                                  setCheckedValue(wallet.trackId);
                                  setSelectedRowData(wallet);
                                }}
                              >
                                <td className="date-time-2">
                                  <div className="d-flex gap-3 align-items-center bottom-table">
                                    <span className="select-box wallet-select-box">
                                      {wallet?.art_work_image &&
                                      wallet?.art_work_image.length > 0 ? (
                                        <img
                                          className="select-box bg-custom-trans"
                                          src={`${process.env.REACT_APP_IMGURL}/${wallet?.art_work_image[0].src}`}
                                          alt=""
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    <div className="d-flex cursor-pointer">
                                      <TextfieldLG className="my-0  p-0 select-title">
                                        {wallet.project_name}
                                      </TextfieldLG>
                                    </div>
                                  </div>
                                </td>
                                <div className="quantity d-flex p-0 align-items-end">
                                  <DropworksRadioCheckbox
                                    checked={wallet.trackId === checkedValue}
                                  />
                                </div>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center">
                                No DropStocks available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer social-mobile-modal-footer uni-mr-16">
                {loading ? (
                  <Loader className="uni-mt-16" />
                ) : (
                  <>
                    <ButtonComponent id="WalletFeesDropStocksWithdrawModal">
                      <DropworksSmallButton
                        className="btn-wid btn-bg-gray3 my-0 ml-0 social-link-modal-btns"
                        disabled={allErrors.amount === 1}
                        onClick={handleOnClick}
                      >
                        Next
                      </DropworksSmallButton>
                    </ButtonComponent>
                  </>
                )}
              </div>
            </form>
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
