import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./app/routing/MainRoutes";
import "../src/styles/variablesstyles.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import { generateToken, messaging } from "./notification/firebase";
import { onMessage } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import {
  connectSocket,
  onInviteReceived,
} from "./app/shared/utils/socketServices";
import {
  fetchNotification,
  fetchReceviedInvite,
  fetchSentInvite,
  setSocketNotification,
} from "./redux/reducer/uploadSlice";
import { getLocalStorageObject } from "./app/shared/utils/localStorage";
import { setHeaderValue } from "./redux/reducer/auth";
import { AudioPlayer } from "./app/shared/components/audio-player";
import { setInvite, setSentInvite } from "./redux/reducer/inviteSlice";
import "../src/common-css/common-css.css";

function App() {
  const { authentication } = useSelector((state: RootState) => state.auth);
  const music = useSelector((state: RootState) => state.music);
  const dispatch = useDispatch();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  useEffect(() => {
    let auth_token = localStorage.getItem("dropworksToken");
    let device_token = localStorage.getItem("dw_fb_token");

    if (auth_token && !device_token) {
      generateToken();
      // onMessage(messaging, (payload) =>{
      //    console.log(payload);
      // });
    }
    const unsubscribe = onMessage(messaging, (payload) => {
      // localStorage.setItem("notification","true");
    });

    if (auth_token) {
      let value = Number(getLocalStorageObject("dw-header"));
      dispatch(setHeaderValue(value));
      if (authentication) {
        connectSocket(auth_token);
      }
      dispatch(fetchNotification() as any);
      onInviteReceived((msg: any) => {
        if (msg) {
          if (audioRef.current) {
            audioRef.current.play().catch((error) => {
              console.error("Audio play error:", error);
            });
          }
          dispatch(fetchReceviedInvite() as any).then((res: any) =>
            dispatch(setInvite(res.payload))
          );
          dispatch(fetchSentInvite() as any).then((res: any) =>
            dispatch(setSentInvite(res.payload))
          );
          dispatch(fetchNotification() as any);
        }
      });
    }

    return () => unsubscribe();
  }, [authentication]);
  useEffect(() => {
    AOS.init({ duration: 1250, once: true });
  }, []);

  return (
    <BrowserRouter>
      <MainRoutes />
      {music.Data && (
        <>
          <div
            className="d-flex  w-100 "
            style={{ background: "rgb(0 0 0)", height: "97px" }}
          ></div>
          <AudioPlayer />
        </>
      )}
      <audio
        ref={audioRef}
        src="\message-incoming-dropworks.mp3"
        preload="auto"
      />
    </BrowserRouter>
  );
}

export default App;
