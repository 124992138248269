import React, { FC, useRef, useState } from "react";
import "./styles.scss";
import playmusic2 from "../../assets/img/play-music-icon-2.png";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/effect-fade";
import { useDispatch, useSelector } from "react-redux";
import {
  setData,
  setMusicPlayerData,
  setMusicTrack,
  setMusicTrackData,
} from "../../redux/reducer/musicSlice";
import { setOpenPopup } from "../../redux/reducer/reportSlice";
import { apionMusicPlay } from "../../app/shared/utils/apiServices";
import { RootState } from "../../redux/store";
import { TextfieldMD } from "../custom-field/dropstock-text";
import { setTrackDetailsPage } from "../../redux/reducer/beatSlice";
export const view: FC<any> = ({
  navigateTo,
  image,
  arr,
  className,
  className2,
  page,
  showSwiper = false,
}) => {
  const navigate = useNavigate();
  const submitData = async (data: any) => {
    navigate(`/track-page/${data}`);
  };

  const prevButtonRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: RootState) => state.user);
  const swiperRef = useRef<any>(null);
  const dispatch = useDispatch();
  const playButtonClick = (e: any, data: any) => {
    e.stopPropagation();
    dispatch(setData(true));
    dispatch(setMusicTrackData(data?.fullTrackData));
    dispatch(setMusicTrack(data?.fullTrackData?.[0]?.audio_file?.[0]?.src));
    dispatch(setOpenPopup("track"));
    // console.log(data,"in track icon")
    dispatch(
      setMusicPlayerData({
        musicName: data?.audioName,
        musicAudioSrc: data?.audioUrl,
        musicImgSrc: data?.image,
        showOptions: true,
        musicLiked: Boolean(data?.audioLiked),
        showMusicBar: true,
        musicCreator: data?.createrName,
        musicOwnedByMe: data?.audioByMe,
        musicDescription: data?.fullTrackData?.[0]?.description,
        musicId: data?.id,
        musicPath: data?.audioPath,
        musicType: "track",
        musicPageTrackData: {
          trackDetailsReleaseDate: data?.trackDetailsReleaseDate || "",
          trackDeatilsCreatorList: data?.trackDeatilsCreatorList || "",
          trackDetailsUniqueId: data?.trackDetailsUniqueId || "",
        },
      })
    );
    apionMusicPlay({ name: "track_id", value: data.id });
    if (
      data?.trackDetailsReleaseDate &&
      data?.trackDeatilsCreatorList &&
      data?.trackDetailsUniqueId
    ) {
      dispatch(
        setTrackDetailsPage({
          trackDetailsReleaseDate: data?.trackDetailsReleaseDate,
          trackDeatilsCreatorList: data?.trackDeatilsCreatorList,
          trackDetailsUniqueId: data?.trackDetailsUniqueId,
        })
      );
    }
  };
  return (
    <>
      {showSwiper && arr.length > 0 && (
        <>
          <div className="swiper-button-prev" ref={prevButtonRef}></div>
          <div className="swiper-button-next" ref={nextButtonRef}></div>
        </>
      )}
      <div className={`row ${className}`}>
        {!showSwiper ? (
          arr?.length ? (
            <>
              {arr.map((data: any, index: number) => (
                <div
                  key={index}
                  className={`col-6 col-sm-6 col-md-3 col-lg-2 track-icon ${className2}`}
                >
                  <div
                    className="track-icon-container"
                    onClick={() => submitData(data.slugName)}
                  >
                    {data.image ? (
                      <img
                        className="track-icon-box"
                        src={`${process.env.REACT_APP_IMGURL}/${data.image}`}
                      />
                    ) : (
                      ""
                    )}
                    <img
                      className="track-icon-box-icon"
                      src={playmusic2}
                      onClick={(e) => playButtonClick(e, data)}
                    />
                  </div>
                  <div className="track-icon-heading">
                    {data.heading} &nbsp;
                  </div>
                  <div className="track-icon-subheading link-btm">
                    {data?.createrName &&
                      data?.createrName?.length &&
                      data?.createrName.map(
                        (data: any, index: number, arr: any) => (
                          <React.Fragment key={data.slug_name}>
                            <TextfieldMD
                              className="underline-opening mb-0 mt-0 m-0"
                              onClick={navigateTo(
                                `/user-profile/${data.slug_name}`
                              )}
                            >
                              {data.receiver_name}
                            </TextfieldMD>
                            {index < arr?.length - 1 && ", "}
                          </React.Fragment>
                        )
                      )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span className="no-data-found">No tracks Available</span>
          )
        ) : null}
        {showSwiper ? (
          arr?.length ? (
            <>
              <Swiper
                modules={[Autoplay, Navigation]}
                autoplay={{ delay: 10000, disableOnInteraction: false }}
                spaceBetween={0}
                navigation={{
                  prevEl: prevButtonRef.current,
                  nextEl: nextButtonRef.current,
                }}
                onBeforeInit={(swiper: any) => {
                  swiper.params.navigation.prevEl = prevButtonRef.current;
                  swiper.params.navigation.nextEl = nextButtonRef.current;
                }}
                slidesPerView={2}
                effect="fade"
                speed={2000}
                loop={true}
                breakpoints={{
                  640: { slidesPerView: 2 },
                  768: { slidesPerView: 5 },
                  1024: { slidesPerView: 6 },
                  1440: { slidesPerView: 6 },
                }}
                onSwiper={(swiper: any) => {
                  swiperRef.current = swiper;
                }}
                onAutoplayStart={(swiper: any) =>
                  console.log("Autoplay started")
                }
                onAutoplayStop={(swiper: any) =>
                  console.log("Autoplay stopped")
                }
                className="swiper-container"
              >
                {arr.map((data: any, index: any) => (
                  <SwiperSlide
                    key={index}
                    onMouseEnter={() => swiperRef.current?.autoplay?.stop()}
                    onMouseLeave={() => swiperRef.current?.autoplay?.start()}
                    className={`track-icon ${className2}`}
                  >
                    <div
                      className="track-icon-container"
                      onClick={() => submitData(data.slugName)}
                    >
                      {data.image ? (
                        <img
                          className="track-icon-box"
                          src={`${process.env.REACT_APP_IMGURL}/${data.image}`}
                          alt={data.heading}
                        />
                      ) : null}
                      <img
                        className="track-icon-box-icon"
                        src={playmusic2}
                        alt="Play"
                        onClick={(e) => playButtonClick(e, data)}
                      />
                    </div>
                    <div className="track-icon-heading">
                      {data.heading} &nbsp;
                    </div>
                    <div className="track-icon-subheading link-btm">
                      {data?.createrName &&
                        data?.createrName?.length &&
                        data?.createrName.map(
                          (data: any, index: number, arr: any) => (
                            <React.Fragment key={data.slug_name}>
                              <TextfieldMD
                                className="underline-opening mb-0 mt-0 m-0"
                                onClick={navigateTo(
                                  `/user-profile/${data.slug_name}`
                                )}
                              >
                                {data.receiver_name}
                              </TextfieldMD>
                              {index < arr?.length - 1 && ", "}
                            </React.Fragment>
                          )
                        )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : (
            <span className="no-data-found">No tracks Available</span>
          )
        ) : null}
      </div>
    </>
  );
};
