import React, { useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { PiCheckBold, PiChecksBold } from "react-icons/pi";
import doubletick2 from "../../../assets/steps/Double-tick2.svg";
interface VideoMessageProps {
  videoUrl: string;
  onDownload?: () => void;
  messageTime?: string;
  seen_unseen?: number;
  is_recieved?: boolean;
}

const VideoMessage: React.FC<VideoMessageProps> = ({
  videoUrl = `${process.env.REACT_APP_BASE_URL}/uploads/1733145198629.mp4`,
  messageTime = "",
  seen_unseen = 1,
  is_recieved,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  const [progress, setProgress] = useState(0);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const handleStartDownload = (e: any) => {
    const controller = new AbortController();
    setAbortController(controller);

    // handleDownload(e, item, setProgress, controller);
  };

  const handleCancelDownload = () => {
    if (abortController) {
      abortController.abort(); // Abort the ongoing download
      setProgress(0); // Reset progress
      setAbortController(null); // Clear the controller
    }
  };

  const renderDeliveryStatus = () => {
    switch (seen_unseen) {
      case 3:
        return (
          <img src={doubletick2} className="dobule-tick" alt="Delivered" />
        );
      case 2:
        return <PiChecksBold className="uni-ml-8 " />;
      case 1:
        return <PiCheckBold className="uni-ml-8" />;
      default:
        return <FaRegClock className="uni-ml-8" />;
    }
  };

  return (
    <div className="video-wrapper uni-mb-8">
      <div
        className="video-message"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="play-button-overlay">
          {(isHovered || !isPlaying) && (
            <button className="play-button" onClick={handlePlayPause}>
              {isPlaying ? "❚❚" : "▶"}
            </button>
          )}
        </div>
        <video
          ref={videoRef}
          src={`${`${process.env.REACT_APP_BASE_URL}`}/${videoUrl}`}
          controls
          className="video-player"
          onEnded={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>
      <div className="message-time text-end fss-16">
        {!is_recieved && messageTime}
        {!is_recieved && renderDeliveryStatus()}
      </div>
    </div>
  );
};

export default VideoMessage;
