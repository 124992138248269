import { FC, useEffect, useState } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  getSearchValue?:(value:string)=>void
  clearInputExternal?: number; 
}

export const DropworksSearchInput:FC<InputProps>=({getSearchValue,clearInputExternal=0,...props})=>{
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
      if (clearInputExternal>0) {
        clearInput();
      }
    }, [clearInputExternal]);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      getSearchValue && getSearchValue(e.target.value)
      
    };
    const clearInput = () => {
      setInputValue("");
      getSearchValue && getSearchValue("")
    };
    return view({handleInputChange,inputValue,clearInput,...props});
}