import {configureStore} from '@reduxjs/toolkit';

import { authSlice } from './reducer/auth';
import { musicReducer } from './reducer/musicSlice';
import { dropWorksSelectReducer } from './reducer/dropworksSelect';
import { uploadReducer } from './reducer/uploadSlice';
import { modalReducer } from './reducer/modal';
import { userReducer } from './reducer/userSlice';
import languageReducer from './reducer/languageSlice';
import { settingsReducer } from './reducer/settingsSlice';
import { mailReducer } from './reducer/mailSlice';
import { inviteReducer } from './reducer/inviteSlice';
import { beatReducer } from './reducer/beatSlice';
import { projectReducer } from './reducer/projectnameSlice';
import { messageReducer } from './reducer/messageChatSlice';
import { reportReducer } from './reducer/reportSlice';
import { walletReducer } from './reducer/walletSlice';

// import {uiSlice} from '@app/store/reducers/ui';
// import {createLogger} from 'redux-logger';

const store = configureStore({
  devTools:true,
  reducer: {
    auth: authSlice.reducer,
    music: musicReducer,
    dropWorksSelectOption:dropWorksSelectReducer,
    upload:uploadReducer,
    modal:modalReducer,
    user:userReducer,
    language: languageReducer,
    settings: settingsReducer,
    mail:mailReducer,
    invite:inviteReducer,
    beat:beatReducer,
    projectName:projectReducer,
    messageChat: messageReducer,
    report: reportReducer,
    wallet: walletReducer,
    // ui: uiSlice.reducer
  },
//   middleware: (getDefaultMiddleware) => [
//     ...getDefaultMiddleware().concat(createLogger())
//   ]
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;