import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllBeatsTrackByUserId } from "../../shared/utils/apiServices";
import { RootState } from "../../../redux/store";

export const Profile = () => {
  const navigate = useNavigate();
  const [beatsData, setBeatsData] = useState<any>()
  const [trackData, setTrackData] = useState<any>()
  const [recentReleasesData, setRecentReleasesData] = useState<any>()

  const [linksArray, setLinkAyyay] = useState<any>([
  ]);

  const navigateTo = (value: string) => () => {
    navigate(value)
  }

  useEffect(() => {
    const fetchAllBeatsTrackBy = async () => {
      const result = await getAllBeatsTrackByUserId();
      if (result.success) {
        setBeatsData(result.BeatsTrackData.data.beatsData)
        setTrackData(result.BeatsTrackData.data.trackData)
        setRecentReleasesData(result.BeatsTrackData.data.recentReleasesdata)
      }
    };

    fetchAllBeatsTrackBy();
  }, []);

  const firstName = useSelector((state: any) => state.user.firstname)
  const lastName = useSelector((state: any) => state.user.lastname)
  const userName = useSelector((state: any) => state.user.username)
  const displayName=useSelector((state:RootState)=>state.user.displayName)
  const role = useSelector((state: any) => state.user.role)
  const bioGraphy = useSelector((state: any) => state.user.bioGraphy)
  const DWPreferences: any = useSelector((state: any) => state.user.DWPreferences)
  const socialLinks: any = useSelector((state: any) => state.user.social_links)
  const imgUrl: string = useSelector((state: RootState) => state.user.imgUrl)
  const id: string = useSelector((state: RootState) => state.user.id)
  const blueTick: number = useSelector((state: RootState) => state.user.blueTick)
  const displayBeats: boolean = useSelector((state: RootState) => state.user.displayBeats)
  const displayReleases: boolean = useSelector((state: RootState) => state.user.displayReleases)
  const {musicPlayer} = useSelector((state: RootState) => state.music)

  useEffect(() => {
    if (socialLinks?.length > 0) {
      setLinkAyyay(socialLinks.map((data: any) => (
        {
          name: data.name,
          link: data.link,
        }
      )))
    }
  }, [socialLinks])

  const changeData=()=>{
    if(musicPlayer.musicType ==="track" && Boolean(trackData.length) )
    {
      let newUpdatedData=trackData.map((ele:any)=>{
        if(ele?._id && ele?._id === musicPlayer.musicId)
          {
            let newData={favoriteData:musicPlayer.musicLiked?{_id:"abc"}:""};
            return {...ele,...newData}
          }
          else{
            return ele ;
          }
        })
        setTrackData(newUpdatedData);
      }
      else if(musicPlayer.musicType ==="beat" && Boolean(beatsData.length)) {
      let newUpdatedData=beatsData.map((ele:any)=>{
        if(ele._id && ele?._id === musicPlayer.musicId)
        {
          let newdata= {favoriteData:musicPlayer.musicLiked?{_id:"abcd"}:""};
          return {...ele,...newdata}
        }
        else{
          return ele ;
        }
      })
     
     setBeatsData(newUpdatedData);  
   
    }
  }
 
  useEffect(()=>{
    changeData();
  },[musicPlayer.musicId , musicPlayer.musicLiked])


  return (view({
    navigateTo, firstName, blueTick, lastName, userName, role, bioGraphy, DWPreferences, beatsData, trackData,
    displayBeats, displayReleases, recentReleasesData, linksArray, socialLinks, imgUrl, id,displayName
  }))
}