import React, { FC } from "react";
import { FaPlay, FaPause, FaReply, FaRegClock } from "react-icons/fa";
import { BiHeadphone } from "react-icons/bi";
import "./styles.scss";
import audioIcon from '../../../../assets/steps/Audio_file_in_chat.svg';
import { PiCheckBold, PiChecksBold } from "react-icons/pi";
import doubletick2 from '../../../../assets/steps/Double-tick2.svg';
import { IoCheckmarkDone } from "react-icons/io5";
export const view: FC<any> = ({
  src,
  togglePlay,
  handleTimeUpdate,
  handleLoadedMetadata,
  formatTime,
  audioRef,
  isPlaying,
  currentTime,
  duration,
  is_recieved=false,
  messageTime="",
  seen_unseen=1
}) => {

  const renderDeliveryStatus = () => {
    switch (seen_unseen) {
      case 3:
        return <img src={doubletick2} className="dobule-tick" alt="Delivered" />;
      case 2:
        return <PiChecksBold className="uni-ml-8 " />;
      case 1:
        return <PiCheckBold className="uni-ml-8" />;
      default:
        return <FaRegClock className="uni-ml-8" />;
    }
  };
  return (
    <div className="bhhh">

  <div className="audio-player">
    <img src={audioIcon} className="img-fluid audio-image"/>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
      {/* Play/Pause Button */}
      <button onClick={togglePlay} className="audio-btn">
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>

      {/* Progress Bar */}
      <input
        type="range"
        value={currentTime}
        max={duration}
        onChange={(e) => {
          const newTime = Number(e.target.value);
          if (audioRef.current) {
            audioRef.current.currentTime = newTime;
            handleTimeUpdate();
          }
        }}
        className="audio-progress"
        />

      {/* Timestamps */}
      <span className="audio-time">{formatTime(currentTime)}</span>
      <span className="audio-time">{formatTime(duration)}</span>
     
      {/* <BiHeadphone className="audio-icon" /> */}
    </div>
    <div className="message-time fss-16">{!is_recieved && messageTime}{!is_recieved && renderDeliveryStatus()}</div>
        </div>

  );
};
