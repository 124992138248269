import { FC, useEffect, useRef, useState } from "react";
import  view  from "./view";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { registerFourthPopup, registerThirdPopup } from "../../../utils/apiServices";
import {  setStartTimer } from "../../../../../redux/reducer/modal";
import { openPopup } from "../../logo-header/view";



export const Modal4:FC<any>=()=>{
    const [phNumber, setPhNumber] = useState<number>();
  const [message,setMessage]=useState<string>();
  const closeModalRef = useRef<HTMLAnchorElement>(null);

  const updateNumber = (value: number) => {
    setPhNumber(value);
};
const { mobile,mobileCountryCode } = useSelector((state: RootState) => state.modal);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const OpenPopup3 = async () => {
    setLoading(true);
    const response: any = await registerFourthPopup({phone:mobile,otp:phNumber,countryCode:mobileCountryCode});
    if (response.success) {
      setLoading(false);
      if (closeModalRef.current) {
        closeModalRef.current.click();
      }
  openPopup("acc-secured",dispatch)
    } else {
      setLoading(false);
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

  const [cooldown, setCooldown] = useState(false);
  const [timer, setTimer] = useState(0);
 const {startTimer } =useSelector((state:RootState)=>state.modal) ;
  const handleResendCode = async () => {
    if (!cooldown) {
      setCooldown(true);
      setTimer(10); 
      if(!startTimer)
      {
       const response = await registerThirdPopup({phone:mobile,countryCode:mobileCountryCode});
       if(response.success)
       {

       }
       else{
        setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
       }
      }
    }
  };

  useEffect(()=>{
    if(startTimer)
    {
      handleResendCode();
      dispatch(setStartTimer(false))
    }
  },[startTimer])

  useEffect(() => {
    if (cooldown && timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);

      if (timer === 1) {
        setCooldown(false);
      }

      return () => clearInterval(countdown);
    }
  }, [cooldown, timer]);

    return view({phNumber,updateNumber,OpenPopup3,message,loading,cooldown,handleResendCode,timer});
}
export default Modal4