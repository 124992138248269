import {DropworksSmallButton} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { TextfieldMD,TextfieldSM, TextfieldXSM} from "../../../../../common/custom-field/dropstock-text";
import { downloadPinPdf } from "../../../../content/guest-pages/login/modal";
import {ButtonComponent,CloseModalButton} from "../../logo-header/view";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { CopyBox } from "../../../../../common/copy-box";

export const view: FC<downloadPinPdf> = ({
  loading,
  downloadBtnClick,
  nextBtnClick,
  isFilled,
  privateKey,
  isPDFDownloaded,
  phrase,
  closeModalRef
}) => {
  const { is_downloaded_pin, is_downloaded_next } = useSelector(
    (state: RootState) => state.user
  );
  
  return (
    <ModalWrapper modalId="downloadPinOneTime">
      <div
        className="modal fade"
        id="downloadPinOneTime"
        aria-hidden="true"
        aria-labelledby="downloadPinOneTime"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon"></div>
            <div className="modal-header uni-mb-16">
              <Heading3 className="modal-title">Download PDF</Heading3>
            </div>
             <CloseModalButton  ref={closeModalRef} className="d-none"  ></CloseModalButton>
            <div className="modal-body uni-mb-56 uni-mbl-max-height">
              <TextfieldMD className="uni-mt-8 ctm-txt ctm-md-lh">
                This PDF contains highly sensitive information, including your
                private key, recovery phrase, and PIN, which can be used to
                reset your PIN if you forget it. Read the following points
                carefully:
              </TextfieldMD>

              <TextfieldSM className="uni-mt-16 ctm-txt ctm-md-lh">
                1. Secure storage: Save this file in a secure and private
                location. It will not be retrievable again.
              </TextfieldSM>
              <TextfieldSM className="uni-mt-16 ctm-txt ctm-md-lh">
                2.Confidentiality:Do not share this file with anyone. Access to
                this information could compromise your security.
              </TextfieldSM>
              <TextfieldSM className="uni-mt-16 ctm-txt ctm-md-lh">
                3.No recovery:If you lose this document, we cannot restore or
                regenerate your private key or recovery phrase.
              </TextfieldSM>
              <TextfieldMD className="uni-mt-16 uni-mb-16 text-white">
                Write down the following travel words and store them in a
                safe place:
              </TextfieldMD>
              <CopyBox text={phrase} showButton={true} className="uni-mb-16"/>
              <TextfieldMD className="uni-mt-16 uni-mb-16 text-white">
                Copy the private key and store it in a safe place:
              </TextfieldMD>
              <CopyBox text={privateKey} showButton={true} className="uni-mb-16" />
              <TextfieldXSM className="m-0 ds-ctm-txt">
                By clicking Download, you acknowledge and accept full
                responsibility for safeguarding this information.
              </TextfieldXSM>
            </div>
            <div className="modal-footer social-mobile-modal-footer  ps-0 pt-0 pb-0 uni-pr-72">
              {loading ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  <DropworksSmallButton
                    onClick={downloadBtnClick}
                    disabled={isFilled ? true : false}
                    className={
                      is_downloaded_pin
                        ? "btn-wid social-link-modal-btns mb-0 white-s-nowrap custom-inactive-btn text-secondary disable-interactions"
                        : `btn-wid social-link-modal-btns mb-0 white-s-nowrap mt-0 uni-mr-16 ${
                            isFilled
                              ? "custom-inactive-btn text-secondary disable-interactions"
                              : ""
                          }`
                    }
                  >
                    Download PDF
                  </DropworksSmallButton>
                  {
                  isPDFDownloaded ? (
                    <>
                      <ButtonComponent id="downloadPdfConfirmation" className="mbl-anchor-full-btn">
                        <DropworksSmallButton
                          className="btn-wid social-link-modal-btns mb-0 mt-0"
                          onClick={nextBtnClick}
                        >
                          Next
                        </DropworksSmallButton>
                      </ButtonComponent>
                    </>
                  ) :
                  !isFilled ? (
                    <>
                      <DropworksSmallButton
                        disabled={isFilled ? false : true}
                        className={
                          isFilled
                            ? "btn-wid social-link-modal-btns mb-0 mt-0"
                            : "btn-wid social-link-modal-btns mb-0  custom-inactive-btn text-secondary disable-interactions"
                        }
                      >
                        Next
                      </DropworksSmallButton>
                    </>
                  ) : (
                    <>
                      <ButtonComponent id="downloadPdfConfirmation" >
                        <DropworksSmallButton
                          disabled={isFilled ? false : true}
                          className={
                            isFilled
                              ? "btn-wid social-link-modal-btns mb-0 mt-0"
                              : "btn-wid social-link-modal-btns mb-0  custom-inactive-btn text-secondary disable-interactions"
                          }
                          onClick={nextBtnClick}
                        >
                          Next
                        </DropworksSmallButton>
                      </ButtonComponent>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
