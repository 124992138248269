import { FC, useEffect, useState } from "react";
import { trackFormData, viewType } from "./modal";
import chatBubble from "../../../assets/img/chat-bubble.png";
import "./styles.scss";
import UserIcon from "../../../assets/steps/Group 18414.svg";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksDisableButton,
  DropworksNormalButton,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import { setuserListData } from "../../../redux/reducer/messageChatSlice";
import Tabs from "../../../common/tabs";
import { DropworksSelect } from "../../../common/custom-field/dropworks-select";
import { DropworksSwitch } from "../../../common/custom-field/dropworks-switch";
import kingRoland from "../../../assets/img/roland.png";
import { BlueTik, YelloTik } from "../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../common/icons";
import king from "../../../assets/img/king.png";
import downloadIcon from "../../../assets/steps/download.svg";
import Producer from "../../../assets/img/Producer.png";
import { UserLable } from "../../../common/user-labels";
import rightArrow from "../../../assets/steps/arrow-small-right.svg";
import exclamationTriangle from "../../../assets/steps/exclamation-triangle.svg";
import {
  ButtonComponent,
  openPopup,
} from "../../shared/components/logo-header/view";
import TabsComponent from "../../../common/tabs-component/view";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from "../../../common/custom-field/dropstock-heading";
import { SelectArrowDropdown } from "../../../common/selection-dropdown";
import CustomTooltip from "../../../common/custom-tooltip/view";
import { preProcessFile } from "typescript";
import {
  createTrackProject,
  getFile,
  getMissingStep,
  updateInvitedCollaboratorsTrackProject,
} from "../../shared/utils/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  fetchTrackDetails,
  getALlInvitedUserbyTrackProject,
  setSelectedFile,
  setSetelectedCollaborator,
  setTabValueChange,
  setTrackID,
  setTrackSlugName,
  setUploadNewBeatImg,
  setUploadNewBeatImgAPI,
  setUserRole,
  SpotifyLinkUser,
} from "../../../redux/reducer/uploadSlice";
import { AppDispatch } from "../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { setProjectname } from "../../../redux/reducer/projectnameSlice";
import { InviteFriends } from "../../../common/invite-friends";
import { setDeleteModalData } from "../../../redux/reducer/musicSlice";
import { IMaskInput } from "react-imask";
import { dateFormat } from "../../shared/utils/localStorage";

const view: FC<viewType> = ({ navigateTo }) => {
  const dispatch = useDispatch<AppDispatch>();
  const parmsSlug = useParams();
  const { selectedCollaborator } = useSelector(
    (state: RootState) => state.upload
  );
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Change Alias",
    "Spotify Profile Link",
  ]);
  const [MeatballsMenuOptionsSpotify, setMeatballsMenuOptionsSpotify] =
    useState(["Spotify Profile Link"]);
  const [MeatballsMenuOptions2, setMeatballsMenuOptions2] = useState([
    "Change Alias",
    "Message",
    "Remove",
  ]);
  const [MeatballsMenuOptions21, setMeatballsMenuOptions21] = useState([
    "Change Alias",
    "Message",
  ]);

  const [MeatballsMenuOptions3, setMeatballsMenuOptions3] = useState([
    "Approve",
    "Decline",
  ]);
  const [MeatballsMenuOptions31, setMeatballsMenuOptions31] = useState([
    "Delete",
  ]);
  const {
    spotifyUser,
    tabValueChange,
    uploadnewbeatImg,
    uploadnewbeatImgAPI,
    invitedUserTrackProject,
    trackDetails,
    trackId,
    trackSlugname,
    userRole,
  } = useSelector((state: RootState) => state.upload);
  const pname = useSelector((state: RootState) => state.projectName);

  const [slugName, setslugName] = useState<any>();
  const [trackformData, setTrackFormData] = useState<trackFormData>({
    project_name: "Project Name",
    track_name: "",
    description: "",
    genre: "",
    language: "",
    contains_explicit_language: false,
    is_project_public: false,
    art_work_image: "",
    file_type: "",
    short_description: "",
    is_instrumental: false,
    released_date:""
  });
  const userData = useSelector((state: any) => state.user);

  const [missingStep, setMissingStep] = useState<any>([]);
  const [previousTrackformData, setPreviousTrackformData] = useState<any>({
    project_name: "Project Name",
    track_name: "",
    description: "",
    genre: "",
    language: "",
    contains_explicit_language: false,
    is_project_public: false,
    art_work_image: "",
    file_type: "",
    short_description: "",
    is_instrumental: false,
    released_date:""
  });
  const [tabsValue, setTabValue] = useState(0);
  const [tabsValue2, setTabValue2] = useState(0);
  const [clickTab,setClickTab]=useState(false)
  const [clickDisableRelease,setClickDisableRelease]=useState(false)

  const [showComponent, setShowComponent] = useState(tabsValue);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        navigate("/create");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(tabsValue);
    }, 300); // Matches the CSS transition duration

    return () => clearTimeout(timeout);
  }, [tabsValue]);
  const options = [
    { value: "Primary Artist", label: "Primary Artist" },
    { value: "Featured Artist", label: "Featured Artist" },
    { value: "Hidden", label: "Hidden" },
  ];

  const GenreOptions = [
    { value: "R&B", label: "R&B" },
    { value: "Hip Hop", label: "Hip Hop" },
    { value: "Pop", label: "Pop" },
    { value: "Electropop", label: "Electropop" },
    { value: "Dance - pop", label: "Dance - pop" },
    { value: "J - pop", label: "J - pop" },
    { value: "K - pop", label: "K - pop" },
    { value: "EDM", label: "EDM" },
    { value: "House", label: "House" },
    { value: "Deep House", label: "Deep House" },
    { value: "Tech House", label: "Tech House" },
    { value: "Progressive House", label: "Progressive House" },
    { value: "Electro House", label: "Electro House" },
    { value: "Techno", label: "Techno" },
    { value: "Peak Time Techno", label: "Peak Time Techno" },
    { value: "Melodic House Techno", label: "Melodic House Techno" },
    { value: "Progressive Techno", label: "Progressive Techno" },
    { value: "Trance", label: "Trance" },
    { value: "Future Bass", label: "Future Bass" },
    { value: "Latina", label: "Latina" },
    { value: "Alterative", label: "Alterative" },
    { value: "LoFi", label: "LoFi" },
    { value: "Ballad", label: "Ballad" },
    { value: "Others", label: "Others" },
  ];
  const LanguageOption = [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "Japanese", label: "Japanese" },
    { value: "Korean", label: "Korean" },
    { value: "Chinese", label: "Chinese" },
  ];

  useEffect(() => {
    onBlurFunction("art_work_image", uploadnewbeatImgAPI);
  }, [uploadnewbeatImgAPI]);

  const [editTable, setEditTable] = useState(true);

  useEffect(() => {
    console.log(trackDetails)
    if (userData.id == trackDetails?.trackData?.user_id._id) {
      setEditTable(false);
    } else {
      setEditTable(true);
    }
  }, [trackDetails]);

  useEffect(() => {
    if (trackDetails) {
      const role = trackDetails?.collaborators?.find(
        (collaborator: any) => collaborator.receiver_user_id === userData.id
      )?.role;
      dispatch(setUserRole(role));
    }
  }, [trackDetails]);

  useEffect(() => {
    if (trackId) {
      dispatch(getALlInvitedUserbyTrackProject(trackId));
      dispatch(fetchTrackDetails(trackSlugname));
      dispatch(SpotifyLinkUser(trackId));
    }
  }, [trackId, tabsValue,tabsValue2]);

  useEffect(() => {
    if (!parmsSlug.slugName) {
      openPopup("ProjectName", dispatch);
    }
  }, []);

  useEffect(() => {
    checkTabState2(tabValueChange);
    setChangeTableValue2(tabValueChange);
  }, [tabValueChange]);

  const navigate = useNavigate();
  const onBlurFunction = async (
    name: string,
    value: string | null | boolean
  ) => {
    if (value && previousTrackformData?.[name] !== value) {
      const response = await createTrackProject({ [name]: value, id: trackId });
      if (response.success) {
        // setId(response.data._id);
        dispatch(setTrackID(response.data._id));
        setPreviousTrackformData(response.data);
        // setslugName(response.data.slug_name);
        dispatch(setTrackSlugName(response.data.slug_name));
        dispatch(setProjectname(response.data.project_name));
      } else {
      }
    }
  };
  const onBlurFunction2 = async (
    name: string,
    value: string | null | boolean
  ) => {
    const response = await createTrackProject({ [name]: value, id: trackId });
    if (response.success) {
      // setId(response.data._id);
      dispatch(setTrackID(response.data._id));

      setPreviousTrackformData(response.data);
      // setslugName(response.data.slug_name);
      dispatch(setTrackSlugName(response.data.slug_name));
      dispatch(setProjectname(response.data.project_name));
    } else {
    }
  };
  const [message, setMessage] = useState<string>("");

  const OnClickMeatBalls = async (value: any, i: number, secondi?: any) => {
    if (value === "Change Alias") {
      dispatch(setSetelectedCollaborator(invitedUserTrackProject?.data?.[i]));
      openPopup("ChangeAlias", dispatch);
    } else if (value === "Remove") {
      dispatch(setSetelectedCollaborator(invitedUserTrackProject?.data?.[i]));
      openPopup("RemoveCollaborator", dispatch);
      // const data = {
      //   track_id: invitedUserTrackProject?.data?.[i].track_id,
      //   remove: "remove",
      //   invited_id: invitedUserTrackProject?.data?.[i]._id,
      // };
      // const response = await updateInvitedCollaboratorsTrackProject(data);
      // if (response.success) {
      //   dispatch(
      //     getALlInvitedUserbyTrackProject(trackId)
      //   );
      //   dispatch(
      //     fetchTrackDetails(trackSlugname)
      //   );
      // } else {
      //   setMessage(response.message);
      // }
    } else if (value === "Spotify Profile Link") {
      dispatch(setSetelectedCollaborator(invitedUserTrackProject?.data?.[i]));
      openPopup("SpotifyVerify", dispatch);
    } else if (value === "Delete") {
      dispatch(
        setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i])
      );
      dispatch(
        setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i])
      );
      dispatch(
        setSetelectedCollaborator(trackDetails?.collaborators?.[secondi])
      );
      openPopup("DeleteFile", dispatch);
    } else if (value === "Approve") {
      dispatch(
        setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i])
      );
      dispatch(
        setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i])
      );
      dispatch(
        setSetelectedCollaborator(trackDetails?.collaborators?.[secondi])
      );
      openPopup("ApproveFile", dispatch);
    } else if (value === "Decline") {
      dispatch(
        setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i])
      );
      dispatch(
        setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i])
      );
      dispatch(
        setSetelectedCollaborator(trackDetails?.collaborators?.[secondi])
      );
      openPopup("DeclineFile", dispatch);
    } else {
      dispatch(
        setuserListData({
          userName: invitedUserTrackProject?.data?.[i].display_name,
          userImg:invitedUserTrackProject?.data?.[i].profile_image?.[0]?.src,
          alluserListId: invitedUserTrackProject?.data?.[i].receiver_user_id,
          tabsValue: 0,
          chattingTab: "user",
          username:invitedUserTrackProject?.data?.[i].receiver_user_id
        })
      );
navigate(`/message-chat`);
    }
  };

  useEffect(() => {
    const getMissing = async () => {
      if (trackId) {
        const response: any = await getMissingStep(trackId);
        if (response) {
          setMissingStep(response.data);
        } 
      }
    };
    getMissing();
  }, [tabsValue]);

  useEffect(() => {
    const fetchFile = async () => {
      if (parmsSlug.slugName) {
        const response: any = await getFile(parmsSlug.slugName);
        if (response.success) {
          // setId(response.data.trackData._id);
          setTrackFormData({
            project_name: response.data.trackData.project_name,
            track_name: response.data.trackData.track_name,
            description: response.data.trackData.description,
            genre: response.data.trackData.genre,
            language: response.data.trackData.language,
            contains_explicit_language:
              response.data.trackData.contains_explicit_language,
            is_project_public: response.data.trackData.is_project_public,
            art_work_image: "",
            file_type: "",
            short_description: "",
            is_instrumental: response.data.trackData.is_instrumental,
            released_date:response.data.trackData.released_date?dateFormat(response.data.trackData.released_date):response.data.trackData.released_date
          });
          dispatch(setProjectname(response.data.trackData.project_name));

          dispatch(fetchTrackDetails(response.data.trackData.slug_name));
          // setslugName(response.data.trackData.slug_name);
          dispatch(setTrackSlugName(response.data.trackData.slug_name));
          dispatch(setTrackID(response.data.trackData._id));

          dispatch(
            setUploadNewBeatImg(
              response?.data?.trackData?.art_work_image[0]?.src
            )
          );
        } else {
          //console.log("Failed to fetch data");
        }
      } else {
        dispatch(setProjectname(""));
      }
    };
    fetchFile();
  }, [parmsSlug]);

  useEffect(() => {
    return () => {
      dispatch(setUploadNewBeatImg(""));
      dispatch(setTabValueChange(0));
      setEditTable(true);
      dispatch(setTrackID(""));
      dispatch(setUploadNewBeatImgAPI(""));
    };
  }, [dispatch]);

  const changeCredit = async (value: any, trackId: any, inviteuserid: any) => {
    const data = {
      track_id: trackId,
      credit_to: value,
      invited_id: inviteuserid,
    };
    const response = await updateInvitedCollaboratorsTrackProject(data);
    if (response.success) {
      dispatch(getALlInvitedUserbyTrackProject(trackId));
    } else {
      setMessage(response.message);
    }
  };

  const [invitedArr, setInvitedArr] = useState([]);
  useEffect(() => {
    if (invitedUserTrackProject && invitedUserTrackProject) {
      const updatedArray = invitedUserTrackProject?.invitedData?.map(
        (data: any, index: number) => ({
          img: data?.profile_image?.[0]?.src || "",
          name: data.receiver_name,
          subname: data.receiver_user_id.display_name,
          vocalist: data.role,
          percentage: data.percentage_offered,
          userSlug: data.slug_name,
          bluetik: data.is_bluetick,
          active:
            data.acceept_status === "rejected"
              ? "Declined"
              : data.acceept_status === "pending"
              ? "Active"
              : data.acceept_status === "accepted"
              ? "Accepted"
              : "Expired",
          id:
            data.acceept_status === "rejected"
              ? "DeclinedInviteSent"
              : data.acceept_status === "pending"
              ? "ActiveInviteSent"
              : data.acceept_status === "accepted"
              ? "AcceptedInviteSent"
              : "ExpiredInviteSent",

        userId:data._id      
        })
      );
      setInvitedArr(updatedArray);
    }
  }, [invitedUserTrackProject]);

  const handleDownload = async (e: any, item: any) => {
    e.preventDefault(); // Prevent default link behavior.

    const fileUrl = `${process.env.REACT_APP_IMGURL}/${item?.src}`;
    const fileName = item?.src || "download";

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link); 
      link.click();
      document.body.removeChild(link); 

     
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const [primaryArtist, setPrimaryArtist] = useState<any>([]);
  const [featuredArtist, setFeaturedArtist] = useState<any>([]);
  const [hiddenArtist, setHiddenArtist] = useState<any>([]);
  const[nonCredits,setNonCredits]=useState<any>([])

//   useEffect(() => {
//     if (Array.isArray(invitedUserTrackProject?.data)) {
//       const filteredPrimaryArtists = invitedUserTrackProject.data.filter(
//         (data: any) => data.credit_to === "Primary Artist"
//       );
//       setPrimaryArtist(filteredPrimaryArtists);

//       const filteredFeaturedArtist = invitedUserTrackProject.data.filter(
//         (data: any) => data.credit_to === "Featured Artist"
//       );
//       setFeaturedArtist(filteredFeaturedArtist);

//       const filteredHiddenArtist = invitedUserTrackProject.data.filter(
//         (data: any) => data.credit_to === "Hidden"
//       );
//       setHiddenArtist(filteredHiddenArtist);

//       const filteredNonCreditArtist = invitedUserTrackProject.data.slice(1).filter((data: any) => data.credit_to == null);

// setNonCredits(filteredNonCreditArtist);


//     }
//   }, [invitedUserTrackProject]);

const deleteproject=()=>{
    dispatch(setDeleteModalData({
        deleteID:trackId,
        deleteName:trackDetails?.trackData?.project_name,
        deleteImage:trackDetails?.trackData?.art_work_image?.[0]?.src || trackDetails?.trackData?.default_artwork,
        deleteSlugName:trackDetails?.trackData?.description,
        deleteType:"track"
      }))
      openPopup("DeleteModal", dispatch);
}

useEffect(() => {
  if (Array.isArray(invitedUserTrackProject?.data)) {
  
    const filteredPrimaryArtists = invitedUserTrackProject.data
    .slice(1)
      .filter((data: any) => data.credit_to === "Primary Artist")
      .sort((a: any, b: any) => {
       
        if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
        if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; 

       
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
      });
    setPrimaryArtist(filteredPrimaryArtists);

  
    const filteredFeaturedArtist = invitedUserTrackProject.data
    .slice(1)
      .filter((data: any) => data.credit_to === "Featured Artist")
      .sort((a: any, b: any) => {
       
        if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; 
        if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; 

        
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
      });
    setFeaturedArtist(filteredFeaturedArtist);

    
    const filteredHiddenArtist = invitedUserTrackProject.data
    .slice(1)
      .filter((data: any) => data.credit_to === "Hidden")
      .sort((a: any, b: any) => {
       
        if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; 
        if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; 

       
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
      });
    setHiddenArtist(filteredHiddenArtist);

   
    const filteredNonCreditArtist = invitedUserTrackProject.data
      .slice(1)
      .filter((data: any) => data.credit_to == null && data.role.toLowerCase() != "curator")
      .sort((a: any, b: any) => {
     
        if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1; 
        if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1; 

      
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
      });
    setNonCredits(filteredNonCreditArtist);
  }
}, [invitedUserTrackProject]);

  const imageUrl = uploadnewbeatImg?.includes("http")
    ? uploadnewbeatImg
    : `${process.env.REACT_APP_IMGURL}/${uploadnewbeatImg}`;
  const [changeTabValue, setChangeTableValue] = useState<number>(0);

  const [changeTabValue2, setChangeTableValue2] = useState<number>(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
    setChangeTableValue(value);
  };
  const checkTabState2 = (value: number) => {
    setTabValue2(value);
    setChangeTableValue2(value);
    dispatch(setTabValueChange(value));
  };


  const [mask, setMask] = useState("");
  const [dob, setDob] = useState("");
  const [dobError,setDOBError]=useState("")

  const validateDate = (value: string) => {
    if (value) {
      const [datePart, timePart] = value.split(" ");
      const [month, day, year] = datePart.split("/").map(Number);
      const [hour, minute] = timePart ? timePart.split(":").map(Number) : [0, 0];

      if (!day || !month || !year || month < 1 || month > 12 || day < 1 || day > 31||year>2050) {
        setDOBError("Invalid date (MM/DD/YYYY). Enter a valid date");
        return;
      }
      const isValidDate = (year: number, month: number, day: number) => {
        const date = new Date(year, month - 1, day);
        return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
      };
      if (!isValidDate(year, month, day)) {
        setDOBError("Invalid date. Please check the day and month.");
        return;
      }
      if (hour < 0 || hour > 23 || minute < 0 || minute > 59||!hour||isNaN(minute)||isNaN(hour)) {
        setDOBError("Invalid time. Please enter a valid time (HH:MM).");
        return;
      }
      const enteredDate = new Date(Date.UTC(year, month - 1, day, hour, minute));
      const currentDate = new Date();
      const currentUtcDate = new Date(Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate(),
        currentDate.getUTCHours(),
        currentDate.getUTCMinutes(),
        currentDate.getUTCSeconds()
      ));
      const fifteenDaysFromNow = new Date(currentUtcDate);
      fifteenDaysFromNow.setUTCDate(currentUtcDate.getUTCDate() + 15);
  
      if (enteredDate < fifteenDaysFromNow) {
        setDOBError("Date and time must be at least 15 days in the future.");
        return;
      }
      setDOBError("");
      onBlurFunction("released_date", value);
    }
  };

  const handleFocus = () => {
    setMask("MM/DD/YYYY HH:MM");
  };
  const handleBlur = () => {
    setMask("");
  };


  return (
    <div className="container container-xxl min-height-cover">
      <DropworksInput
        placeholder="Project Name"
        // disabled={!id}
        disabled={trackDetails?.trackData?.is_released || editTable}
        className={`bg-transparent project-font text-capitalize  ps-0 ${
          trackDetails?.trackData?.is_released || editTable
            ? "disable-item"
            : ""
        }`}
        defaultValue={pname.Data ? pname.Data : trackformData.project_name}
        value={trackformData.project_name}
        onBlur={() =>
          onBlurFunction("project_name", trackformData.project_name)
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTrackFormData((pre: any) => ({
            ...pre,
            project_name: e.target.value,
          }))
        }
        maxLength={30}
      />
      <TextfieldSM className=" mx-0  uni-mb-24 fg-color">
        Note: If you don't enter a project name, the default name will be{" "}
        {trackDetails?.trackData?.user_id?.username || "UserName"}'s Project'
      </TextfieldSM>

      <Tabs
        id="14"
        tabsTitle={["Overview", "Collaborators", "File Center", "Drop"]}
        className="uni-mb-40"
        // disabled={!id}
        checkTabState={checkTabState}
        changeTabValue={changeTabValue}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0 track-overview-col">
                <Heading4 className="uni-mb-16">Overview</Heading4>
                <TextfieldLG className="mt-0">
                  {editTable ? "Track's basic information":"Fill out all information"}
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40 mt-0">
                  You can navigate among sections any time
                </TextfieldSM>

                <TextfieldLG className="uni-mb-16 mt-0 content-sunheading">
                  Track name
                </TextfieldLG>
                <DropworksInput
                  placeholder="Name"
                  className={`uni-mb-32 form-control  ${
                    trackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  } ${
                    clickTab && (trackformData.track_name==""||trackformData.track_name==null)
                      ? "is-invalid b-red-2"
                      : ""
                  }`}
                  disabled={trackDetails?.trackData?.is_released || editTable}
                  value={trackformData.track_name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      track_name: e.target.value,
                    }))
                  }
                  maxLength={30}
                  onBlur={() =>
                    onBlurFunction("track_name", trackformData.track_name)
                  }
                />
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Descriptions
                </TextfieldLG>
                <DropworksTextArea
                  className={`uni-mb-32 track-textarea ${
                    trackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  }`}
                  placeholder="Description (Optional)"
                  // disabled={!id}

                  disabled={trackDetails?.trackData?.is_released || editTable}
                  rows={2}
                  hideIcon={true}
                  value={trackformData.description}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      description: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    onBlurFunction("description", trackformData.description)
                  }
                />

<TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Release Date
                </TextfieldLG>
                <IMaskInput
                id="dob"
                mask={mask}
                disabled={trackDetails?.trackData?.is_released || editTable}
                placeholder="(MM/DD/YYYY HH:MM) (Optional)"
                className={`custom_inp form-control ${
                  trackDetails?.trackData?.is_released || editTable
                    ? "disable-item disable-field"
                    : ""
                } ${
                dobError
                    ? "is-invalid b-red-2"
                    : ""
                } 
                `}
                value={trackformData.released_date}
                onAccept={(value:any) => {
                  setTrackFormData((pre: any) => ({
                    ...pre,
                    released_date: value,
                  }));
                  validateDate(value);
                }}
                lazy={false}
                definitions={{
                  M:{
                    mask: /(.*\d)/,
                    placeholderChar: "M"
                  },
                  D:{
                    mask: /(.*\d)/,
                    placeholderChar: "D"
                  },
                  Y:{
                    mask: /(.*\d)/,
                    placeholderChar: "Y"
                  },
                  H: {
                    mask: /(.*\d)/,
                    placeholderChar: "H"
                  },
                  m: {
                    mask: /(.*\d)/,
                    placeholderChar: "M"
                  }
         
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {dobError && <p className="invalid-feedback ">{dobError}</p>}
 
 
                <TextfieldLG className="uni-mb-16 content-sunheading uni-mt-32">

                  Genre
                </TextfieldLG>
                {/* <DropworksSelect className="uni-mb-32"></DropworksSelect> */}

                <DropworksDisableButton
                  className={`custom-select-dropdown track-overview-dropdown form-control uni-mb-56  ${
                    trackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  }
                  ${
                    clickTab && (trackformData.genre==""||trackformData.genre==null)
                      ? "is-invalid b-red-2"
                      : ""
                  }
                  `}
                  // disabled={!id}
                  disabled={trackDetails?.trackData?.is_released || editTable}
                >
                  <SelectArrowDropdown
                    options={GenreOptions}
                    placeHolderValue="Select"
                    value={trackformData.genre}
                    onChangeValue={(value: null | string) => {
                      onBlurFunction("genre", value);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        genre: value,
                      }));
                    }}
                  ></SelectArrowDropdown>
                </DropworksDisableButton>

                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Language
                </TextfieldLG>
                <DropworksDisableButton
                  className={`custom-select-dropdown track-overview-dropdown uni-mb-56 form-control ${
                    trackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  }
                   ${
                    clickTab && (trackformData.language==""||trackformData.language==null)
                      ? "is-invalid b-red-2"
                      : ""
                  }
                  `}
                  // disabled={!id}
                  disabled={trackDetails?.trackData?.is_released || editTable}
                >
                  <SelectArrowDropdown
                    options={LanguageOption}
                    placeHolderValue="Select"
                    value={trackformData.language}
                    onChangeValue={(value: null | string) => {
                      onBlurFunction("language", value);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        language: value,
                      }));
                    }}
                  ></SelectArrowDropdown>
                </DropworksDisableButton>
                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Contains Explicit Language
                  </TextfieldSM>

                  <DropworksSwitch
                    checked={trackformData.contains_explicit_language}
                    // disabled={!id}
                    disabled={trackDetails?.trackData?.is_released || editTable}
                    className={`${
                      trackDetails?.trackData?.is_released || editTable
                        ? "disable-item "
                        : ""
                    }`}
                    onChange={(e: boolean) => {
                      onBlurFunction2("contains_explicit_language", e);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        contains_explicit_language: e,
                      }));
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Make this project public
                    <CustomTooltip
                      title="Activate this setting if you want your project to be visible by other users. Only the artwork and current collaborators will be displayed."
                      position="right"
                    >
                      <RiQuestionFill className="uni-ml-16 track-tooltip" />
                    </CustomTooltip>
                  </TextfieldSM>
                  <DropworksSwitch
                    checked={trackformData.is_project_public}
                    className={`${
                      trackDetails?.trackData?.is_released || editTable
                        ? "disable-item"
                        : ""
                    }`}
                    // disabled={!id}
                    disabled={trackDetails?.trackData?.is_released || editTable}
                    onChange={(e: boolean) => {
                      onBlurFunction2("is_project_public", e);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        is_project_public: e,
                      }));
                    }}
                  />
                </div>

                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Only Instrumental
                    {/* <CustomTooltip
                      title="Select only instrumental file"
                      position="right"
                    >
                      <RiQuestionFill className="uni-ml-16 track-tooltip" />
                    </CustomTooltip> */}
                  </TextfieldSM>
                  <DropworksSwitch
                    checked={trackformData.is_instrumental}
                    className={`${
                      trackDetails?.trackData?.is_released || editTable
                        ? "disable-item"
                        : ""
                    }`}
                    // disabled={!id}
                    disabled={trackDetails?.trackData?.is_released || editTable}
                    onChange={(e: boolean) => {
                      onBlurFunction2("is_instrumental", e);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        is_instrumental: e,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 d-flex justify-content-end p-0">
                <div>
                  <TextfieldLG className="me-0 ml-auto beat-empty-artwork ">
                    Artwork
                  </TextfieldLG>
                  <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-32 modal-second-head-font">
                    Use a 1:1 aspect ratio with dimensions of 3000px or higher
                  </TextfieldLG>
                  <div className={`beatEmpty_uploadbox2 flex-column mb-3 ${
                    clickTab && !uploadnewbeatImg
                      ? "is-invalid b-red-2"
                      : ""
                  }`}>
                    {uploadnewbeatImg ? (
                      <ButtonComponent
                        id="trackartwork"
                        className={`w-100 h-100 ${
                          trackDetails?.trackData?.is_released || editTable
                            ? "disable-item"
                            : ""
                        }`}
                        disabled={
                          trackDetails?.trackData?.is_released || editTable
                        }
                      >
                        <img
                          src={imageUrl}
                          className="upload-img-2 w-100 h-100 "
                        />
                      </ButtonComponent>
                    ) : (
                      <>
                        {/* <ButtonComponent id="trackartwork" disabled={!id}> */}
                        <ButtonComponent
                          id="trackartwork"
                          className={` ${
                            trackDetails?.trackData?.is_released || editTable
                              ? "disable-item"
                              : ""
                          }`}
                          disabled={
                            trackDetails?.trackData?.is_released || editTable
                          }
                        >
                          <img src={iconImg} alt="Upload Icon " />
                        </ButtonComponent>
                        <label className="mt-4"> Track Artwork</label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>,
          <>
<div className="d-flex invite-acexp flex-wrap uni-mb-32">
        <button
          className={`invite-acexp-btn ${tabsValue2 ==0 ? "active" : ""}`}
          onClick={()=>checkTabState2(0)}
        >
          Team
        </button>
        <button
          className={`invite-acexp-btn ${tabsValue2 == 1 ? "active" : ""}`}
          onClick={()=>checkTabState2(1)}
        >
          Invited
        </button>
      </div>
            <TabsComponent
              tabsValue={tabsValue2}
              components={[
                <>
                  <div className="">
                    <Heading4 className="uni-mb-16">Collaborators</Heading4>
                    <TextfieldLG>{editTable?"Check your Team":"Manage your Collaborators"} </TextfieldLG>
                    <TextfieldSM className="fg-color uni-mb-40">
                      You can navigate among sections any time
                    </TextfieldSM>

                    <div className="d-flex row align-items-center collaborators-content uni-mb-32 flex-wrap">
                      {
                        invitedUserTrackProject?.data?.length > 0
                          ? invitedUserTrackProject?.data?.map(
                              (ele: any, i: number) => {
                                return (
                                  <div className="collaborators-content-under col-6 p-0 uni-mb-32">
                                    <h2 className="text-capitalize">
                                      {ele.role.toLowerCase() ==
                                      "cover designer"
                                        ? "Artwork Designer"
                                        : ele.role}
                                      {ele.isyellow_tick == 1 || i == 0 ? (
                                        <YelloTik className="uni-ml-8" />
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                    <div className="d-flex align-items-center uni-py-16">
                                      <img
                                        src={`${process.env.REACT_APP_IMGURL}/${ele.profile_image?.[0]?.src}`}
                                        onClick={() =>userData.id == ele?.receiver_user_id ?
                                          navigate(
                                            `/profile`
                                          ) : 
                                          navigate(
                                            `/user-profile/${ele.slug_name}`
                                          )
                                        }
                                        onError={(e) => {
                                          e.currentTarget.src = UserIcon;
                                        }}
                                        className="img-king uni-mr-16 cursor-pointer"
                                        alt=""
                                      />
                                      <div
                                        className="uni-mr-16 cursor-pointer d-flex"
                                        onClick={() =>userData.id == ele?.receiver_user_id ?
                                          navigate(
                                            `/profile`
                                          ) : 
                                          navigate(
                                            `/user-profile/${ele.slug_name}`
                                          )
                                        }
                                      >
                                        <CustomTooltip
                                          title={ele?.display_name}
                                          className="w-auto"
                                          position={"top"}
                                        >
                                          <Heading5 className="king-roland-text m-0 text-capitalize">
                                          {ele.display_name?.length > 11
                                              ? ele.display_name.substring(
                                                  0,
                                                  11
                                                ) + "..."
                                              : ele.display_name}

                                            {/* <BlueTik position="top" /> */}
                                          </Heading5>
                                        </CustomTooltip>
                                        {ele.is_bluetick == 1 ? (
                                          <BlueTik
                                            position="top"
                                            className="uni-ml-8"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {editTable ? (
                                        userData.id == ele?.receiver_user_id ? (
                                          <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                                            {i == 0
                                              ? ele.percentage_offered
                                              : ele.percentage_offered}
                                            %
                                          </DropworksNormalButton>
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                                          {i == 0
                                            ? ele.percentage_offered
                                            : ele.percentage_offered}
                                          %
                                        </DropworksNormalButton>
                                      )}

                                      {editTable ||
                                      ele?.role?.toLocaleLowerCase() ==
                                        "curator" ||
                                      ele?.role?.toLocaleLowerCase() ==
                                        "cover designer" ||
                                      i == 0 ? (
                                        ""
                                      ) : (
                                        <DropworksDisableButton
                                          disabled={
                                            trackDetails?.trackData
                                              ?.is_released ||
                                            editTable ||
                                            ele?.role?.toLocaleLowerCase() ==
                                              "curator" ||
                                            ele?.role?.toLocaleLowerCase() ==
                                              "cover designer" ||
                                            i == 0
                                          }
                                          className={`custom-selectarrow uni-mr-16 ${clickTab && ele.credit_to==null ? "is-invalid b-red-2":""}` }
                                        >
                                          <SelectArrowDropdown
                                            options={options}
                                            placeHolderValue="Credit"
                                            className="credit-selectdropdown"
                                            value={ele.credit_to}
                                            onChangeValue={(
                                              value: null | string
                                            ) => {
                                              changeCredit(
                                                value,
                                                ele.track_id,
                                                ele._id
                                              );
                                            }}
                                          ></SelectArrowDropdown>
                                        </DropworksDisableButton>
                                      )}

                                      {trackDetails?.trackData?.is_released ||
                                      editTable ? (
                                        userData.id == ele?.receiver_user_id &&
                                        !trackDetails?.trackData
                                          ?.is_released ? (
                                          <MeatballsMenuDropdown
                                            arr={MeatballsMenuOptionsSpotify}
                                            pagename="call-funtion"
                                            onClickOptions={(stelected: any) =>
                                              OnClickMeatBalls(stelected, i)
                                            }
                                            className="custom-metaballs"
                                          ></MeatballsMenuDropdown>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        <MeatballsMenuDropdown
                                          arr={
                                            i == 0
                                              ? MeatballsMenuOptions
                                              : 
                                               ele?.isyellow_tick 
                                              ? MeatballsMenuOptions21
                                              : ele?.role?.toLocaleLowerCase() ==
                                              "curator"? MeatballsMenuOptions2.filter(option => option !== "Change Alias") :MeatballsMenuOptions2
                                          }
                                          pagename="call-funtion"
                                          onClickOptions={(stelected: any) =>
                                            OnClickMeatBalls(stelected, i)
                                          }
                                          className="custom-metaballs"
                                        ></MeatballsMenuDropdown>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : ""
                        // (
                        //   <TextfieldLG>No Collaborators invited.</TextfieldLG>
                        // )
                      }

                      <div className="p-0 col-6">
                        <div className=" collaborators-content-under pe-0 uni-mt-16">
                          {trackDetails?.trackData?.is_released || editTable ? (
                            <></>
                          ) : (
                            <ButtonComponent id="InviteModal8">
                              <div className={`track-plus-icon`}>
                                <span className="under-plus-icon">+</span>
                              </div>
                            </ButtonComponent>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>,
                <>
                  <div className="invite-usericon">
                    {invitedArr?.length > 0 ? (
                      <InviteFriends
                        arr={invitedArr}
                        showCondition={editTable}
                        page="track"
                      />
                    ) : (
                      <TextfieldLG>No collaborators are invited.</TextfieldLG>
                    )}
                  </div>
                </>,
              ]}
            />
          </>,
          <>
            <Heading4 className="uni-mb-16">File Center</Heading4>
            <div className="d-flex">
              <div className="uni-mr-56">
                <TextfieldLG className="mt-0">
                  Upload and manage your files
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40">
                  You can navigate among sections any time
                </TextfieldSM>
              </div>
              {userRole?.toLocaleLowerCase() == "curator" ||
              userRole?.toLocaleLowerCase() == "investor" ||
              trackDetails?.trackData?.is_released ? (
                ""
              ) : (
                <ButtonComponent
                  id="UploadModal"
                  disabled={
                    trackDetails?.trackData?.is_released ||
                    userRole?.toLocaleLowerCase() == "curator" ||
                    userRole?.toLocaleLowerCase() == "investor"
                  }
                >
                  <DropworksNormalButton
                    className={
                      trackDetails?.trackData?.is_released ||
                      userRole?.toLocaleLowerCase() == "curator" ||
                      userRole?.toLocaleLowerCase() == "investor"
                        ? " custom-inactive-btn text-secondary  disable-item"
                        : ""
                    }
                  >
                    Upload
                  </DropworksNormalButton>
                </ButtonComponent>
              )}
            </div>

            <div className=" row file-center-main uni-mb-32 ">
              {trackDetails?.collaborators?.map((data: any, i: any) => {
                return (
                  <div className=" col-6 p-0 uni-mb-32">
                    <h2 className="uni-mb-16 text-capitalize">
                      {data?.role.toLowerCase() == "cover designer"
                        ? "Artwork Designer"
                        : data?.role}
                      {data.isyellow_tick == 1 || i === 0 ? (
                        <YelloTik className="uni-ml-8" />
                      ) : (
                        ""
                      )}
                    </h2>

                    <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn cursor-pointer"  onClick={() =>userData.id == data?.receiver_user_id ?
                                          navigate(
                                            `/profile`
                                          ) : 
                                          navigate(
                                            `/user-profile/${data?.slug_name}`
                                          )
                                        }>
                      <img
                        src={`${process.env.REACT_APP_IMGURL}/${data.profile_image[0]?.src}`}
                        className="img-king"
                        alt=""
                      />
                      <div>
                        <Heading5 className="fw-700 d-flex align-items-center text-capitalize">
                          {data?.display_name}
                          {data?.is_bluetick == 1 ? (
                            <BlueTik position="top" className="uni-ml-8" />
                          ) : (
                            ""
                          )}
                          {/* <BlueTik className="uni-ml-8" />{" "} */}
                        </Heading5>
                      </div>
                    </div>
                    {data.upload_file && data.upload_file.length > 0 && (
                      <div className="producer-border producer-main">
                        {data.upload_file?.map((data2: any, index: any) => {
                          return (
                            <>
                              <div className="d-flex align-items-center  justify-content-between uni-mt-16 border-style">
                                <div>
                                  <TextfieldSM className="fs-color-g3 m-0">
                                    Type:{" "}
                                    <span className="fw-400-wh">
                                      {data2?.file_type}
                                    </span>
                                  </TextfieldSM>
                                  <TextfieldSM className="fs-color-g3 m-0">
                                    Description:
                                    <span className="fw-400-wh">
                                      {" "}
                                      {data2?.short_description}
                                    </span>
                                  </TextfieldSM>
                                  <TextfieldSM className="fs-color-g3 m-0">
                                    Date:{" "}
                                    <span className="fw-400-wh">
                                      {" "}
                                      {new Date(
                                        data2.upload_date
                                      ).toLocaleString("en-US", {
                                        month: "2-digit",
                                        day: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })}
                                    </span>
                                  </TextfieldSM>
                                </div>
                                <div className="d-flex downloadicon-metaballs-file-center">
                                  {userData.id ==
                                    trackDetails?.trackData?.user_id._id ||
                                  trackDetails?.collaborators?.find(
                                    (collaborator: any) =>
                                      collaborator.receiver_user_id ===
                                        userData.id &&
                                      collaborator.isyellow_tick === 1
                                  ) ||
                                  userData.id == data?.receiver_user_id ? (
                                    <>
                                      <h5>
                                        {/* <a href={`${process.env.REACT_APP_IMGURL}/${data2?.src}`}   download={data2?.fileName}>
                            <img src={downloadIcon} alt="Download music" />
                           </a> */}
                                        <a
                                          onClick={(e) =>
                                            handleDownload(e, data2)
                                          }
                                          className="cursor-pointer"
                                        >
                                          <img
                                            src={downloadIcon}
                                            alt="Download music cursor-pointer"
                                          />
                                        </a>
                                      </h5>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <h5>
                                    {/* data2?.verification_status=="pending"||i==0 ? */}
                                    {trackDetails?.trackData?.is_released ||
                                    editTable ? (
                                      editTable &&
                                      userData.id == data?.receiver_user_id &&
                                      data2?.verification_status ==
                                        "pending" ? (
                                        <MeatballsMenuDropdown
                                          className="custom-metaballs2"
                                          pagename="call-funtion"
                                          onClickOptions={(stelected: any) =>
                                            OnClickMeatBalls(
                                              stelected,
                                              index,
                                              i
                                            )
                                          }
                                          arr={MeatballsMenuOptions31}
                                        ></MeatballsMenuDropdown>
                                      ) : (
                                        ""
                                      )
                                    ) : data2?.verification_status !=
                                        "pending" && i != 0 ? (
                                      ""
                                    ) : (
                                      <>
                                        <MeatballsMenuDropdown
                                          className="custom-metaballs2"
                                          pagename="call-funtion"
                                          onClickOptions={(stelected: any) =>
                                            OnClickMeatBalls(
                                              stelected,
                                              index,
                                              i
                                            )
                                          }
                                          arr={
                                            i == 0
                                              ? MeatballsMenuOptions31
                                              : MeatballsMenuOptions3
                                          }
                                        ></MeatballsMenuDropdown>
                                      </>
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </>,
          <>
            <Heading4 className="uni-mb-16">Drop</Heading4>

            <TextfieldLG>Review all information before Drop </TextfieldLG>
            <TextfieldSM className="fg-color uni-mb-40">
              {" "}
              You can navigate among sections any time
            </TextfieldSM>

            <div className="d-flex uni-mb-40 justify-content-between align-items-end release-content-border">
              <div className="d-flex align-items-end">
                <img
                  src={uploadnewbeatImg ? imageUrl : `${process.env.REACT_APP_IMGURL}/${trackDetails?.trackData?.default_artwork}`}
                  className="uni-mr-24 treeImage-track"
                />
                <div>
                  <Heading1 >
                    {trackformData?.track_name || trackformData?.project_name}{" "}
                  </Heading1>
                  <TextfieldMD className="fc-gray4 text-capitalize">
                    <span className="cursor-pointer"  
                      onClick={() =>userData.id == trackDetails?.collaborators?.[0]?.receiver_user_id ?
                        navigate(
                          `/profile`
                        ) : 
                        navigate(
                          `/user-profile/${trackDetails?.collaborators?.[0]?.slug_name}`
                        )
                      }
                      
                      >
                    {trackDetails?.collaborators?.[0]?.display_name }
                    </span>

                    {primaryArtist.length > 0
                      ? primaryArtist.map((data: any, index: number) => (
                          <span className="cursor-pointer"
                          onClick={() =>userData.id == data?.receiver_user_id ?
                            navigate(
                              `/profile`
                            ) : 
                            navigate(
                              `/user-profile/${data?.slug_name}`
                            )
                          }
                          
                          >
                          {","}{" "}{data.display_name}
                          
                          </span>
                        ))
                      : ""}
                    {featuredArtist.length > 0
                      ? featuredArtist.map((data: any, index: number) => (
                          <span className="cursor-pointer"     onClick={() =>userData.id == data?.receiver_user_id ?
                            navigate(
                              `/profile`
                            ) : 
                            navigate(
                              `/user-profile/${data?.slug_name}`
                            )
                          }>
                           {","}{" "}  {data.display_name}
                            
                          </span>
                        ))
                      : ""}
                    {hiddenArtist.length > 0
                      ? hiddenArtist.map((data: any, index: number) => (
                          <span className="cursor-pointer"     onClick={() =>userData.id == data?.receiver_user_id ?
                            navigate(
                              `/profile`
                            ) : 
                            navigate(
                              `/user-profile/${data?.slug_name}`
                            )
                          }>
                           {","}{" "}  {data.display_name}
                           
                          </span>
                        ))
                      : ""}

{nonCredits.length > 0
                      ? nonCredits.map((data: any, index: number) => (
                          <span className="cursor-pointer"     onClick={() =>userData.id == data?.receiver_user_id ?
                            navigate(
                              `/profile`
                            ) : 
                            navigate(
                              `/user-profile/${data?.slug_name}`
                            )
                          }>
                         {","}{" "}  {data.display_name} 
                            {/* {index !== hiddenArtist.length - 1 && ","}{" "} */}
                          </span>
                        ))
                      : ""}
                  </TextfieldMD>

                  {trackDetails && trackDetails?.trackData?.genre ? (
                    <UserLable>{trackDetails?.trackData?.genre}</UserLable>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex">
                {editTable || trackDetails?.trackData?.is_released ? (
                  editTable &&
                  !trackDetails?.trackData?.is_released &&
                  invitedUserTrackProject?.data?.find(
                    (ele: any) => ele.receiver_user_id == userData.id
                  )?.isyellow_tick == 0 ? (
                    <ButtonComponent id="LeaveProject">
                      <DropworksNormalButton className="release-btn3 mb-0 uni-mr-8">
                        Leave Project
                      </DropworksNormalButton>
                    </ButtonComponent>
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    {/* <ButtonComponent id="DeleteModal"> */}
                      <DropworksNormalButton className="release-btn3 mb-0 uni-mr-8" onClick={deleteproject} >
                        Delete
                      </DropworksNormalButton>
                    {/* </ButtonComponent> */}
                  </>
                )}
                {missingStep &&
                missingStep?.[0]?.pageOne == true &&
                missingStep?.[0]?.pageTwo == true &&
                missingStep?.[0]?.pageThree == true ? (
                  <>
                    {trackDetails?.trackData?.is_released ? (
                      <DropworksNormalButton
                        className="release-btn mb-0 disable-item"
                        disabled
                      >
                        Released
                      </DropworksNormalButton>
                    ) : spotifyUser && spotifyUser?.length > 0 ? (
                      <ButtonComponent
                        id="LinkConfirmation"
                        disabled={
                          trackDetails?.trackData?.is_released || editTable
                        }
                      >
                        <DropworksNormalButton className="release-btn2 mb-0">
                          Release
                        </DropworksNormalButton>
                      </ButtonComponent>
                    ) : (
                      <ButtonComponent
                        id="ReleaseModal"
                        disabled={
                          trackDetails?.trackData?.is_released || editTable
                        }
                      >
                        <DropworksNormalButton className="release-btn2 mb-0">
                          Release
                        </DropworksNormalButton>
                      </ButtonComponent>
                    )}
                  </>
                ) : (
                  <>
                    {trackDetails?.trackData?.is_released ? (
                      <DropworksNormalButton
                        className="release-btn mb-0 disable-item"
                        disabled
                      >
                        Released
                      </DropworksNormalButton>
                    ) : (
                      <DropworksNormalButton
                      onClick={() => { 
                        setClickDisableRelease(true)
                        window.scrollTo({
                          top: document.documentElement.scrollHeight,
                          behavior: "smooth",
                        });
                      }}
                      disabled={ editTable}
                        className={`release-btn mb-0 cursor-pointer ${editTable?"disable-item":""}`}
                      >
                        Release
                      </DropworksNormalButton>
                    )}
                  </>
                )}
              </div>
            </div>
            {(missingStep && missingStep?.[0]?.pageOne == false) ||
            missingStep?.[0]?.pageTwo == false ||
            missingStep?.[0]?.pageThree == false 
            
            ? (
              <>
                <div className={`uni-p-40 missing-steps ${clickDisableRelease?"is-invalid b-red-2":""}`}>
                  <h2 className="missing-text  uni-mb-24">
                    {/* <img src={exclamationTriangle} className="uni-mr-16" /> */}
                    <span className="textFeild-error textFeild-error-missing"></span>
                    Missing Steps
                  </h2>

                  {missingStep && missingStep?.[0]?.pageOne == false ? (
                    <>
                      <TextfieldLG
                        className="fc-gray4 uni-mr-8 mt-0 cursor-pointer textfield-track"
                        onClick={() => {
                          {
                            setClickTab(true);
                            checkTabState(0);
                            setChangeTableValue(0);
                          }
                        }}
                      >
                        Fill out all required information{" "}
                        <img src={rightArrow} className="cursor-pointer" />
                      </TextfieldLG>
                    </>
                  ) : (
                    <></>
                  )}
                  {missingStep && missingStep?.[0]?.pageTwo == false ? (
                    <>
                      <TextfieldLG
                        className="fc-gray4 uni-mr-8  mt-0 cursor-pointer textfield-track"
                        onClick={() => {
                          {
                            setClickTab(true);
                            checkTabState(1);
                            setChangeTableValue(1);
                          }
                        }}
                      >
                        Credit Collaborators{" "}
                        <img src={rightArrow} className="cursor-pointer" />
                      </TextfieldLG>
                    </>
                  ) : (
                    <></>
                  )}
                  {missingStep && missingStep?.[0]?.pageThree == false ? (
                    <>
                      <TextfieldLG
                        className="fc-gray4 uni-mr-8  mt-0 cursor-pointer textfield-track"
                        onClick={() => {
                          {
                            setClickTab(true);
                            checkTabState(2);
                            setChangeTableValue(2);
                          }
                        }}
                      >
                        Upload final Track file{" "}
                        <img src={rightArrow} className="cursor-pointer" />
                        {missingStep?.[0]?.pageThreeDetails?.final == false ? (
                          <>
                            <br />* Final mix and master
                          </>
                        ) : (
                          ""
                        )}
                        {missingStep?.[0]?.pageThreeDetails?.mix == false ? (
                          <>
                            <br />* Mix master minus one
                          </>
                        ) : (
                          ""
                        )}
                      </TextfieldLG>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>,
        ]}
      />
      {parmsSlug.slugName && trackId && tabsValue !=3 &&(
        <div className="chat-img">
          <ButtonComponent id="MessageChatModal">
            <img src={chatBubble} />
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};
export default view;
