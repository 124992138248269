import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { trackDetails } from "./beatSlice";
import axios from "axios";
import { getformatDate } from "../../app/shared/utils/localStorage";


interface musicPlayerData {
  musicName:string,
  musicAudioSrc:string,
  musicImgSrc:string,
  showOptions:boolean,
  musicLiked:boolean,
  showMusicBar:boolean,
  musicCreator:any,
  musicOwnedByMe:boolean,
  musicDescription:string,
  musicId:string
  musicPath:string
  musicType:string
  musicPageTrackData?:trackDetails
  }

  interface deleteModal {
    deleteID:string
    deleteName:string
    deleteImage:string
    deleteSlugName:string
    deleteType:string
  }
  interface musicPageData {
    musicPageName:string,
    musicPageAudioSrc:string,
    musicPageImgSrc:string,
    showPageOptions:boolean,
    musicPageLiked:boolean,
    showPageMusicBar:boolean,
    musicPageCreator:string,
    musicPageOwnedByMe:boolean,
    musicPageDescription:string,
    musicPageId:string
    musicPagePath:string
    musicPageType:string
  }
  
  interface MusicSlice {
    Data:boolean,
    musicTrack:string,
    musicTrackData:any,
    musicPageData:musicPageData,
    musicPlayer :musicPlayerData,
    deleteModal:deleteModal,
    nextTracks:any,
    nextBeats:any
  }

const initialState : MusicSlice = {
  Data: false, 
  musicTrack:"",
  musicTrackData:"",
  nextTracks:[],
  nextBeats:[],
  musicPlayer:{
    musicName:"",
    musicAudioSrc:"",
    musicImgSrc:"",
    showOptions:false,
    musicLiked:false,
    showMusicBar:false,
    musicCreator:"",
    musicOwnedByMe:false,
    musicDescription:"",
    musicId:"",
    musicPath:"",
    musicType:""
    },
  musicPageData:{
    musicPageName:"",
    musicPageAudioSrc:"",
    musicPageImgSrc:"",
    showPageOptions:false,
    musicPageLiked:false,
    showPageMusicBar:false,
    musicPageCreator:"",
    musicPageOwnedByMe:false,
    musicPageDescription:"",
    musicPageId:"",
    musicPagePath:"",
    musicPageType:""
  },
  deleteModal:{
    deleteID:"",
    deleteName:"",
    deleteImage:"",
    deleteSlugName:"",
    deleteType:""
  }
};

export const fetchNextTracks = createAsyncThunk(
  "NextTrrack",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/playCount/get-all-track-audio-paly`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
 
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);
 
 
export const fetchNextBeats = createAsyncThunk(
  "NextBeats",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/playCount/get-all-beat-audio-paly`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
 
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);

const musicSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<boolean>) => {
      state.Data = action.payload; 
    },
    setMusicTrack: (state, action: PayloadAction<string>) => {
      state.musicTrack = action.payload; 
    },
    setMusicTrackData: (state, action: PayloadAction<any>) => {
      state.musicTrackData = action.payload; 
    },
   setMusicPlayerData:(state,action:PayloadAction<musicPlayerData>)=>{
     state.musicPlayer=action.payload;
   },
   setMusicPageData:(state,action:PayloadAction<musicPageData>)=>{
    state.musicPageData=action.payload;
  },
  setDeleteModalData:(state,action:PayloadAction<deleteModal>)=>{
    state.deleteModal=action.payload;
  },
  setMusicPageDataLike:(state,action:PayloadAction<boolean>)=>{
    state.musicPageData.musicPageLiked=action.payload
  },
  setMusicPlayerDataLike:(state,action:PayloadAction<boolean>)=>{
    state.musicPlayer.musicLiked=action.payload
  },
  
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchNextTracks.pending, (state) => {
        state.nextTracks = [];
      })
      .addCase(fetchNextTracks.fulfilled, (state, action) => {
        const musicPlayerId = state.musicPlayer.musicId; 
        const tracks = action.payload.map((data: any, index: number) => ({
          musicName: data?.track_name,
          musicAudioSrc: data.upload_file.filter((ele: any) => ele.file_type === "Final mix and master")?.[0]?.src,
          musicImgSrc: data?.art_work_image?.[0]?.src,
          showOptions: true,
          musicLiked: Boolean(data?.favoriteData?._id),
          showMusicBar: true,
          musicCreator: data?.collaborators,
          musicOwnedByMe: false,
          musicDescription: data?.description,
          musicId: data?._id,
          musicPath: `/track-page/${data?.slug_name}`,
          musicType: "track",
          musicPageTrackData: {
            trackDetailsReleaseDate:getformatDate(data?.created_at),
            trackDeatilsCreatorList:[data?.creator_user_name],
            trackDetailsUniqueId:data?.unique_id
            }
        }));
        const matchedTrackIndex = tracks.findIndex((track:any) => track.musicId === musicPlayerId);
      
        if (matchedTrackIndex !== -1) {
          const matchedTrack = tracks[matchedTrackIndex];
          tracks.splice(matchedTrackIndex, 1); 
          tracks.unshift(matchedTrack); 
        }
        state.nextTracks = tracks; 
      })
      .addCase(fetchNextTracks.rejected, (state, action) => {
        state.nextTracks = [];
      });

      builder
      .addCase(fetchNextBeats.pending, (state) => {
        state.nextBeats = [];
      })
      .addCase(fetchNextBeats.fulfilled, (state, action) => {
        const musicPlayerId = state.musicPlayer.musicId; 
        const beats = action.payload.map((data: any, index: number) => ({
          musicName: data?.beat_name,
          musicAudioSrc: data?.audio_file?.[0]?.src,
          musicImgSrc: data?.art_work_image?.[0]?.src,
          showOptions: true,
          musicLiked: Boolean(data?.favoriteData?._id),
          showMusicBar: false,
          musicCreator: data?.user_id?.firstname + " " + data?.user_id?.lastname,
          musicOwnedByMe: false,
          musicDescription: data?.description,
          musicId: data?._id,
          musicPath: `beats-page/${data?.beat_slug_name}`,
          musicType: "beat"
        }));
        const matchedBeatIndex = beats.findIndex((beat:any) => beat.musicId === musicPlayerId);
        if (matchedBeatIndex !== -1) {
          const matchedBeat = beats[matchedBeatIndex];
          beats.splice(matchedBeatIndex, 1); 
          beats.unshift(matchedBeat); 
        }
      
        state.nextBeats = beats; 
      })
      
      .addCase(fetchNextBeats.rejected, (state, action) => {
        state.nextBeats = [];
      });
    }
});


export const { setData,setMusicTrack,setMusicTrackData,setMusicPlayerData,setMusicPageData,setDeleteModalData,setMusicPageDataLike,setMusicPlayerDataLike } = musicSlice.actions;


export const musicReducer = musicSlice.reducer;
