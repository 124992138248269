import axios from "axios";
import { getLocalStorageObject, setLocalStorageObject, urlUpdated } from "./localStorage";
import { loginForm } from "./utilsModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { changePasswordFormInputs, changePinFormInputs, deleteAccountFormInputs, resetPasswordFormInputs, setPinFormInputs, verifyPinFormInputs } from "../../content/guest-pages/login/modal";
import apiClient from "../../../services/FetchClient";
import * as Sentry from '@sentry/react';

const NavigateOnLogin=(message:string)=>{
  if(message === "Already verification done")
    {
      localStorage.removeItem("dropworksToken");
      window.location.href="/login";
    }
    if(message ==="Link has been expired")
    {
      window.location.href="/sign-up";
    }
}


export const loginApiGoogle = async (data: string) => {
  try {
    const response: any = await axios.post(urlUpdated("/login-with-google"), {code:data});
    if (response.data.success) {
      if(response.data.user_id)
      {
        localStorage.setItem("userId",response.data.user_id);

      }
      return { success: true, message: "" ,data:response.data};
    
    } else {
      console.log("in success false of login api")
      // return {
      //   mandatory_field:response.data?.mandatory_field,
      //   token:response.data?.token,
      //   success: false,
      //   type: response.data?.type || "",
      //   message: response.data.message,
      //   username:response.data?.username,
      //   imgUrl:response.data?.profile_image?.[0]?.src
      // };
    }
  } catch (err: any) {
Sentry.captureException(err);
console.log("in catch of login api")
    return null;
  }
};
export const loginApi = async (data: loginForm) => {
  try {
    const response: any = await apiClient.post("/login", data);
    if (response.data.success) {
        localStorage.setItem("dropworksToken", response.data.token);
      return { success: true, message: "" };
    } else {
      return {
        mandatory_field:response.data?.mandatory_field,
        token:response.data?.token,
        success: false,
        type: response.data?.type || "",
        message: response.data.message,
        username:response.data?.username,
        imgUrl:response.data?.profile_image?.[0]?.src
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    
    return null;
  }
};

export const sendTokenEmailLink = async (data: any) => {
  try {
    const response: any = await axios.put(urlUpdated(`/update-status-active?token=${data}`));
    if (response.data.success) {
      return { success: true, message: "",token:response.data.token || "",isLoggedIn:response.data.isLoggedIn || false};
    }
     else {
      return {
        success: false,
        type: response.data.type,
        message: response.data.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    
    return null;
  }
};



export const signUpApi = async (data: loginForm) => {
    try {
      const response: any = await apiClient.post(urlUpdated("/register"), data);
      if (response.data.success) {
        console.log(response.data,"in register")
       localStorage.setItem("userId",response.data.user_id);
        return { success: true, message: "",email:response?.data?.register?.email,mandatory_field:response?.data?.mandatory_field,token :response?.data?.token,username:response?.data?.username || "username",image:response?.data?.image};
      }
       else {
        return {
          success: false,
          type: response.data.type,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return null;
    }
  };
  export const recoverbyEmail = async (data: loginForm) => {
    try {
      const response: any = await axios.post(urlUpdated("/password-reset"), data);
      if (response.data.success) {
        return { success: true, message: "" };
      } else {
        return {
          success: false,
          type: response.data?.type || "",
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return null;
    }
  };
  export const resetPassword = async ({data,passwordToken}:{data:resetPasswordFormInputs,passwordToken:string}) => {
    try {
      const response: any = await axios.post(urlUpdated(`/set-new-forget-password-by-link/?token=${passwordToken}`), data);
      if (response.data.success) {
        return { success: true, message: "" };
      } else {
        return {
          success: false,
          type: response.data?.type || "",
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return null;
    }
  };

  export const registerFirstFormApi = async (data: any) => {
    try {
      let userId=localStorage.getItem('userId');
      const response: any = await axios.put(urlUpdated(`/update-page-1/${userId}`), data);
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        return {
          success: false,
          type: response.data.type,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return null;
    }
  };
  
  export const registerSecondFormApi = async (data: any) => {
    try {
      let userId=localStorage.getItem('userId');
      const response: any = await axios.put(urlUpdated(`/update-page-2/${userId}`), data);
      if (response.data.success) {
        return { success: true, message: "" ,data:response.data};
      }
       else {
        return {
          success: false,
          type: response.data.type,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return null;
    }
  };

  export const registerFirstPopup = async (data: string[]) => {
    
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-pop-up-1/?token=${token}`), {
        // token:token,
        role:data
      });
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      if(err.status === 401)
      {
        return {
          success: false,
          message: "Your Token has expired",
        }
      }
      return {
        success: false,
        message: err.message,
      }
    }
  };
  export const registerSecondPopup = async (data: string[]) => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-pop-up-2/?token=${token}`), {
        // token:token,
        genres:data
      });
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      if(err.status === 401)
      {
        return {
          success: false,
          message: "Your Token has expired",
        }
      }
      return {
        success: false,
        message: err.message,
      }
    }
  };

  export const registerThirdPopup = async (data:any) => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-phone-pop-up-3/?token=${token}`), data);
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      if(err.status === 401)
      {
        return {
          success: false,
          message: "Your Token has expired",
        }
      }
      return {
        success: false,
        message: err.message,
      }
    }
  };


  export const registerFourthPopup = async ({phone,otp,countryCode}:{phone:number,otp:number|undefined,countryCode:string}) => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-otp-verify-pop-up-4/?token=${token}`), {
        phone:phone,
        otp:otp,
        countryCode:countryCode
      });
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const registerFifthPopup = async () => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-pop-up-5/?token=${token}`));
      if (response.data.success) {
        return { success: true, message: "",token:response.data.token };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getUserDetails = async () => {
    let token=localStorage.getItem('dropworksToken');
    if(token)
    {
    try {
      const response: any = await axios.get(urlUpdated('/get-user-by-id'),{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
     
  }
else{
  return null;
}
  };

  export const updateEmail = async (data:any) => {
 
    try {
        const response = await axios.post(urlUpdated('/send-otp-on-current-email'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const updateEmailOtp = async (data:any) => {
     try {
        const response = await axios.post(urlUpdated('/otp-verify-Current-email'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const updateEmailnewEmail= async (data:any) => {
    try {
       const response = await axios.post(urlUpdated('/otp-send-new-email'), data,
         {
           headers: {
             'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
           },
         });
         if(response.data.success)
         {
           return{
             success:true,
             message:""
           }
         }
         else{
           return{
             success:false,
             message:response.data.message
           }
         }
   } catch (err: any) {
Sentry.captureException(err);
     
     return {
       success: false,
       message: err?.response?.data?.message || err?.message,
     }
   }
 };

 export const updateEmailnewEmailOTP = async (data:any) => {
  try {
     const response = await axios.post(urlUpdated('/otp-verify-new-email'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
Sentry.captureException(err);
   
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};
export const addPhoneNumber = async (data:any) => {
  try {
     const response = await axios.put(urlUpdated('/add-phone'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
Sentry.captureException(err);
   
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};

export const verifyPhoneNumber = async (data:{phone:string,otp:string,countryCode:string}) => {
  try {
     const response = await axios.put(urlUpdated('/verify-phone-otp'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
Sentry.captureException(err);
   
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};
export const changePhoneNumber = async (data:any) => {
  try {
     const response = await axios.put(urlUpdated('/change-phone'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
Sentry.captureException(err);
   
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};
export const addSocialLinks = async (data:any) => {
  try {
     const response = await axios.put(urlUpdated('/add-social-links'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
Sentry.captureException(err);
   
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};

export const getBlockedUserList = async () => {
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/block/getblock-item-list`,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",userData:response.data };
    } else {
      
      return {
          success: false,
          message: response.data.message,
          status: response.data.status,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const updateUnBlockList = async (data:string[]) => {
  try {
    const response: any = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/block/unblock-item`,{blockedUserId:data},
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",userData:response.data };
  } else {

      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const createTrackProject=async(data: any)=>{
  let id="";
  if (data?.id){
id =`?track_id=${data?.id}`;
}
delete data?.id;
  try {
    const response: any = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/track/create-track${id}`,data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

export const deleteTrackProject=async(data: any)=>{
  let id="";
  if (data?.id){
id =`${data?.id}`;
}
delete data?.id;
  try {
    const response: any = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/track/delete-project/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
    

      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

export const createRereleaseTrackProject=async(data: any)=>{
  let id="";
  if (data?.id){
id =`?track_id=${data?.id}`;
}
delete data?.id;
  try {
    const response: any = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/track/create-released-track${id}`,data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
          errorType:response.data.type
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}



export const getAllInvitedUserTrackProject=
async(data: any)=>{
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-invited-collabotor-by/:${data}`,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

export const inviteTrackProject=async(data: any)=>{

  try {
    const response: any = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/invite/sent-track-invite-to-user`,data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

export const updateInvitedCollaboratorsTrackProject=async(data: any)=>{

  try {
    const response: any = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/track/update-invited-collaborator`,data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

  export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }


  export const updatePhoneNumber = async (data:any) => {
 
    try {
        const response = await axios.put(urlUpdated('/add-phone'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getUserData = async () => {
    try {
      const response: any = await axios.get(urlUpdated(`/get-user-by-id`),
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return { success: true, message: "",userData:response.data };
    } else {

        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };





  export const updateUser = async (data:any) => {
 
    try {
        const response = await axios.put(urlUpdated('/update-user-profile'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          
        return(response)
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const changePassword = async ({data,passwordToken}:{data:changePasswordFormInputs,passwordToken:string}) => {
    try {
      const response = await axios.put(urlUpdated('/change-password'), data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if(response.data.success)
        {
          return{
            success:true,
            message:""
          }
        }
        else{
          return{
            success:false,
            message:response.data.message
          }
        }
  } catch (err: any) {
Sentry.captureException(err);
      
      return null;
    }
  };

  export const deleteAccount = async ({data,passwordToken}:{data:deleteAccountFormInputs,passwordToken:string}) => {
    try {
      const response = await axios.post(urlUpdated('/deactive-account-by-user'), data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if(response.data.success)
        {
          return{
            success:true,
            message:""
          }
        }
        else{
          return{
            success:false,
            type:response.data.type || "username",
            message:response.data.message
          }
        }
  } catch (err: any) {
Sentry.captureException(err);
      
      return null;
    }
  };

  export const getSecurityQuestions = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/securityQuestiones/getAllSecurityQuestions`);
      if (response.data.success) {
        return { success: true, message: "",questionData:response.data };
    } else {
 
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
 
  export const updateSecurityQuestions = async (payload:any) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/securityQuestiones/updateSecurityAnswer`,{ "answers": payload.answers },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        return(response)
    } catch (err: any) {
Sentry.captureException(err);
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getSecurityQuestionsAnswer = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/securityQuestiones/getSecurityQuestionsByUserId`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",questionData:response.data };
    } else {
 
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const addBeat = async (payload:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/beats/create-beat`,payload,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        return(response)
    } catch (err: any) {
Sentry.captureException(err);
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getReceivedInvite = async () => {
    let token=localStorage.getItem('dropworksToken');
    if(token)
    {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/invite/get-received-invitation`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        return { success: true, data:response.data};
      }
       else {
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
     
  }
else{
  return null;
}
  };

  export const getAllBeatsTrackByUserId = async () => {
    try {
      const response: any = await axios.get(urlUpdated(`/get-all-beats-track-by-user-id`),
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",BeatsTrackData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getAllUsers = async (searchName?:string,searchValue?:string,searchTrackId?:string) => {
    let searchUrl=`/get-all-user-not-invited`;
    if(searchValue && searchName)
    {
      searchUrl=`/get-all-user-not-invited?${searchName}=${searchValue}&track_id=${searchTrackId}`
    }  
        try {
      const response: any = await axios.get(urlUpdated(searchUrl),
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",userData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getAllUserStatus = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/chat/get-user-Group-Combined-List`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allUserStatus:response.data};
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getUserGroupList = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/chat/get-Group-List`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",groupsStatus:response.data};
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getUnreadMessageList = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/chat/get-all-unread-message`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",unreadMessage:response.data};
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getAllBeats = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-beat-search`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getAllTracks = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-track-search`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allTrackData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getSentInvite = async () => {
    let token=localStorage.getItem('dropworksToken');
    if(token)
    {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/invite/get-sent-invitation`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        return { success: true, data:response.data};
      }
       else {
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
     
  }
else{
  return null;
}
  };


  export const inviteStatus = async (data:any) => {
 
    try {
        const response = await axios.put((`${process.env.REACT_APP_BASE_URL}/api/invite/verify-invitation-by-link`), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  
  export const getAllProjects = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-project-search`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allProjectData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getBeat = async (data:any) => {
 
    try {
        const response = await axios.get((`${process.env.REACT_APP_BASE_URL}/api/beats/get-beat-by/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getLibraryData = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-track-project-beats-details`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",allLibraryData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getFeaturedProjectData = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-project`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",featuredProjectData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const oneToOneChatHistroy = async (id:string) => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/chat/chat-history/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",onChatHistroy:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  
  export const getFeaturedBeatsData = async () => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track//get-all-hot-beats`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",featuredBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getUserById = async (data:any) => {
    try {
      const response: any = await axios.get(`
        ${process.env.REACT_APP_BASE_URL}/api/track/get-track-and-beats-deatils-user-by/${data}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
 
        return { success: true, message: "",featuredBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


 export const uploadTrackProject=async(data: any)=>{
    let id="";
    if (data?.trackId){
  id =data?.trackId  ;
  }
    try {
      const response: any = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/track/upload-collaborator-file/${id}`,data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return { success: true, message: "",data:response.data.data };
    } else {
   
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  }

  export const getTrackProjectBeatsDetailsById = async (data:any,value:number) => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-track-project-beats-details-by-id/${data}/${value}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
 
        return { success: true, message: "",featuredBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getFile = async (data:any) => {
 
    try {
        const response = await axios.get((`${process.env.REACT_APP_BASE_URL}/api/track/get-track-by/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getFileRerelease = async (data:any) => {
 
    try {
        const response = await axios.get((`${process.env.REACT_APP_BASE_URL}/api/track/get-track-by-released/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const updateFevourite = async (obj:any,userID:any) => {
    let id;
    if (obj.hasOwnProperty('beat_id')) {
      id = `post-favourite?beat_id=${obj.beat_id}`;
  } else if (obj.hasOwnProperty('track_id')) {
      id = `post-favourite?track_id=${obj.track_id}`;
  }else{
    id = `post-favourite?track_id=${obj.project_id}`
  }
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/favourite/${id}&original_creator_id=${userID}`,{},
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        return(response.data)
    } catch (err: any) {
Sentry.captureException(err);
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const updateUserFevourite = async (userID:any) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/favourite/post-favourite?user_id_liked=${userID}`,{},
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        return(response.data)
    } catch (err: any) {
Sentry.captureException(err);
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };






  export const getMissingStep = async (data:any) => {
 
    try {
        const response = await axios.get((`${process.env.REACT_APP_BASE_URL}/api/track/get-all-missing-steps/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getMissingStepRerelease = async (data:any) => {
 
    try {
        const response = await axios.get((`${process.env.REACT_APP_BASE_URL}/api/track/get-all-missing-steps-released/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };







  export const deletAuploadFile=async(data: any)=>{
  delete data?.id;
    try {
      const response: any = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/track/update-collaborator-file-status`,data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return { success: true, message: "",data:response.data.data };
    } else {
   
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  }


  export const spotifyRerelease=async(data: any)=>{
    delete data?.id;
      try {
        const response: any = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/track/update-collaborator-file-status-released`,data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if (response.data.success) {
          return { success: true, message: "",data:response.data.data };
      } else {
     
          return {
              success: false,
              message: response.data.message,
          };
      }
      } catch (err: any) {
  Sentry.captureException(err);
        
        return {
          success: false,
          message: err?.response?.data?.message || err?.message,
        }
      }
    }


  export const updateBeat = async (obj:any,payload:any) => {

    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/beats/update-beat?beatId=${obj}`,payload,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        return(response.data)
    } catch (err: any) {
Sentry.captureException(err);
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const groupChatHistroy = async (id:string) => {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/chat/group-history/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
 
        return { success: true, message: "",groupsChatHistroy:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const deleteBeat = async (id:string) => {
    try {
      const response: any = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/beats/delete-beat/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
 
        return { success: true, message: "",DeleteBeatData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
Sentry.captureException(err);
      
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

 

  export const blockedUser = async (blockUserId: string) => {
    try {
        
        const response: any = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/block/block-item`,
            {
                block_user_Id: blockUserId, // Include the user ID in the request body
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
                },
            }
        );

        if (response.data.success) {
            return { success: true, message: "", blockUser: response.data };
        } else {
            return {
                success: false,
                message: response.message,
            };
        }
    } catch (err: any) {
Sentry.captureException(err);
        
        return {
            success: false,
            message: err?.response?.data?.message || err?.message,
        };
    }
};

export const deletedUser = async (userId: string) => {
  try {
      
      const response: any = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/chat/delete-one-to-one-chat/${userId}`,
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
              },
          }
      );

      if (response.data.success) {
          return { success: true, message: "", deleteUser: response.data };
      } else {
          return {
              success: false,
              message: response.message,
          };
      }
  } catch (err: any) {
Sentry.captureException(err);
      
      return {
          success: false,
          message: err?.response?.data?.message || err?.message,
      };
  }
};

export const reportUser = async (id: string, reportData: { report_type: string; reason: string }) => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/report/report-system`,
      {
        // reported_to: reportData.reported_to,
        report_type: "message",
        reason: reportData.reason,
        user_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "", reportedUser: response.data };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    console.error(err, "error in reportUser API");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};

export const reportSomethingElse = async (id: string, reportData: { report_type: string; reason: string,who_pretending_to: string }) => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/report/report-system`,
      {
        report_type: "user",
        reason: reportData.reason,
        user_id: id,
        who_pretending_to: reportData.who_pretending_to
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "", reportedSomething: response.data };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    console.error(err, "error in reportUser API");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};
export const reportTrack = async (track_id: string, reportData: { report_type: string; reason: string}) => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/report/report-system`,
      {
        report_type: "track",
        reason: reportData.reason,
        track_id: track_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "", reportedUser: response.data };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    console.error(err, "error in reportUser API");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};
export const reportBeats = async (beat_id: string, reportData: { report_type: string; reason: string}) => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/report/report-system`,
      {
        report_type: "beat",
        reason: reportData.reason,
        beat_id: beat_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "", reportedUser: response.data };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    console.error(err, "error in reportUser API");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};
export const reportProject = async (project_id: string, reportData: { report_type: string; reason: string}) => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/report/report-system`,
      {
        report_type: "project",
        reason: reportData.reason,
        project_id: project_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "", reportedUser: response.data };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    console.error(err, "error in reportUser API");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};

export const logoutApi = async () => {
  let data= localStorage.removeItem("dw_fb_token");
  localStorage.removeItem("chat-tab");
  localStorage.removeItem("userId")
  sessionStorage.removeItem("isDownloadPDFClicked")
  try {
    const response: any = await apiClient.post("/logout", data);
    if (response.data.success) {
      return { success: true, message: "" };
    } else {
      return {
        mandatory_field:response.data?.mandatory_field,
        token:response.data?.token,
        success: false,
        type: response.data?.type || "",
        message: response.data.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    
    return null;
  }
};

export const sendFirebaseToken = async (data: any) => {
  try {
    const response: any = await axios.post(urlUpdated('/add-device-token'),data);
    if (response.data.success) {
      return { success: true, message: ""};
    }
     else {
      return {
        success: false,
        message: response.data.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    
    return null;
  }
};

export const getAllSearch = async (value:any) => {
  let searchValue;
  if(value.length>0){
    searchValue=`?search=${value}`;
  }
  else{
    searchValue='';
  }
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-search${searchValue}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "",allSearchData:response.data };
  } else {
      return {
          success: false,
          message: response.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const muteMessages = async (
  muteData: { mute_user_id?: string; mute_group_id?: string }
) => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/mute/add-mute`,
      {
        mute_user_id: muteData.mute_user_id,
        mute_group_id: muteData.mute_group_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
        },
      }
    );

    if (response.data.success) {
      return { success: true, message: "", mutedMessage: response.data };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    console.error(err, "error in muteMessages API");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};
export const getTrackBeatProjectUserId = async (report_type: string, user_id: string) => {
  try {
      const response: any = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/report/get-track-beat-project-user-id/${report_type}/${user_id}`, // Include path parameters in the URL
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
              },
          }
      );
      if (response.data.success) {
          return { success: true, message: "", getTrackBeatProjectUserId: response.data };
      } else {
          return {
              success: false,
              message: response.message,
          };
      }
  } catch (err: any) {
Sentry.captureException(err);
      
      return {
          success: false,
          message: err?.response?.data?.message || err?.message,
      };
  }
};






export const getFavoritesData = async () => {
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/favourite/get-favourite-by`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "",favoritesData:response.data };
  } else {
      return {
          success: false,
          message: response.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};
export const getAllNotification = async () => {
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/favourite/get-invited-notification`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "",allProjectData:response.data };
  } else {
      return {
          success: false,
          message: response.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const getReportedUserChatCount = async (reported_user_id: string) => {
  try {
      const response: any = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/report/get-chat-count/${reported_user_id}`, // Include path parameters in the URL
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
              },
          }
      );
      if (response.data.success) {
          return { success: true, message: "", getChatCount: response.data };
      } else {
          return {
              success: false,
              message: response.message,
          };
      }
  } catch (err: any) {
Sentry.captureException(err);
      
      return {
          success: false,
          message: err?.response?.data?.message || err?.message,
      };
  }
};

export const getFavoritesSearch = async (value:any) => {
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/favourite/get-favourite-by-search?search=${value}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      }
    );
    if (response.data.success) {
      return { success: true, message: "",favoritesSearchData:response.data};
  } else {
      return {
          success: false,
          message: response.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const deleteMessageForUser = async (messageId: any) => {
  try {
    const response: any = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/chat/deleteMessageForUser`,
      { messageId }, // Body data goes here
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
      }
    );
    
    if (response.data.success) {
      return { success: true, message: "", deleteUserMessage: response.data };
    } else {
      return {
        success: false,
        message: response.data.message, // Ensure this is properly accessed from the response
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    console.error(err, "Error in Delete Message API");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};



export const updatReadCount = async (data:any) => {
 
  try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/notification/update-notification-count`, data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if(response.data.success)
        {
          return{
            success:true,
            message:""
          }
        }
        else{
          return{
            success:false,
            message:response.data.message
          }
        }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const clearAllNotifiactionByType = async (data:any) => {
 
  try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/notification/update-notification-by-type`, data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if(response.data.success)
        {
          return{
            success:true,
            message:""
          }
        }
        else{
          return{
            success:false,
            message:response.data.message
          }
        }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const getPrivateAndPhrase = async () => {
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users/copy-wallet-details`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      }
    );
    if (response.data.success) {
      return {secureInfo:response.data.data};
  } else {
      return {
          success: false,
          message: response.message,
      };
  }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};


export const apionMusicPlay = async (data:any) => {
 
  try {
      const response = await axios.put((`${process.env.REACT_APP_BASE_URL}/api/playCount/create-beat-track-paly-count?${data.name}=${data.value}`),data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if(response.data.success)
        {
          return{
            success:true,
            message:"",
            data:response.data.data
          }
        }
        else{
          return{
            success:false,
            message:response.data.message
          }
        }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};


export const getPopularThisWeek = async () => {
  try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-popular-user`, // Include path parameters in the URL
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
              },
          }
      );
      if (response.data.success) {
          return { success: true, message: "", getPopularThisWeek: response.data.data };
      } else {
          return {
              success: false,
              message: response.message,
          };
      }
  } catch (err: any) {
Sentry.captureException(err);
      
      return {
          success: false,
          message: err?.response?.data?.message || err?.message,
      };
  }
};


export const getPopularTrack = async () => {
  try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-popular-track`, // Include path parameters in the URL
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
              },
          }
      );
      if (response.data.success) {
          return { success: true, message: "", getPopularTrack: response.data.data };
      } else {
          return {
              success: false,
              message: response.message,
          };
      }
  } catch (err: any) {
Sentry.captureException(err);
      return {
          success: false,
          message: err?.response?.data?.message || err?.message,
      };
  }
};

export const getProjectByUserID = async () => {
  try {
      const response: any = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/track/get-all-user-project`,
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
              },
          }
      );
      if (response.data.success) {
          return { success: true, message: "", getUserProject: response.data };
      } else {
          return {
              success: false,
              message: response.message,
          };
      }
  } catch (err: any) {
    Sentry.captureException(err);
      return {
          success: false,
          message: err?.response?.data?.message || err?.message,
      };
  }
};

// verify Pin
export const verifyPin = async ({ data }: { data: verifyPinFormInputs }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/user-wallet-forget-pin`, data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
    if (response.data.success) {
      return {
        success: true,
        message: ""
      }
    }
    else {
      return {
        success: false,
        message: response.data.message
      }
    }
  } catch (err: any) {
    Sentry.captureException(err);
    return null;
  }
};

// Change Pin
export const changePin = async ({ data }: { data: changePinFormInputs }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/user-wallet-reset-pin`, data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
    if (response.data.success) {
      return {
        success: true,
        message: ""
      }
    }
    else {
      return {
        success: false,
        message: response.data.message
      }
    }
  } catch (err: any) {
    Sentry.captureException(err);
    return null;
  }
};

// Set Wallet Pin
export const setWalletPin = async ({ data }: { data: setPinFormInputs }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/set-wallet-pin`, data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
    if (response.data.success) {
      return {
        success: true,
        message: ""
      }
    }
    else {
      return {
        success: false,
        message: response.data.message
      }
    }
  } catch (err: any) {
    Sentry.captureException(err);
    return null;
  }
};

// Download Wallet Pin PDF
export const downloadPinPdf = async () => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/users/download-wallet-pin`,
      {}, // Pass empty object or any relevant data if necessary for the body
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
        responseType: 'arraybuffer', // Set response type to arraybuffer for binary data
      }
    );
    if (response.data) {
      return response.data
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
    Sentry.captureException(err);
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};

// Download Wallet Pin confirmation
export const downloadPdfConfirmation = async () => {
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/users/next-button-for-download-wallet`,
      {}, // Pass empty object or any relevant data if necessary for the body
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
        },
        responseType: 'arraybuffer', // Set response type to arraybuffer for binary data
      }
    );
    if (response.data) {
      return response.data
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (err: any) {
    Sentry.captureException(err);
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};


export const getCreateSliderPhoto = async () => {
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-create-page-gallery-by-user`);
    if (response.data.success) {
      return { success: true, message: "" ,data:response.data.data};
    } else {
      return {
        success: false,
        message:""
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    
    return null;
  }
};

export const getHomeSliderPhoto = async () => {
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-gallery-home-page-by-user`);
    if (response.data.success) {
      return { success: true, message: "" ,data:response.data.data};
    } else {
      return {
        success: false,
        message:""
      };
    }
  } catch (err: any) {
Sentry.captureException(err);
    
    return null;
  }
};


export const deleteBanner = async (data:any) => {
  try {
    
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/users/delete-user-banner-image-social-links?${data}`,  
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
          },
        }
      );
        if(response.data.success)
        {
          return{
            success:true,
            message:"",
            data:response?.data
          }
        }
        else{
          return{
            success:false,
            message:response.data.message
          }
        }
  } catch (err: any) {
Sentry.captureException(err);
    
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};