import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { useDispatch } from "react-redux";
import { clearWalletDetails, setWalletDetails, transferUsdcOrDropstock } from "../../../../../redux/reducer/walletSlice";
import { AppDispatch } from "../../../../../redux/store";

export const WalletConfirmWithdrawal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema4 = yup.object({
    pin: yup.string().required("PIN is required").min(4, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").max(6, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").matches(/^\d{4}$|^\d{6}$/, "Pin should be 4 or 6 digits.")
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema4),
    mode: "onSubmit",
  });

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const walletConfirm = async (data: any) => {
    if (data) {
      setLoading(true)
      openPopup("WalletTransaction", dispatch);
      setLoading(false);
      handleCloseModalClick()
      dispatch(transferUsdcOrDropstock(data)).then((response: any) => {
        if (response.payload.success) {
          dispatch(setWalletDetails({ status: "success" }));
        } else {
          dispatch(setWalletDetails({ status: "failed" }));
          setLoading(false);
        }
      })
    }
  };

  const openModal = () => {
    reset()
    // dispatch(clearWalletDetails());
    setLoading(false);
  }
  return view({ errors, isSubmitted, loading, register, handleSubmit, navigateTo, closeModalRef, openModal, walletConfirm });
}
export default WalletConfirmWithdrawal;