import { useNavigate, useParams } from "react-router-dom"
import view from "./view";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addBeat } from "../../shared/utils/apiServices";
import { setUploadBeatImg } from "../../../redux/reducer/uploadSlice";

export const BeatEmpty = () => {
    const [beatName, setBeatName] = useState<string>()
    const [beatDescription, setBeatDescription] = useState<string>()
    const [uploadedAudioFile, setUploadedAudioFile] = useState<any>(null);
    const [audioFileAPI, setAudioFileAPI] = useState<any>(null);
    const [audioImage, setAudioImage] = useState<any>(null);
    const [error, setError] = useState<any>("");
    const [beatImgAPI, setBeatImgAPI] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const dispatch = useDispatch()

    const [beatId, setBeatId] = useState<any>()

    const uploadedImage = useSelector((state: any) => state.upload.beatImg);
    const ImgBeat = useSelector((state: any) => state.upload.beatImgAPI);

    const beatSlugName = useParams()

    useEffect(() => {
        setBeatImgAPI(ImgBeat)
    }, [ImgBeat])

    useEffect(() => {
        setAudioImage(uploadedImage)
    }, [uploadedImage])

    useEffect(() => {
        return () => {
            dispatch(setUploadBeatImg("")); // Clear the uploaded image when the component unmounts
        };
    }, []);

    const navigate = useNavigate();

    const handleAudioUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setLoading(true)
        if (file) {
            setUploadedAudioFile(file);
            const formData = new FormData();
            formData.append('uploadingFile', file);

            try {
                const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`, formData);
                if (uploadResponse.data.success) {
                    setAudioFileAPI(uploadResponse?.data.file_data)
                    setLoading(false)
                }
                else {
                    console.error("Else Error in response of uploading image", uploadResponse.data.message);
                }
            }
            catch (error) {
                console.error("Catch Error in response of uploading image", error);
            }
        }
    };


    const handleRemoveAudio = () => {
        setUploadedAudioFile(null);
        setAudioFileAPI(null);
    };


    const navigateTo = (value: string) => () => {
        navigate(value)
    }



    const payload = {
        beat_name: beatName,
        description: beatDescription,
        art_work_image: beatImgAPI,
        audio_file: audioFileAPI,
    };

    const handleSubmit = async () => {
        let response: any = await addBeat(payload);
        if (response?.data?.success && response?.data?.beat) {
            navigate("/library")
        } else {
            setError(response.data.message)
        }
    }

    return (view({ navigateTo }, {
        beatName, setBeatName, beatDescription, setBeatDescription, uploadedImage,
        handleAudioUpload, handleRemoveAudio, uploadedAudioFile, handleSubmit, audioImage, error,
        beatSlugName, beatId, setError, loading, audioFileAPI
    }))
}