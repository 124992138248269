import { FC, useRef, useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { changePassword, resetPassword } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";

export const ChangePasswordModal=()=>{
    const [loading, setLoading] = useState(false);
    const [passwordToken,setPasswordToken]=useState("")
    const closeModalRef = useRef<HTMLAnchorElement>(null); 
    const navigate = useNavigate(); 
    const navigateTo = (value: string) => () => {
        navigate(value);
      };
    const schema4 = yup.object({
        old_password: yup.string().min(6, "Password must be at least 6 characters").required("Old password is required"),
        new_password: yup.string().min(6, "Password must be at least 6 characters").required("New password is required"),
        confirm_password: yup
          .string()
          .oneOf([yup.ref("new_password")], "Passwords must match")
          .required("Confirm password is required"),
      });

    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        formState: { errors, isSubmitted },
      } = useForm<any>({
        resolver: yupResolver(schema4),
        mode: "onSubmit",
      });
      
      const handleCloseModalClick = () => {
        if (closeModalRef.current) {
          closeModalRef.current.click(); 
        }
      };
      
    const onSubmit = async (data: any) => {
        setLoading(true)
        const response: any = await changePassword({data,passwordToken});
        if (response.success) {
          setLoading(false);
          handleCloseModalClick()
        } else {
            setLoading(false);
          setError(response.type || "new_password" || "old_password", { type: "custom", message: response.message });
        }
      };

      const openModal=()=>{
        reset()
        setValue("old_password", "");
        setValue("new_password", "");
        setValue("confirm_password", "");
        setError("new_password", { type: "custom", message: "" });
        setError("old_password", { type: "custom", message: "" });
        setError("confirm_password", { type: "custom", message: "" });
        setLoading(false);
      }
    return view({errors,isSubmitted,loading,register,onSubmit,handleSubmit,navigateTo,closeModalRef,openModal});
}
export default ChangePasswordModal