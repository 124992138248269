import VerifyPinPrivateKey from './pop-ups/verify-pin-privatekey'
import DisplayNameModal from "./pop-ups/display-name";
import BiographyModal from "./pop-ups/biography-modal";
import SecurityQuestion from "./pop-ups/security-question";
import SecurityQuestion2 from "./pop-ups/security-question 2";
import ChangePasswordModal from "./pop-ups/change-password";
import ChangePasswordModal2 from "./pop-ups/change-password2";
import ChangePasswordModal3 from "./pop-ups/change-password3";
import DeleteAccount from "./pop-ups/delete-account";
import DeleteAccount3Modal from "./pop-ups/delete-account3";
import RegionModal from "./pop-ups/region-modal";
import DisplayLanguage from "./pop-ups/display-language";
import { ActiveInviteReceived} from "./pop-ups/active-invite-received";
import ReportModal1 from "./pop-ups/report-modal-1";
import ThankYouModal from "./pop-ups/thank-you-modal";
import InviteModal from "./pop-ups/invite-modal";
import InviteModal2 from "./pop-ups/invite-modal-2";
import SocialLink8 from "./pop-ups/social-links-8";
import EmailModal1 from "./pop-ups/email-modal-1";
import EmailModal2 from "./pop-ups/email-modal-2";
import EmailModal6 from "./pop-ups/email-modal-6";
import MobilePhoneModal3 from "./pop-ups/mobile-phone-modal-3";
import MobilePhoneModalA from "./pop-ups/mobile-phone-modal-A";
import MobilePhoneModalB from "./pop-ups/mobile-phone-modal-B";
import ProfilePictureModal from "./pop-ups/profile-picture-modal";
import ProfilePictureModal2 from "./pop-ups/profile-picture-modal-2";
import ReportModal2 from "./pop-ups/report-modal-2";
import ReportModal3 from "./pop-ups/report-modal-3";
import ReportModal4 from "./pop-ups/report-modal-4";
import ReportRecentChatModal from "./pop-ups/report-recent-chat";
import SuccessfullyBlocked from "./pop-ups/successfully-blocked";
import TrackReportModal from "./pop-ups/track-report-reason";
import UserReportReasonModal from "./pop-ups/user-report-reason";
import BeatReportModal from "./pop-ups/beat-report-reason";
import ImpersonatingReportModal from "./pop-ups/impersonating-report-modal";
import AcceptedInviteReceived from "./pop-ups/accepted-invite-received";
import DeclinedInviteReceived from "./pop-ups/declined-invite-received";
import ExpiredInviteReceived from "./pop-ups/expired-invite-received";
import AcceptedInviteSent from "./pop-ups/accepted-invite-sent";
import ActiveInviteSent from "./pop-ups/active-invite-sent";
import DeclinedInviteSent from "./pop-ups/declined-invite-sent";
import ExpiredInviteSent from "./pop-ups/expired-invite-sent";
import RolesModal from "./pop-ups/roles-modal";
import DawsModal from "./pop-ups/daws-modal";
import EmailModal from "./pop-ups/email-modal";
import BlockedUser1 from "./pop-ups/blockuser-1";
import BlockedUser2 from "./pop-ups/blockuser-2";
import BlockedModal from "./pop-ups/blocked-modal";
import SelectTrack from "./pop-ups/select-track";
import SelectBeat from "./pop-ups/select-beat";
import SocialLinksNotification from "./pop-ups/social-links-notification";
import LoginHistorySocials from "./pop-ups/login-history";
import UploadBanner from "./pop-ups/upload-banner";
import UploadBanner2 from "./pop-ups/upload-banner-2";
import SocialLinksMain from "./pop-ups/socail-links-main";
import BeatArtWork from "./pop-ups/beat-artwork";
import ReleasedModal from "./pop-ups/release-modal";
import ApproveFile from "./pop-ups/approve-file";
import DeclineFile from "./pop-ups/decline-file";
import UploadModal from "./pop-ups/upload-modal";
import DepositModal from "./pop-ups/deposit-modal";
import WithdrawModal from "./pop-ups/withdraw-modal";
import InviteModal8 from "./pop-ups/invite-modal-8";
import ChangeAlias from "./pop-ups/change-alias";
import RemoveCollaborator from "./pop-ups/remove-collaborator";
import DeleteFile from "./pop-ups/delete-file";
import ApproveFile2 from "./pop-ups/approve-file-2";
import DeleteChat from "./pop-ups/delete-chat";
import DropWorksSelectSubmit from "./pop-ups/dropworks-select-submit";
import SubMissionThankyou from "./pop-ups/submission-thankyou";
import SellDropStocks from "./pop-ups/sell-dropstocks";
import SellDropStocks3 from "./pop-ups/sell-dropstocks-3";
import DropStocksSelectsubmit3 from "./pop-ups/dropworks-select-submit-3";
import CreateMobile from "./pop-ups/create-mobile";
import MessageChatModal from "./pop-ups/message-chat-modal";
import SelectProject from "./pop-ups/select-project";
import ProjectReportModal from "./pop-ups/project-report-reason";
import { useSelector } from "react-redux";
import VerifyPinModal from "./pop-ups/verify-pin";
import ChangePinModal from "./pop-ups/change-pin";
import SelectPinTypeModal from "./pop-ups/select-pin-type-modal";
import OneTimePinModal from "./pop-ups/set-pin-one-time";
import LinkConfirmation from "./pop-ups/spotify-link-confirmation";
import VerifyPhraseModal from "./pop-ups/verify-phrase";
import DownloadPinOneTime from "./pop-ups/download-pin-one-time-next-btn";
import DownloadPdfConfirmation from "./pop-ups/download-pdf-confirmations";
import WalletDepositTypeModal from "./pop-ups/wallet-deposit-type-modal";
import WalletWithdrawTypeModal from "./pop-ups/wallet-withdraw-type-modal";
import WalletPinModal from "./pop-ups/wallet-pin-modal";
import WalletUsdcWithdraw from "./pop-ups/wallet-USDC-withdraw";
import WalletFeesDropStocksWithdraw from "./pop-ups/wallet-fees-DropStocks-withdraw";
import WalletNetworkConfirmation from "./pop-ups/wallet-network-confirmation";
import WalletConfirmWithdrawal from "./pop-ups/wallet-confirmation";
import WalletTransaction from "./pop-ups/wallet-transaction";
import WalletDropStocksWithdraw from "./pop-ups/wallet-DropStocks-withdraw";
import WalletUsdcDeposit from "./pop-ups/wallet-USDC-deposit";
import WalletDropstocksDeposit from "./pop-ups/wallet-DropStocks-deposit";
import { RootState } from '../../../redux/store';
import BeatArtWorkFirst from './pop-ups/beat-artwork-first';
import TrackDetailsPopUp from './pop-ups/track-details-page';
import DeleteModal from './pop-ups/delete-modal';
import LeaveProject from './pop-ups/leave-project';
import ProjectName from './pop-ups/project-name';
import ProjectName2 from './pop-ups/project-name2';
import SpotifyVerify from './pop-ups/spotify-verify';
import TrackArtWork from './pop-ups/track-artwork';
import UserListModal from './pop-ups/user-list-modal';
import SpotifyVerifyRerelease from './pop-ups/spotify-verify-rerelease';

const ParentPopUp = () => {
const {openModal}=useSelector((state:RootState)=>state.modal)

  return (
  <>
{/* {openModal === "acc-secured" && <AccSecured />} */}
{/* {openModal === "Modal1" && <Modal1 />}
{openModal === "Modal2" && <Modal2 />}
{openModal === "Modal4" && <Modal4 />} */}
{/* {openModal === "SecureYourAccount" && <SecureYourAccount />} */}
{/* {openModal === "settinguppopup" && <SettingUpPopup />} */}
{openModal === "AcceptedInviteReceived" && <AcceptedInviteReceived />}
{openModal === "AcceptedInviteSent" && <AcceptedInviteSent />}
{openModal === "ActiveInviteReceived" && <ActiveInviteReceived />}
{openModal === "ActiveInviteSent" && <ActiveInviteSent />}
{openModal === "ApproveFile" && <ApproveFile />}
{openModal === "ApproveFile2" && <ApproveFile2 />}
{openModal === "beatartwork" && <BeatArtWork />}
{openModal === "BeatArtWorkFirst" && <BeatArtWorkFirst />}
{openModal === "BeatReportModal" && <BeatReportModal />}
{openModal === "BiographyModal" && <BiographyModal />}
{openModal === "BlockedModal" && <BlockedModal />}
{openModal === "BlockedUser1" && <BlockedUser1 />}
{openModal === "BlockedUser2" && <BlockedUser2 />}
{openModal === "ChangeAlias" && <ChangeAlias />}
{openModal === "ChangePassword" && <ChangePasswordModal />}
{openModal === "ChangePassword2" && <ChangePasswordModal2 />}
{openModal === "ChangePassword3" && <ChangePasswordModal3 />}
{openModal === "ChangePinModal" && <ChangePinModal />}
{openModal === "CreateMobile" && <CreateMobile />}
{openModal === "DawsModal" && <DawsModal />}
{openModal === "DeclineFile" && <DeclineFile />}
{openModal === "DeclinedInviteReceived" && <DeclinedInviteReceived />}
{openModal === "DeclinedInviteSent" && <DeclinedInviteSent />}
{openModal === "DeleteAccount" && <DeleteAccount />}
{openModal === "DeleteAccount3" && <DeleteAccount3Modal />}
{openModal === "DeleteChat" && <DeleteChat />}
{openModal === "DeleteFile" && <DeleteFile />}
{openModal === "DeleteModal" && <DeleteModal />}
{openModal === "DepositModal" && <DepositModal />}
{openModal === "DisplayLanguageModal" && <DisplayLanguage />}
{openModal === "DisplayName" && <DisplayNameModal />}
{openModal === "downloadPdfConfirmation" && <DownloadPdfConfirmation />}
{openModal === "downloadPinOneTime" && <DownloadPinOneTime />}
{openModal === "SelectSubmit" && <DropWorksSelectSubmit />}
{openModal === "DropStocksSelectsubmit3" && <DropStocksSelectsubmit3 />}
{openModal === "EmailModal" && <EmailModal />}
{openModal === "EmailModal1" && <EmailModal1 />}
{openModal === "EmailModal2" && <EmailModal2 />}
{openModal === "EmailModal6" && <EmailModal6 />}
{openModal === "ExpiredInviteReceived" && <ExpiredInviteReceived />}
{openModal === "ExpiredInviteSent" && <ExpiredInviteSent />}
{openModal === "ImpersonatingReportModal" && <ImpersonatingReportModal />}
{openModal === "InviteModal" && <InviteModal />}
{openModal === "InviteModal2" && <InviteModal2 />}
{openModal === "InviteModal8" && <InviteModal8 />}
{openModal === "LeaveProject" && <LeaveProject />}
{openModal === "LoginHistorySocials" && <LoginHistorySocials />}
{openModal === "MessageChatModal" && <MessageChatModal />}
{openModal === "MobilePhoneModal3" && <MobilePhoneModal3 />}
{openModal === "MobilePhoneModalA" && <MobilePhoneModalA />}
{openModal === "MobilePhoneModalB" && <MobilePhoneModalB />}
{openModal === "ProfilePictureModal" && <ProfilePictureModal />}
{openModal === "ProfilePictureModal2" && <ProfilePictureModal2 />}
{openModal === "ProjectName" && <ProjectName />}
{openModal === "ProjectName2" && <ProjectName2 />}
{openModal === "ProjectReportModal" && <ProjectReportModal />}
{openModal === "RegionModal" && <RegionModal />}
{openModal === "ReleaseModal" && <ReleasedModal />}
{openModal === "RemoveCollaborator" && <RemoveCollaborator />}
{openModal === "ReportModal1" && <ReportModal1 />}
{openModal === "ReportModal2" && <ReportModal2 />}
{openModal === "ReportModal3" && <ReportModal3 />}
{openModal === "ReportModal4" && <ReportModal4 />}
{openModal === "ReportRecentChatModal" && <ReportRecentChatModal />}
{openModal === "RolesModal" && <RolesModal />}
{openModal === "SecurityQuestion" && <SecurityQuestion />}
{openModal === "SecurityQuestion2" && <SecurityQuestion2 />}
{openModal === "SelectBeat" && <SelectBeat />}
{openModal === "SelectPinTypeModal" && <SelectPinTypeModal />}
{openModal === "SelectProject" && <SelectProject />}
{openModal === "SelectTrack" && <SelectTrack />}
{openModal === "SellDropStocks" && <SellDropStocks />}
{openModal === "SellDropStocks3" && <SellDropStocks3 />}
{openModal === "OneTimePinModal" && <OneTimePinModal />}
{openModal === "SocialLinksMain" && <SocialLinksMain />}
{openModal === "SocialLink8" && <SocialLink8 />}
{openModal === "SocialLinksNotification" && <SocialLinksNotification />}
{openModal === "LinkConfirmation" && <LinkConfirmation />}
{openModal === "SpotifyVerify" && <SpotifyVerify />}
{openModal === "SubMissionThankyou" && <SubMissionThankyou />}
{openModal === "SuccessfullyBlocked" && <SuccessfullyBlocked />}
{openModal === "ThankYouModal" && <ThankYouModal />}
{openModal === "trackartwork" && <TrackArtWork />}
{openModal === "TrackDetailsPopUp" && <TrackDetailsPopUp />}
{openModal === "TrackReportModal" && <TrackReportModal />}
{openModal === "UploadBanner" && <UploadBanner />}
{openModal === "UploadBanner2" && <UploadBanner2 />}
{openModal === "UploadModal" && <UploadModal />}
{openModal === "UserListModal" && <UserListModal />}
{openModal === "UserReportReasonModal" && <UserReportReasonModal />}
{openModal === "VerifyPhrase" && <VerifyPhraseModal />}
{openModal === "VerifyPin" && <VerifyPinModal />}
{openModal === "VerifyPinPrivateKey" && <VerifyPinPrivateKey />}
{openModal === "WalletConfirmWithdrawal" && <WalletConfirmWithdrawal />}
{openModal === "WalletDepositTypeModal" && <WalletDepositTypeModal />}
{openModal === "WalletDropstocksDepositModal" && <WalletDropstocksDeposit />}
{openModal === "WalletDropstockWithdrawModal" && <WalletDropStocksWithdraw/>}
{openModal === "WalletFeesDropStocksWithdrawModal" && <WalletFeesDropStocksWithdraw />}
{openModal === "WalletNetworkConfirmation" && <WalletNetworkConfirmation />}
{openModal === "WalletPinModal" && <WalletPinModal />}
{openModal === "WalletTransaction" && <WalletTransaction />}
{openModal === "WalletUsdcDepositModal" && <WalletUsdcDeposit />}
{openModal === "WalletUsdcWithdrawModal" && <WalletUsdcWithdraw />}
{openModal === "WalletWithdrawTypeModal" && <WalletWithdrawTypeModal />}
{openModal === "WithdrawModal" && <WithdrawModal />}
{openModal === "SpotifyVerifyRerelease" && <SpotifyVerifyRerelease />}


  </>
  )
}

export default ParentPopUp